import axios from 'axios';
import { ipaddress } from '../../../App';
import { useEffect, useState, useCallback } from 'react';
import DataCount from './DataCount';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import DataSubCounts from '../ClosedReasons/DataSubCounts';
import { buildQueryString } from '../../../utils/QueryBuilder';

function ClosedData({ setCloseCourseData }) {
  const [closedData, setClosedData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [courseBasedReasons, setCourseBasedReasons] = useState([]);
  const [reasonsBasedCourses, setReasonsBasedCourses] = useState([]);

  const [selectedReason, setSelectedReason] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  const [months, setMonths] = useState({ startDate: '', endDate: '' });
  const location = useLocation();

  const formatDate = date => (date ? dayjs(date).format('YYYY-MM-DD') : '');

  let empID;

  // console.log('LOC', location.pathname.slice(13, 31));

  if (location.pathname.startsWith('/view_employee_leads')) {
    empID = location.pathname.slice(21, 31);
  }

  const navigate = useNavigate();

  const getReasonsData = useCallback(async () => {
    const IPPADDRESS = `${ipaddress}/SearchClosedLeadsForReasons/?${
      months.startDate ? `start_date=${formatDate(months.startDate)}` : ''
    }${months.endDate ? `&end_date=${formatDate(months.endDate)}` : ''}${
      empID ? `&emp_id=${empID}` : ''
    }`;

    console.log('REASONSAPI', IPPADDRESS);

    try {
      const response = await axios.get(IPPADDRESS);
      console.log('REASONS ALL', response.data);
      setReasonsData(response.data.reason_counts);
    } catch (error) {
      console.error('Error fetching reasons data:', error);
    }
  }, [months]);

  const getCoursesBasedOnReasonsData = useCallback(
    async reason => {
      setSelectedReason(reason);
      console.log(reason);
      const IPPADDRESS = `${ipaddress}/SearchLeadsForReason/?${`reason=${encodeURIComponent(
        reason,
      )}`}${
        months.startDate ? `&start_date=${formatDate(months.startDate)}` : ''
      }${months.endDate ? `&end_date=${formatDate(months.endDate)}` : ''}${
        empID ? `&emp_id=${empID}` : ''
      }`;

      console.log('REASONSAPI', IPPADDRESS);

      try {
        const response = await axios.get(IPPADDRESS);
        console.log('COURSE BASED REASONS', response.data);
        setCourseBasedReasons(response.data.course_counts);
      } catch (error) {
        console.error('Error fetching reasons data:', error);
      }
    },
    [months],
  );

  const getReasonsBasedOnCoursesData = useCallback(
    async course_name => {
      setSelectedCourse(course_name);
      console.log(course_name);
      const IPPADDRESS = `${ipaddress}/PeopleByReasonForClosure/?${`course_name=${encodeURIComponent(
        course_name,
      )}`}${
        months.startDate ? `&start_date=${formatDate(months.startDate)}` : ''
      }${months.endDate ? `&end_date=${formatDate(months.endDate)}` : ''}${
        empID ? `&emp_id=${empID}` : ''
      }`;

      console.log('REASONSAPI', IPPADDRESS);

      try {
        const response = await axios.get(IPPADDRESS);
        console.log(' REASONS BASED COURSES', response.data);
        setReasonsBasedCourses(response.data);
      } catch (error) {
        console.error('Error fetching reasons data:', error);
      }
    },
    [months],
  );

  const getClosedData = useCallback(async () => {
    const IPPADDRESS = `${ipaddress}/CourseWiseClosedLeads/?${
      months.startDate ? `start_date=${formatDate(months.startDate)}` : ''
    }${months.endDate ? `&end_date=${formatDate(months.endDate)}` : ''}${
      empID ? `&emp_id=${empID}` : ''
    }`;
    console.log('IPAD', IPPADDRESS);

    try {
      const response = await axios.get(IPPADDRESS);
      console.log('CLOSED ALL', response.data);
      setClosedData(response.data);
    } catch (error) {
      console.error('Error fetching closed data:', error);
    }
  }, [months]);

  useEffect(() => {
    getReasonsData();
    getClosedData();
  }, [getClosedData, getReasonsData]);

  const fetchCourses = async courseName => {
    const formattedStartDate = formatDate(months.startDate);
    const formattedEndDate = formatDate(months.endDate);

    const queryParams = {
      course_name: courseName,
      reason_for_closure: selectedReason,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      emp_id: empID,
    };

    const apiUrl = `${ipaddress}/SearchClosedLeads/?${buildQueryString(
      queryParams,
    )}`;

    try {
      const response = await axios.get(apiUrl);
      console.log('MUIZ:', courseName, apiUrl, response.data);

      // Scroll to the results table
      const section = document.getElementById('closedTable');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      console.log('MUIZ', response.data.customer_leads_data);

      setCloseCourseData(response.data.customer_leads_data);
    } catch (error) {
      console.error('Error fetching filtered closed leads:', error);
    }
  };

  const fetchReasons = async reason => {
    const formattedStartDate = formatDate(months.startDate);
    const formattedEndDate = formatDate(months.endDate);

    const apiUrl = `${ipaddress}/SearchClosedLeads/?reason_for_closure=${encodeURIComponent(
      reason,
    )}&course_name=${encodeURIComponent(selectedCourse)}${
      formattedStartDate ? `&start_date=${formattedStartDate}` : ''
    }${formattedEndDate ? `&end_date=${formattedEndDate}` : ''}${
      empID ? `&emp_id=${empID}` : ''
    }`;

    try {
      const response = await axios.get(apiUrl);
      console.log('MUIZ:', reason, apiUrl, response.data);

      const section = document.getElementById('closedTable');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      console.log('MUIZ', response.data);

      setCloseCourseData(response.data.customer_leads_data);
    } catch (error) {
      console.error('Error fetching filtered closed leads:', error);
    }
  };

  // Handle date input changes
  const handleDateChange = (field, value) => {
    setMonths(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="tw-p-4">
      {/* Date Filters */}
      <div className="tw-mb-6 tw-gap-x-4 tw-flex">
        <div className="tw-w-max">
          <label
            htmlFor="startMonth"
            className="tw-block tw-text-sm tw-font-medium"
          >
            Start Month
          </label>
          <input
            onChange={e => handleDateChange('startDate', e.target.value)}
            className="tw-p-2 tw-border tw-rounded-md"
            type="date"
            name="startMonth"
            id="startMonth"
          />
        </div>
        <div className="tw-w-max">
          <label
            htmlFor="endMonth"
            className="tw-block tw-text-sm tw-font-medium"
          >
            End Month
          </label>
          <input
            onChange={e => handleDateChange('endDate', e.target.value)}
            className="tw-p-2 tw-border tw-rounded-md"
            type="date"
            name="endMonth"
            id="endMonth"
          />
        </div>
      </div>

      {/* Data Count Component */}
      <div className="tw-grid tw-grid-cols-4 tw-grid-rows-1 tw-gap-x-2 ">
        <DataCount
          fetchData={getReasonsBasedOnCoursesData}
          data={closedData}
          fieldCount="count"
          fieldName="customer_lead_id__course_enquired__course_name"
          title="Courses Summary"
        />
        {selectedCourse && (
          <DataSubCounts
            fetchData={fetchReasons}
            data={reasonsBasedCourses}
            fieldCount="count"
            fieldName="reason_for_closure__reason"
            title="Reasons Based On Courses Summary"
          />
        )}
        <DataCount
          fetchData={getCoursesBasedOnReasonsData}
          data={reasonsData}
          fieldCount="count"
          fieldName="reason"
          title="Reasons Summary"
        />
        {selectedReason && (
          <DataSubCounts
            fetchData={fetchCourses}
            data={courseBasedReasons}
            fieldCount="count"
            fieldName="course_name"
            title="Courses Based On Reasons Summary"
          />
        )}
      </div>
    </div>
  );
}

export default ClosedData;
