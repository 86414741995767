import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ipaddress } from '../App';
import axios from 'axios';
import Preloader from './Preloader';
import Arrow_svg from '../Svgs/Arrow_svg';

const Edit_customer_lead = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const { id } = useParams();

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);

  const [bach_perc, setbach_perc] = useState(true);
  const [mast_perc, setmast_perc] = useState(true);
  const [loading, setloading] = useState(false);

  const [bach_degrees, setbach_degrees] = useState([]);
  const [bach_specific, setbach_specific] = useState([]);

  const [master_degrees, setmaster_degrees] = useState([]);
  const [master_specific, setmaster_specific] = useState([]);

  useEffect(() => {
    setloading(true);
    axios
      .get(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

    axios(`${ipaddress}/bachelor-degrees/`)
      .then(r => {
        console.log('Bach degrees successfully fetched', r.data);
        setbach_degrees(r.data);
      })
      .catch(err => {
        console.log('Bach degrees fetching Error', err);
      });

    axios(`${ipaddress}/MasterDegrees/`)
      .then(r => {
        console.log('Mast degrees successfully fetched', r.data);
        setmaster_degrees(r.data);
      })
      .catch(err => {
        console.log('Mast degrees fetching Error', err);
      });

    axios
      .get(`${ipaddress}/Allleadlevels/`)
      .then(r => {
        console.log('Lead levels successfully fetched', r.data);
        setlead_levels(r.data.leadlevels);
      })
      .catch(err => {
        console.log('Lead levels fetching Error', err);
      });

    axios
      .get(`${ipaddress}/LeadDetailsView/${id}/`)
      .then(r => {
        console.log('Lead details successfully fetched', r.data);
        setFormData(r.data);
      })
      .catch(err => {
        console.log('Lead details fetching Error', err);
      });
    setloading(false);
  }, []);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const [formData, setFormData] = useState({
    emp_id: '',
    name: '',
    email: '',
    contact_number: '',
    gender: '',
    bachelor_degree: '',
    bachelor_specification: '',
    percentage_bachelor: '',
    master_degree: '',
    master_specification: '',
    percentage_master: '',
    course_enquired: '',
    course_enquiredd: '',
    updated_remarks: '',
    enquiry_location: '',
    source: '',
    contacted_person_name: '',
    contacted_person_number: '',
    technical_skills_known: [],
    soft_skills_known: [],
    priority: '',
    level_lead: '',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    created_at: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [updated_remarks, setupdated_remarks] = useState('');

  const location = useLocation();
  console.log('FROM', location);
  const from = location.state?.from;
  console.log('AABB', from);

  const senddetails = e => {
    e.preventDefault();
    setload(true);

    formData.emp_id = user.emp_id;
    axios
      .put(`${ipaddress}/CustomerLeadsView/${id}/`, formData)
      .then(r => {
        setload(false);
        console.log('Successfully updated', r.data);
        console.log(formData);
        toast.success('Successfully updated', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });

        setTimeout(() => {
          navigate(from ? `${from}` : '/dashboard');
        }, 2000);
      })
      .catch(err => {
        setload(false);
        console.log('Lead updating error', err);
        console.log(formData);
      });
  };

  const [other_course, setother_course] = useState(false);

  // Phone number verification
  const [load2, setload2] = useState();
  const [message2, setmessage2] = useState();

  const verify_phone = phone => {
    // if(phone.length==10){
    setload2(true);
    setTimeout(() => {
      verify2(phone);
    }, 1000);
    // }
  };

  const verify2 = phone => {
    axios
      .post(`${ipaddress}/MobileNumberValidation/`, {
        mobile_number: phone,
        customer: 'Customer',
      })
      .then(r => {
        console.log('Phone number verified', r.data);
        setload2(false);
        setmessage2(true);
        formData.contact_number = phone;
      })
      .catch(err => {
        setmessage2(false);
        setload2(false);
      });
  };

  // Email verification
  const [email_load, seemail_tload] = useState();
  const [message, setmessage] = useState();

  const verify_email = email => {
    if (email.length > 0) {
      setload(true);
      setTimeout(() => {
        verify(email);
      }, 1000);
    }
  };

  const verify = email => {
    axios.get(`${ipaddress}/EmailCheck/${email}/`).then(r => {
      console.log('Email verified', r.data);
      setload(false);

      if (r.data.message === 'mail_notexist_customerlead') {
        setmessage(true);
        formData.email = email;
      } else {
        setmessage(false);
      }
    });
  };

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="animate__animated animate__slideInUp p-md-2"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <div className="container-fluid row m-0">
            <div className="my-3 d-flex align-items-center ps-md-0">
              <Link
                className="text-dark"
                to="/customerleads"
              >
                <Arrow_svg />
              </Link>
              <h5
                className="m-0 ms-2 ms-md-4"
                style={{
                  color: '#002147',
                  letterSpacing: '1px',
                  fontWeight: 600,
                  fontSize: '17px',
                }}
              >
                EDIT B2C LEAD
              </h5>
            </div>
            <div className="col-12 bg-white p-2 p-md-4 shadow">
              <form
                className=""
                onSubmit={senddetails}
              >
                {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
                <div className="row m-0 border-bottom pb-2">
                  <h6 className="text-color fw-bold mb-3">Personal Details</h6>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputEmail4"
                      className="form-label"
                    >
                      Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputEmail4"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Contact Number
                    </label>
                    <div class="input-group form-inputs">
                      <input
                        type="tel"
                        maxLength={10}
                        className="form-control shadow-none  py-2 border-0 bg-transparent"
                        id="inputPassword4"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={e => {
                          verify_phone(e.target.value);
                        }}
                      />
                      <span
                        class="input-group-text border-0 bg-transparent"
                        id="basic-addon2"
                      >
                        <div
                          class={`spinner-border spinner-border-sm text-success ${
                            load2 ? '' : 'd-none'
                          }`}
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#198754"
                          class={`bi bi-check2-circle ${
                            !load2 && message2 == true ? '' : 'd-none'
                          }`}
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                        </svg>
                      </span>
                    </div>
                    <p
                      className={`text-danger mt-1 ${
                        message2 == false ? '' : 'd-none'
                      }`}
                      style={{ fontSize: '13px' }}
                    >
                      *Mobile Number already exist
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Gender
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option selected>Choose...</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Transgender</option>
                    </select>
                  </div>
                </div>

                {/* ----------------------------------EDUCATIONAL DETAILS----------------------------------------------------------- */}
                <div className="row m-0 py-3">
                  <h6 className="text-color fw-bold mb-3">
                    Educational Details
                  </h6>

                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Bachelor Degree{' '}
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      name="bachelor_degree"
                      value={formData.bachelor_degree}
                      onChange={e => {
                        setbach_perc(false);
                        const { name, value } = e.target;

                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }}
                    >
                      <option selected>Choose...</option>
                      {bach_degrees.map(x => {
                        return (
                          <>
                            <option value={x}>{x}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Bachelor Specification
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      onChange={e => {
                        if (e.target.value === 'other') {
                          document.getElementById('bachother').style.display =
                            'block';
                        } else {
                          const { name, value } = e.target;
                          setFormData(prev => ({
                            ...prev,
                            [name]: value,
                          }));
                          document.getElementById('bachother').style.display =
                            'none';
                        }
                      }}
                    >
                      <option selected>Choose...</option>
                      <option value="other">Other</option>
                      <option>Software</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Percentage Achieved
                    </label>
                    <input
                      type="number"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.percentage_bachelor}
                      name="percentage_bachelor"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className="col-md-6 col-lg-3 mb-3"
                    id="bachother"
                    style={{ display: 'none' }}
                  >
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enter your Specification
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.bachelor_specification}
                      name="bachelor_specification"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row m-0 border-bottom pb-3">
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Master Degree
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      onChange={e => {
                        setmast_perc(false);
                        const { name, value } = e.target;

                        setFormData(prev => ({
                          ...prev,
                          [name]: value,
                        }));
                      }}
                    >
                      <option selected>Choose...</option>
                      <option>M.Tech</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Master Specification
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      onChange={e => {
                        if (e.target.value === 'other') {
                          document.getElementById('masterother').style.display =
                            'block';
                        } else {
                          const { name, value } = e.target;
                          setFormData(prev => ({
                            ...prev,
                            [name]: value,
                          }));
                          document.getElementById('masterother').style.display =
                            'none';
                        }
                      }}
                    >
                      <option selected>Choose...</option>
                      <option value="other">Other</option>
                      <option value="software">Sofware</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Percentage Achieved
                    </label>
                    <input
                      type="number"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.percentage_master}
                      name="percentage_master"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className="col-md-6 col-lg-3 mb-3"
                    id="masterother"
                    style={{ display: 'none' }}
                  >
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enter your Specification
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.master_specification}
                      name="master_specification"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* ----------COURSE DETAILS----------------------- */}
                <div className="row m-0 border-bottom py-3">
                  <h6 className="text-color fw-bold mb-3">Course Details</h6>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Course Enquired
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      value={formData.course_enquired}
                      name="course_enquired"
                      onChange={e => {
                        if (e.target.value === 'other') {
                          const { name, value } = e.target;
                          setFormData(prev => ({
                            ...prev,
                            [name]: Number(value),
                          }));
                          setother_course(true);
                        } else {
                          setother_course(false);
                          const { name, value } = e.target;
                          setFormData(prev => ({
                            ...prev,
                            [name]: Number(value),
                          }));
                        }
                      }}
                    >
                      <option value="">Choose...</option>
                      {availablecourses.map(x => {
                        return (
                          <>
                            <option value={x.id}>{x.course_name}</option>
                          </>
                        );
                      })}
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div
                    className={`col-md-6 col-lg-4 mb-4 ${
                      other_course ? '' : 'd-none'
                    }`}
                  >
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enter the course
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      name="othercourseenquired"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enquiry Location
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.enquiry_location}
                      name="enquiry_location"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div
                    style={{ display: 'none' }}
                    id="contactedpersondiv"
                  >
                    <div className="col-md-6 col-lg-4 mb-4">
                      <label
                        for="inputPassword4"
                        className="form-label"
                      >
                        Contacted Person's Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none  py-2"
                        id="inputPassword4"
                        onChange={handleInputChange}
                        value={formData.contacted_person_name}
                        name="contacted_person_name"
                      />
                    </div>

                    <div className="col-md-6 col-lg-4 mb-4">
                      <label
                        for="inputPassword4"
                        className="form-label"
                      >
                        Contacted Person's Number
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none  py-2"
                        id="inputPassword4"
                        onChange={handleInputChange}
                        value={formData.contacted_person_number}
                        name="contacted_person_number"
                      />
                    </div>
                  </div>
                  {/* Other source */}
                  <div
                    className="col-md-6 col-lg-4 mb-4"
                    id="other-source"
                    style={{ display: 'none' }}
                  >
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Enter the source
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      value={formData.source}
                      name="source"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      for="inputState"
                      className="form-label shadow-none"
                    >
                      Priority
                    </label>
                    <select
                      id="inputState"
                      className="form-select shadow-none  py-2"
                      name="priority"
                      value={formData.priority}
                      onChange={handleInputChange}
                    >
                      <option selected>Choose...</option>
                      <option>High</option>
                      <option>Medium</option>
                      <option>Low</option>
                    </select>
                  </div>

                  <div className="col-12 mb-3">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Remarks
                    </label>
                    <textarea
                      readOnly
                      type="text"
                      rows={3}
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      name="updated_remarks"
                      value={formData.updated_remarks}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-12 text-end mt-3">
                  <button
                    type="submit"
                    disabled={load}
                    className="btns text-white fw-medium px-2 px-lg-5"
                  >
                    {load ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit_customer_lead;
