import { useState } from 'react';

function DataSubCounts({ data, fieldName, fieldCount, title, fetchData }) {
  const [active, setActive] = useState();

  return (
    <div className="tw-p-4 tw-w-full tw-bg-gray-50 tw-rounded-md tw-shadow-md tw-max-h-[400px] tw-overflow-y-auto">
      <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700 tw-mb-4">
        {title}
      </h2>
      {data?.length > 0 ? (
        <ul className="tw-space-y-2">
          {data?.map((item, idx) => (
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setActive(idx);
                if (fetchData) {
                  fetchData(item?.[fieldName]);
                }
              }}
              key={idx}
              className={`tw-flex tw-justify-between tw-items-center ${
                active === idx
                  ? 'tw-bg-green-400 tw-text-white '
                  : 'tw-bg-white'
              } tw-border tw-rounded-md tw-shadow-sm tw-p-3 tw-transition-transform tw-duration-300 `}
            >
              <span className="tw-text-lg tw-capitalize ">
                {item?.[fieldName] || 'N/A'}
              </span>
              <span className="tw-text-xl tw-font-bold tw-text-indigo-600">
                {item?.[fieldCount] || 0}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="tw-text-gray-500">No closed data available.</p>
      )}
    </div>
  );
}

export default DataSubCounts;
