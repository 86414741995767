import { toast } from 'react-toastify';
import { ipaddress } from '../../App';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

function AddReasons({ type, setload, load }) {
  const [reason, setReason] = useState('');
  const [allReasons, setAllReasons] = useState([]);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonName, setReasonName] = useState({});

  // Fetch reasons
  const getReasons = () => {
    axios
      .get(`${ipaddress}/ReasonsForCloser/`)
      .then(data => {
        setAllReasons(data.data);
      })
      .catch(err => console.log(err));
  };

  // Add reason
  const addReasons = () => {
    setload(true);
    axios
      .post(`${ipaddress}/ReasonsForCloser/`, { reason })
      .then(() => {
        toast.success('Reason added successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setReason(''); // Clear the input
        getReasons(); // Refetch updated data
      })
      .finally(() => setload(false));
  };

  // Update reason
  const updateReasons = id => {
    axios
      .put(`${ipaddress}/ReasonsForCloser/${id}/`, {
        reason: reasonName.reason,
      })
      .then(() => {
        toast.success('Reason updated successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        getReasons(); // Refetch updated data
        setShowReasonModal(false); // Close modal
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getReasons();
  }, []); // Fetch data on initial load

  return (
    <div className={`${type === 'reasons' ? 'block' : 'tw-hidden'}`}>
      <div className="col-md-12 px-0 px-md-4 pb-4">
        <h6 className="fs-5 text-dark m-0 mb-3 p-2 p-md-4 bg-white">
          Customer Concerns
        </h6>
        <div className="bg-white py-md-5 row m-0 p-4 px-5">
          <div className="mb-3 col-lg-4 col-md-6 pb-3">
            <label htmlFor="reason">New Concern</label>
            <input
              type="text"
              value={reason}
              onChange={e => setReason(e.target.value)}
              className="form-control shadow-none py-2 border-secondary-subtle mt-2"
              id="reason"
            />
          </div>
          <div className="mb-3 col-md-6 col-lg-4 d-flex align-items-center pt-md-3">
            <button
              className="btns text-white px-5 py-2"
              onClick={addReasons}
              disabled={load}
            >
              {load ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="ms-2">Submitting...</span>
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="col-md-8 px-0 px-md-4 py-4 mx-auto">
        {allReasons?.length > 0 && (
          <div
            className="table-responsive p-2"
            style={{ height: '80vh', overflowY: 'scroll' }}
          >
            <table className="w-100 bg-white">
              <thead className="table-secondary">
                <tr style={{ backgroundColor: '#034592' }}>
                  <th
                    scope="col"
                    className="py-3"
                  >
                    <p className="text-white tw-pl-12 tw-text-start m-0 fw-medium">
                      SI No
                    </p>
                  </th>
                  <th scope="col">
                    <p className="text-white tw-pl-8 tw-text-start m-0 fw-medium">
                      Concern Type
                    </p>
                  </th>
                  <th scope="col">EDIT</th>
                </tr>
              </thead>
              <tbody>
                {allReasons?.map((eachReason, idx) => (
                  <tr
                    key={idx}
                    className="table-row"
                  >
                    <td
                      scope="row"
                      className="py-3"
                    >
                      <p className="text-center m-0">{idx + 1}</p>
                    </td>
                    <td className="py-3">
                      <p className="text-center m-0">{eachReason.reason}</p>
                    </td>
                    <td className="py-3">
                      <div className="text-center">
                        <svg
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setReasonName(eachReason);
                            setShowReasonModal(true);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#4C2987"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal
        show={showReasonModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fs-5"
          >
            Edit Reason
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => setShowReasonModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0 justify-content-center">
            <p className="text-center m-0 fw-medium mb-2 fs-5">
              Update the Name
            </p>
            <div className="col-12">
              <input
                type="text"
                className="form-control shadow-none border-secondary-subtle py-3"
                value={reasonName?.reason}
                onChange={e =>
                  setReasonName(prev => ({
                    ...prev,
                    reason: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-12 text-center mt-3">
              <button
                className="btn btn-primary px-5"
                onClick={() => updateReasons(reasonName.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddReasons;
