import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function RemarksSections({ x }) {
  return (
    <td
      className="table-row py-3"
      style={{ position: 'relative' }}
    >
      <OverlayTrigger
        placement="left" // Tooltip appears to the right
        overlay={
          <Tooltip id={`tooltip-${x.id}`}>
            <span
              style={{
                whiteSpace: 'normal', // Allow text wrapping
                minWidth: '500px', // Set max width for the tooltip
                wordWrap: 'break-word', // Break long words if needed
              }}
            >
              {x.remarks || '-'}
            </span>
          </Tooltip>
        }
      >
        <p
          className="m-0 tw-text-blue-600 py-2"
          style={{
            cursor: 'pointer',
          }}
        >
          {x.remarks ? `${x.remarks.slice(0, 10)}...` : '-'}
        </p>
      </OverlayTrigger>
    </td>
  );
}

export default RemarksSections;
