import React, { useEffect, useState } from 'react';
import Mode_of_payment_modal from './Mode_of_payment_modal';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from 'react-bootstrap/Modal';

const Sharable_Invoice_page = () => {
  const { id } = useParams();

  const [show, setshow] = useState(false);
  const [terms, setterms] = useState([]);

  useEffect(() => {
    axios
      .get(`${ipaddress}/TermsAndConditionsView/`)
      .then(r => {
        console.log('Terms & conditions', r.data);
        setterms(r.data);
      })
      .catch(err => {
        console.log('Terms error');
      });
  }, []);

  const navigate = useNavigate();

  const [printmode, setprintmode] = useState(false);

  const togglePrintMode = () => {
    setprintmode(prevMode => !prevMode);
  };

  const handleprint = () => {
    window.print();
  };

  const [indexvalue, setindexvalue] = useState(0);

  const [invoidedata, setinvoicedata] = useState([]);
  const [current_invoidedata, setcurrent_invoicedata] = useState({});
  const [installmentdata, setinstallmentdata] = useState([]);

  const [total, settotal] = useState();

  useEffect(() => {
    fetchobjectdata();
  }, [id]);

  const fetchobjectdata = () => {
    axios
      .get(`${ipaddress2}/ShowInvoice/${id}/`)
      .then(r => {
        console.log('particular invoice data', r.data);
        setcurrent_invoicedata(r.data);
      })
      .catch(err => {
        console.log('particular Invoice Error', err);
      });
  };

  const downloadCertificate = () => {
    const input = document.getElementById('invoice');
    html2canvas(input, { scale: 2 })
      .then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 10;
        const imgY = (pdfHeight - imgHeight * ratio) / 10;

        pdf.addImage(
          imgData,
          'PNG',
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio,
        );
        pdf.save('invoice.pdf');
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  return (
    <div className="py-2 bg-light">
      <div className="d-flex justify-content-center py-3">
        <button
          onClick={downloadCertificate}
          className="btn btn-primary d-flex align-items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
          </svg>{' '}
          <span className="ms-2">Download</span>
        </button>
      </div>
      {/* <div className='container'>
       <svg style={{cursor:'pointer'}} onClick={()=>{
              if(id.slice(0,3)==='CUS'){
                navigate('/customerleads')
              }
              else{
                navigate('/leads')
              }
            }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
       </div> */}

      <div
        className="row m-0 pb-4 mb-4"
        id="invoice"
      >
        <div className="col-md-8 mx-auto bg-white pb-4">
          {/* <h5 className='text-center py-2'>Invoice</h5> */}
          <img
            src={require('../images/Group 1171276014.png')}
            alt=""
            style={{ width: '100%' }}
          />

          <div className="px-4">
            <div className="d-flex justify-content-between align-items-center mt-4 px-4">
              <div>
                <p className="m-0 font1">
                  <span className="text-secondary me-3">To</span>
                </p>
                <p className="m-0 mt-1 font1">
                  <span
                    className="text-secondary me-3 fw-medium"
                    style={{ fontSize: '16px' }}
                  >
                    {current_invoidedata.user_details &&
                      current_invoidedata.user_details.customer_name !=
                        undefined &&
                      current_invoidedata.user_details.customer_name}
                  </span>
                </p>
                <p className="m-0 mt-1 font1">
                  <span
                    className="text-secondary me-3"
                    style={{ fontSize: '13px' }}
                  >
                    {current_invoidedata.user_details &&
                      current_invoidedata.user_details.customer_lead_id &&
                      current_invoidedata.user_details.customer_lead_id
                        .customer_lead_id != undefined &&
                      current_invoidedata.user_details.customer_lead_id
                        .customer_lead_id}
                  </span>
                </p>
              </div>

              <div>
                <p className="m-0 font1 d-flex justify-content-between">
                  <span className="text-secondary">Invoice No </span>
                  <span className="text-dark fw-medium">
                    {current_invoidedata.invoice_number}
                  </span>
                </p>
                <p className="m-0 mt-1 font1 d-flex justify-content-between">
                  <span className="text-secondary">Invoice Date </span>{' '}
                  <span className="text-dark fw-medium">
                    {current_invoidedata.created_at != undefined &&
                      current_invoidedata.created_at.slice(0, 10)}
                  </span>
                </p>
                <p className={`m-0 mt-1 font1 d-flex justify-content-between`}>
                  <span className="text-secondary">GST Number </span>{' '}
                  <span className="text-dark fw-medium">
                    {current_invoidedata.gst_number !== ''
                      ? `${
                          current_invoidedata.gst_number &&
                          current_invoidedata.gst_number
                        }`
                      : '-'}{' '}
                  </span>
                </p>
              </div>
            </div>

            <div className="row mt-4 m-0 px-4">
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed by
                  </h6>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Company</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.company_name}
                    </p>
                  </p>
                  <p className="m-0 d-flex mt-1">
                    <p className="invoce-para1 m-0">GST Number</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.gst_number &&
                        current_invoidedata.company_details.gst_number}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Address</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.Area}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">City</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.city}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">State</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.state}
                      ,{' '}
                      {current_invoidedata.company_details &&
                        current_invoidedata.company_details.pincode}
                    </p>
                  </p>
                </div>
              </div>

              {/* -----------------------------------------Client / Customer----------------------------------------------- */}
              <div className="col-md-6 ps-0">
                <div className="invoice_div rounded py-3">
                  <h6
                    className="fw-medium pb-1"
                    style={{ color: '#250F3C', fontSize: '16px' }}
                  >
                    Billed to
                  </h6>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Name</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.customer_name}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">Email</p>
                    <p
                      className={`invoce-para2 m-0 fw-medium text-black ${
                        id.slice(0, 3) === 'CUS' ? '' : 'd-none'
                      }`}
                    >
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.registration_id &&
                        current_invoidedata.user_details.registration_id
                          .customer_lead_id &&
                        current_invoidedata.user_details.registration_id
                          .customer_lead_id.email}
                    </p>
                    <p
                      className={`invoce-para2 m-0 fw-medium text-black ${
                        id.slice(0, 3) === 'CLI' ? '' : 'd-none'
                      }`}
                    >
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.registration_id &&
                        current_invoidedata.user_details.registration_id
                          .client_lead_id &&
                        current_invoidedata.user_details.registration_id
                          .client_lead_id.email}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">City</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.registration_id.City}
                    </p>
                  </p>
                  <p className="m-0 d-flex">
                    <p className="invoce-para1 m-0">State</p>
                    <p className="invoce-para2 m-0 fw-medium text-black">
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.registration_id.State}
                      ,{' '}
                      {current_invoidedata.user_details &&
                        current_invoidedata.user_details.registration_id
                          .Pincode}
                    </p>
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-1 pb-1">
              <div className="table-responsive">
                <table className="table-borderless w-100">
                  <thead className="border-bottom invoice-table">
                    <tr className="">
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">SI No</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Course Description</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Amount</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">SGST</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">CGST</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Total Amount</p>
                      </th>
                    </tr>
                  </thead>

                  {console.log('MUIZ', current_invoidedata)}
                  <tbody className="">
                    <tr
                    // className={`${
                    //   current_invoidedata.registration_fee === 0
                    //     ? 'd-none'
                    //     : ''
                    // }`}
                    >
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        1
                      </th>
                      <td
                        className="invoice-table-cell fw-medium tw-uppercase"
                        style={{ fontSize: '16px' }}
                      >
                        {current_invoidedata.softwatedevelopment}
                      </td>
                      <td className="invoice-table-cell fw-medium tw-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        {current_invoidedata.grand_total}
                      </td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium tw-flex ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        <span>
                          {(
                            current_invoidedata.grand_total -
                            current_invoidedata.grand_gst_total
                          ).toLocaleString()}
                        </span>
                      </td>
                    </tr>

                    {/* Events fees */}
                    <tr
                      className={`${
                        current_invoidedata.upcomingevent_id != null
                          ? ''
                          : 'd-none'
                      }`}
                    >
                      <th
                        scope="row"
                        className="invoice-table-cell"
                      >
                        1
                      </th>
                      <td
                        className="invoice-table-cell fw-medium"
                        style={{ fontSize: '16px' }}
                      >
                        Event Fees
                      </td>
                      <td className="invoice-table-cell fw-medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        {current_invoidedata.grand_total}
                      </td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium">-</td>
                      <td className="invoice-table-cell fw-medium">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        {current_invoidedata.grand_total}
                      </td>
                    </tr>

                    {current_invoidedata.course &&
                      current_invoidedata.course.map((a, index) => {
                        return (
                          <>
                            <tr className="">
                              <th
                                scope="row"
                                className="invoice-table-cell"
                              >
                                1
                              </th>
                              <td
                                className="invoice-table-cell fw-medium"
                                style={{ fontSize: '16px' }}
                              >
                                {a.course_name}
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-currency-rupee"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                </svg>
                                {/* {invoidedata[indexvalue].installment_status ? a.installment_fees : a.single_shot_fees} */}
                                {current_invoidedata.grand_total -
                                  current_invoidedata.grand_gst_total}
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                -
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                -
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-currency-rupee"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                </svg>
                                {current_invoidedata.grand_total -
                                  current_invoidedata.grand_gst_total}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    <tr
                      className={`${
                        current_invoidedata.grand_total > 0 ? 'd-none' : ''
                      }`}
                    >
                      <td
                        colspan="4"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium invoice-table py-2"
                        style={{ fontSize: '15px' }}
                      >
                        <span>Total Amount</span>
                      </td>
                      <td className="invoice-table text-center py-2">
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.grand_total -
                              current_invoidedata.grand_gst_total}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr
                      className={`${
                        current_invoidedata.grand_total > 0 ? 'd-none' : ''
                      }`}
                    >
                      <td
                        colspan="4"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium invoice-table py-2"
                        style={{ fontSize: '15px' }}
                      >
                        Tax
                      </td>
                      <td className="invoice-table text-center py-2">
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.grand_gst_total}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr
                      className={`${
                        current_invoidedata.grand_total > 0 ? 'd-none' : ''
                      }`}
                    >
                      <td
                        colspan="4"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium text-white py-3"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#250F3C',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-center py-3 fw-medium"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#D3D2D7',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {Number(current_invoidedata.grand_total) +
                              Number(current_invoidedata.registration_fee)}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>

                    {/* Events */}
                    {/* <tr className={`${current_invoidedata.grand_total>0 ? '':'d-none'}`}>
      <td colspan="4" className='' style={{color:'#40BA5A',fontSize:'17px'}}></td>
      <td className='text-center fw-medium invoice-table py-2' style={{fontSize:'15px'}}><span>Total Amount</span></td>
      <td className='invoice-table text-center py-2'><p className='m-0'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg> <span className=''>{current_invoidedata.grand_total}<span>/-</span></span></p></td>
    </tr> */}
                    <tr
                      className={`${
                        current_invoidedata.grand_total > 0 ? '' : 'd-none'
                      }`}
                    >
                      <td
                        colspan="4"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium invoice-table py-2"
                        style={{ fontSize: '15px' }}
                      >
                        Tax
                      </td>
                      <td className="invoice-table text-center py-2">
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {current_invoidedata.grand_gst_total}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr
                      className={`${
                        current_invoidedata.grand_total > 0 ? '' : 'd-none'
                      }`}
                    >
                      <td
                        colspan="4"
                        className=""
                        style={{ color: '#40BA5A', fontSize: '17px' }}
                      ></td>
                      <td
                        className="text-center fw-medium text-white py-3"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#250F3C',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-center py-3 fw-medium"
                        style={{
                          fontSize: '15px',
                          backgroundColor: '#D3D2D7',
                          borderTop: '4px solid #fff',
                        }}
                      >
                        <p className="m-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-currency-rupee"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                          </svg>{' '}
                          <span className="">
                            {Number(current_invoidedata.grand_total)}
                            <span>/-</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Installment table */}
            <div
              className={`mt-5 pb-1 ${
                current_invoidedata.course &&
                current_invoidedata.course.length > 0
                  ? ''
                  : 'd-none'
              }`}
            >
              <h6>Installment Details</h6>
              <div className="table-responsive">
                <table className="table-borderless w-100">
                  <thead className="border-bottom invoice-table">
                    <tr className="">
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Installment</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Amount</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Due Date</p>
                      </th>
                      <th
                        scope="col"
                        className="py-3"
                      >
                        <p className="text-center m-0">Status</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {current_invoidedata.course &&
                      current_invoidedata.due_dates.map((a, index) => {
                        return (
                          <>
                            <tr className="">
                              <th
                                scope="row"
                                className="invoice-table-cell"
                              >
                                Installment {index + 1}
                              </th>
                              <td className="invoice-table-cell fw-medium">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-currency-rupee"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                </svg>
                                {a.fees}
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                {a.due_date}
                              </td>
                              <td className="invoice-table-cell fw-medium">
                                {a.status}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* ---------------------------------------------------Terms and conditions--------------------------------------------- */}
            <div className="row m-0 mt-5 align-items-center">
              <div className="col-6">
                <p
                  style={{ color: '#000', fontWeight: 500, cursor: 'pointer' }}
                  onClick={() => {
                    setshow(true);
                  }}
                  className="ps-0 m-0 mb-2"
                >
                  <span style={{ color: 'red' }}>*</span>{' '}
                  <span className="text-primary text-decoration-underline">
                    {' '}
                    Terms & Condition
                  </span>
                </p>
                {/* <span style={{fontSize:'13px'}}>1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde facilis praesentium consequatur dolor quia tenetur beatae quaerat ad numquam neque.</span> */}
                <div className="text-center">
                  {/* <img src={require('../images/png-01 2 1.png')} alt="" /> */}
                </div>
              </div>
              <div className="col-6 text-center">Signature</div>
            </div>

            <div
              style={{ position: 'relative' }}
              className="mt-2"
            >
              <div
                className="d-flex justify-content-evenly px-3 py-1 align-items-center"
                style={{ backgroundColor: '#250F3C', height: '30px' }}
              >
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>merida@gmail.com</span>
                </p>
                <p className="m-0 text-white">
                  <span className="bg-white rounded px-1 d-felx align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#000"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>
                    Jayanagar, Bangalore.
                  </span>
                </p>
              </div>
              <div
                className="d-flex gap-4"
                style={{ backgroundColor: '#57309C', height: '10px' }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------Ask for help------------------------------------------------------ */}
      <div
        class="modal fade"
        id="need_help"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
                <h5 className="text-primary">Need a help</h5>
                <button
                  data-bs-dismiss="modal"
                  className="bg-transparent border-0 ms-auto"
                >
                  <i class="fa-solid fa-circle-xmark fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mode_of_payment_modal />

      {/* Terms and conditions */}
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
        }}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <Modal.Title className="text-success fs-5">
            Terms and Conditions
          </Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setshow(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            {terms.map((x, index) => {
              return (
                <div>
                  <p>
                    <span className="fw-bold">{index + 1}) </span>
                    {x.terms_and_conditions}
                  </p>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sharable_Invoice_page;
