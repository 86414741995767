import axios from 'axios';
import { ipaddress } from '../../App';
import { useEffect, useState } from 'react';
import DataCount from '../All-leads/ClosedFilter/DataCount';

function ProspectSummary({ fetchData }) {
  const [prospectData, setProspectData] = useState();

  const getCourseWiseProspectCount = () => {
    axios

      .get(`${ipaddress}/CourseWiseProspectCount/`)
      .then(res => {
        setProspectData(res.data);
        console.log('CourseWiseProspectCount', res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getCourseWiseProspectCount();
  }, []);

  return (
    <div className="tw-p-4 tw-w-[25%] tw-bg-gray-50 tw-rounded-md tw-shadow-md tw-max-h-[400px] tw-overflow-y-auto">
      <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700 tw-mb-4">
        Course Wise Prospects Summary
      </h2>
      {prospectData?.course_wise_count?.length > 0 ? (
        <ul className="tw-space-y-2">
          {prospectData?.course_wise_count?.map((item, idx) => (
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (fetchData) {
                  fetchData(item?.course_name);
                }
              }}
              key={idx}
              className="tw-flex tw-justify-between tw-items-center tw-bg-white tw-border tw-rounded-md tw-shadow-sm tw-p-3 tw-transition-transform tw-duration-300 "
            >
              <span className="tw-text-lg tw-capitalize tw-text-gray-800">
                {item?.course_name || 'N/A'}
              </span>
              <span className="tw-text-xl tw-font-bold tw-text-indigo-600">
                {item?.distinct_lead_count || 0}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="tw-text-gray-500">No closed data available.</p>
      )}
    </div>
  );
}

export default ProspectSummary;

/* <DataCount
  fetchData={fetchData}
  data={prospectData?.course_wise_count}
  fieldCount={'distinct_lead_count'}
  fieldName={'course_name'}
  title={'Course Wise Prospects Summary'}
/> */
