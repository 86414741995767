import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import Preloader from './Preloader';

const Dashboard = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const designation = user.designation;

  const [loading, setLoading] = useState(true);

  console.log(designation);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    // initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [allleads, setallleads] = useState([]);
  const [allleadscount, setallleadscount] = useState([]);

  const [sourcedata, setsourcedata] = useState([]);
  const [sourcecount, setsourcecount] = useState([]);

  const [coursedata, setcoursedata] = useState([]);
  const [emp_compdata, setemp_compdata] = useState([]);

  const [titles, settitles] = useState([]);
  const [newleads, setnewleads] = useState([]);
  const [followup_leads, setfollowup_leads] = useState([]);
  const [prospects_leads, setprospects_leads] = useState([]);
  // const[_leads,set_leads]=useState([])

  const [title, settitle] = useState([]);
  const [targets, settargets] = useState([]);
  const [achieved, setachieved] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress2}/employee/${user.emp_id}/activities/`).then(r => {
      console.log('Activities graph', r.data.datasets);
      const data = r.data.datasets;

      const titles = data.map(item => item.activity_name);
      const totalMarks = data.map(item => item.Target);
      const marksAchieved = data.map(item => item.achieved);
      settitle(titles);
      settargets(totalMarks);
      setachieved(marksAchieved);
    });
  }, []);

  const activitiesgraph = {
    labels: title,
    datasets: [
      {
        label: 'Target',
        data: targets,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Achieved',
        data: achieved,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  useEffect(() => {
    axios
      .get(
        `${ipaddress}/SupervisedEmployeeLeadsPerformanceView/${user.emp_id}/`,
      )
      .then(r => {
        console.log('Employee comparision', r.data.datasets);
        setemp_compdata(r.data.datasets);
      });

    axios.get(`${ipaddress}/Leads_chart_by_course/${user.emp_id}/`).then(r => {
      console.log('Course based leads', r.data.datasets);
      setcoursedata(r.data.datasets);
    });

    axios
      .get(`${ipaddress}/Leads_chart_by_source_bargraph/${user.emp_id}/`)
      .then(r => {
        console.log('Sourse based leads', r.data);
        setsourcedata(r.data.datasets);
      });

    // All leads count
    axios
      .get(`${ipaddress}/Leads_chart_by_total_leads/${user.emp_id}/`)
      .then(r => {
        console.log('All leads count', r.data);
        const leadstages = r.data.datasets.map(item => item.lead_level);
        setallleads(leadstages);

        const leadcounts = r.data.datasets.map(x => x.lead_level_count);
        setallleadscount(leadcounts);
      });

    setLoading(false);
  }, []);

  const leadsbycourse = {
    labels: coursedata.map(x => x.label),
    datasets: [
      {
        label: 'New Leads',
        data: coursedata.map(item => item.data[0]),
        fill: false,
        backgroundColor: 'rgb(27,98,169)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Followups',
        data: coursedata.map(item => item.data[1]),
        fill: false,
        backgroundColor: 'rgb(255,173,51)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Prospects',
        data: coursedata.map(item => item.data[2]),
        fill: false,
        backgroundColor: 'rgb(255,76,76)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Regsitered',
        data: coursedata.map(item => item.data[3]),
        fill: true,
        backgroundColor: 'rgb(72,193,255)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Closed',
        data: coursedata.map(item => item.data[4]),
        fill: false,
        backgroundColor: 'rgb(173,173,133)',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const employeecomparision = {
    labels: emp_compdata.map(item => item.employee_name),
    datasets: [
      {
        label: 'New Leads',
        data: emp_compdata.map(item => item.values[0]),
        fill: false,
        backgroundColor: 'rgb(27,98,169)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Followups',
        data: emp_compdata.map(item => item.values[1]),
        fill: false,
        backgroundColor: 'rgb(255,173,51)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Prospects',
        data: emp_compdata.map(item => item.values[2]),
        fill: false,
        backgroundColor: 'rgb(255,76,76)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Regsitered',
        data: emp_compdata.map(item => item.values[3]),
        fill: true,
        backgroundColor: 'rgb(72,193,255)',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Closed',
        data: emp_compdata.map(item => item.values[4]),
        fill: false,
        backgroundColor: 'rgb(173,173,133)',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const leadsbysource = {
    labels: ['New Leads', 'Followups', 'Prospects', 'Registered', 'Closed'],
    datasets: sourcedata,
  };

  const leadsdata = {
    labels: allleads,
    datasets: [
      {
        label: 'Leads',
        data: allleadscount,
        fill: false,
        backgroundColor: [
          'rgb(51,153,255)',
          'rgb(255,173,51)',
          'rgb(255,113,113)',
          'rgb(72,193,255)',
          'rgb(173,173,133)',
        ],
        tension: 0.1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20,
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };
  // // Chart options
  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  // const fetchData = async () => {
  //   const sampleData = {
  //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  //     values: [65, 59, 80, 81, 56, 55, 40],
  //   };

  //   setChartData({ labels: sampleData.labels, datasets: [{ label: 'My Dataset', data: sampleData.values }] });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div className="d-flex">
          <Sidebar
            designation={designation}
            activevalue={'dashboard'}
          />
          <div className="w-100 main-div">
            <Navbar
              designation={designation}
              pagename={'Dashboard'}
            />
            <div className="pb-5 pt-4 bg-light main-container px-2 px-md-4">
              <div className="">
                <div className="row m-0 dashboard-div">
                  <div className="col-md-8 px-5 d-flex flex-column justify-content-center">
                    <p
                      className="text-white fw-medium"
                      style={{ fontSize: '35px' }}
                    >
                      Welcome Back MUIZ , {user.firstname}
                    </p>
                    <p
                      className="text-white"
                      style={{ fontSize: '15px' }}
                    >
                      You have 27 new student added to your domain. Please reach
                      out to the Head Teacher if you want them excluded from
                      your domain.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={require('../images/Teacher and student.png')}
                      style={{ width: '90%', height: '90%' }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Slider {...settings}>
                  <div className="p-2">
                    <div className="py-3 px-3 shadow-sm dash-subdiv">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <p
                            className="m-0"
                            style={{ color: '', fontSize: '15px' }}
                          >
                            New Leads
                          </p>
                        </div>
                        <img
                          src={require('../images/Icon (2).png')}
                          className="bg-white"
                          width={36}
                          height={36}
                          alt=""
                        />
                      </div>
                      <span
                        className="fw-medium"
                        style={{ fontSize: '20px', color: '#202224' }}
                      >
                        {allleadscount[0]}
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="py-3 px-3 shadow-sm dash-subdiv">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <p
                            className="m-0"
                            style={{ color: '', fontSize: '15px' }}
                          >
                            Followup Leads
                          </p>
                        </div>
                        <img
                          src={require('../images/ico1.png')}
                          className="bg-white"
                          width={36}
                          height={36}
                          alt=""
                        />
                      </div>
                      <span
                        className="fw-medium"
                        style={{ fontSize: '20px', color: '#202224' }}
                      >
                        {allleadscount[4]}
                      </span>
                      {/* <div className='d-flex align-items-center justify-content-between px-2'>
                      <p className='m-0 d-flex align-items-center fw-medium' style={{color:'#2C2D32'}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg> <span className='ms-2'>1000</span></p>
                      <img src={require('../images/graph.png')} width={80} height={60} alt="" />
                    </div> */}
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="py-3 px-3 shadow-sm dash-subdiv">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <p
                            className="m-0"
                            style={{ color: '', fontSize: '15px' }}
                          >
                            Prospect Leads
                          </p>
                        </div>
                        <img
                          src={require('../images/Group 27231.png')}
                          className="bg-white"
                          width={36}
                          height={36}
                          alt=""
                        />
                      </div>
                      <span
                        className="fw-medium"
                        style={{ fontSize: '20px', color: '#202224' }}
                      >
                        {allleadscount[1]}
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="py-3 px-3 shadow-sm dash-subdiv">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <p
                            className="m-0"
                            style={{ color: '', fontSize: '15px' }}
                          >
                            Registered Leads
                          </p>
                        </div>
                        <img
                          src={require('../images/Icon (2).png')}
                          className="bg-white"
                          width={36}
                          height={36}
                          alt=""
                        />
                      </div>
                      <span
                        className="fw-medium"
                        style={{ fontSize: '20px', color: '#202224' }}
                      >
                        {allleadscount[3]}
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="py-3 px-3 shadow-sm dash-subdiv">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <p
                            className="m-0"
                            style={{ color: '', fontSize: '15px' }}
                          >
                            Closed Leads
                          </p>
                        </div>
                        <img
                          src={require('../images/Group 27232.png')}
                          className="bg-white"
                          width={36}
                          height={36}
                          alt=""
                        />
                      </div>
                      <span
                        className="fw-medium"
                        style={{ fontSize: '20px', color: '#202224' }}
                      >
                        {allleadscount[2]}
                      </span>
                    </div>
                  </div>
                </Slider>
              </div>

              {/* ---------------------------------CHARTS AND GRAPHS--------------------------------------------- */}

              <div className="row m-0 mt-5 pt-3 gap-0 gap-lg-4 justify-content-center">
                <div
                  className={`d-flex flex-column mb-5 mb-lg-0 bg-white pt-3 p-3 ${
                    designation === 'central manager' || designation === 'admin'
                      ? 'col-lg-7'
                      : 'col-lg-7'
                  }`}
                  style={{ borderRadius: '10px' }}
                >
                  <h5 className="text-dark mb-3 mb-lg-4">
                    Leads Chart by Course
                  </h5>
                  <Bar
                    data={leadsbycourse}
                    options={options}
                  />
                </div>
                <div
                  className="col-lg-4 d-flex bg-white p-3 flex-column mb-5 mb-lg-0 pt-3"
                  style={{ borderRadius: '10px' }}
                >
                  <h5 className="text-dark mb-3">Leads Chart</h5>
                  <div
                    className="mx-auto"
                    style={{ width: '300px' }}
                  >
                    <Doughnut
                      data={leadsdata}
                      options={options}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0 mt-lg-4 pt-3 px-3 gap-0 gap-lg-4 justify-content-center">
                <div
                  className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 py-3`}
                  style={{ borderRadius: '10px' }}
                >
                  <h5 className="text-dark mb-3 mb-lg-4">Activities Graph</h5>
                  <Bar
                    data={activitiesgraph}
                    options={options}
                  />
                </div>
                <div
                  className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 py-3`}
                  style={{ borderRadius: '10px' }}
                >
                  <h5 className="text-dark mb-3 mb-lg-4">
                    Leads chart based on Source
                  </h5>
                  <Bar
                    data={leadsbysource}
                    options={options}
                  />
                </div>
                <div
                  className={`col-md-9 mb-5 mt-3 mb-lg-0 bg-white p-3 py-3 ${
                    designation === 'central manager' || designation === 'admin'
                      ? ' '
                      : 'd-none'
                  }`}
                  style={{ borderRadius: '10px' }}
                >
                  <h5 className="text-dark mb-3 mb-lg-4">
                    Employee Comparision Chart
                  </h5>
                  <Bar
                    data={employeecomparision}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
