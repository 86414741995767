import THead from './THead';

function BillWiseFiltering({ apiData }) {
  console.log('API', apiData);
  const processData = apiData => {
    const dataMap = {};
    const datesSet = new Set();

    apiData?.length !== 0 &&
      apiData?.forEach(({ date, sources, courses }) => {
        datesSet.add(date); // Collect unique dates

        // Process courses
        if (courses) {
          Object.entries(courses).forEach(([course, values]) => {
            if (!dataMap[course]) {
              dataMap[course] = { totalBilling: 0, dates: {}, type: 'Course' };
            }
            dataMap[course].totalBilling += values.total_billing;
            dataMap[course].dates[date] = values.total_billing || 0;
          });
        }

        // Process sources
        if (sources) {
          Object.entries(sources).forEach(([source, values]) => {
            if (!dataMap[source]) {
              dataMap[source] = { totalBilling: 0, dates: {}, type: 'Source' };
            }
            dataMap[source].totalBilling += values.total_billing;
            dataMap[source].dates[date] = values.total_billing || 0;
          });
        }
      });

    const dates = Array.from(datesSet).sort(); // Sort dates for column order
    const processedData = Object.entries(dataMap).map(
      ([key, details], index) => ({
        sNo: index + 1,
        name: key || 'Unknown',
        type: details.type,
        achieved: details.totalBilling,
        dates: details.dates,
      }),
    );

    return { processedData, dates };
  };

  const { processedData, dates } = processData(apiData);

  return (
    <div className="tw-overflow-x-auto tw-px-4">
      <table className="tw-w-full tw-table-auto tw-rounded-lg tw-shadow-md">
        <thead>
          <tr className="tw-bg-gray-200">
            <th className="tw-py-3 tw-px-4">S.No</th>
            <th className="tw-py-3 tw-px-4">Name</th>
            <th className="tw-py-3 tw-px-4">Type</th>
            <th className="tw-py-3 tw-px-4">Total Billing</th>
            {dates.map(date => (
              <th
                key={date}
                className="tw-py-3 tw-px-4"
              >
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {processedData?.length === 0 ? (
            <tr>
              <td
                colSpan={4 + dates.length}
                className="tw-text-center tw-py-6 tw-text-gray-500"
              >
                No Data Available
              </td>
            </tr>
          ) : (
            processedData?.map(item => (
              <tr
                key={item.sNo}
                className="tw-bg-white hover:tw-bg-gray-100"
              >
                <td className="tw-py-3 tw-px-4">{item.sNo}</td>
                <td className="tw-py-3 tw-px-4">{item.name}</td>
                <td className="tw-py-3 tw-px-4">{item.type}</td>
                <td className="tw-py-3 tw-px-4">{item.achieved}</td>
                {dates.map(date => (
                  <td
                    key={date}
                    className="tw-py-3 tw-px-4"
                  >
                    {item.dates[date] || 0}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BillWiseFiltering;
