import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ipaddress, ipaddress2 } from '../App';
import axios from 'axios';
import Arrow_svg from '../Svgs/Arrow_svg';

const Client_quotation_register_form = () => {
  const { id } = useParams();

  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);

  const sourcedata = e => {
    if (e.target.value === 'walkin') {
      document.getElementById('contactedpersondiv').style.display = 'block';
    }
    if (e.target.value === 'other') {
      document.getElementById('othersource').style.display = 'block';
    }
  };

  const [availablecourses, setAvailablecourses] = useState([]);
  const [lead_levels, setlead_levels] = useState([]);
  const [particularlead_data, setparticularlead_data] = useState({});
  const [email, setemail] = useState('');
  const [quotation_details, setquotation_details] = useState({});

  useEffect(() => {
    axios
      .get(`${ipaddress}/States/`)
      .then(r => {
        console.log('States successfully fetched', r.data);
        setstates(r.data);
      })
      .catch(err => {
        console.log('States fetching Error', err);
      });

    // To fetch particular lead details
    axios
      .get(`${ipaddress}/LeadDetailsView/${id}/`)
      .then(r => {
        console.log('Particular Lead Data fetched', r.data);
        setparticularlead_data(r.data);
        setquotation_details(r.data.quotation_id);
        setemail(r.data.email);
        setduration(r.data.course_duration);
      })
      .catch(err => {
        console.log('Particular lead details fetching Error', err);
      });
  }, []);

  const [classtypes, setclasstypes] = useState([]);
  const [batchtypes, setbatchtypes] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress}/Allclasstypes/`).then(r => {
      console.log('Class types', r.data);
      setclasstypes(r.data.classtypes);
    });

    axios.get(`${ipaddress}/Allbatchtypes/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data.batchtypes);
    });
  }, []);

  const fetchcities = state => {
    axios(`${ipaddress}/Cities/${state}/`)
      .then(r => {
        console.log('Cities successfully fetched', r.data);
        setcities(r.data);
      })
      .catch(err => {
        console.log('Cities fetching Error', err);
      });
  };

  // Back Date Validation
  const handleDateValidation = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert('You can only select today’s date or future dates.');
      return false;
    }

    setFormData({
      ...formData,
      [name]: date,
    });

    return true;
  };

  const [formData, setFormData] = useState({
    emp_id: user.emp_id,
    customer_lead_id: '',
    client_lead_id: '',
    purpose: particularlead_data.purpose,
    quote_amount: '',
    grand_total: '',
    level_lead: 'registered',
    // due_date:'',
    installment_status: false,
    initial_payment: '',
    Remarks: '',
    installment_number: 0,
    installment_days: 0,
    grand_gst_total: 0,
    installments: [],
    // form_url:`https://crmclient.skilllearningacademy.com/registration_form`,
    // performa_url:`https://crmclient.skilllearningacademy.com/performa_invoice`
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = e => {
    const { name, checked, value } = e.target;
    setFormData(prevState => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  // Generate Performa Invoice
  // const generate_performa_invoice=()=>{
  // axios.post(`${ipaddress2}/PerformaInvoiceGenerator/${id}/`)
  // .then((r)=>{
  //   console.log("Performa invoice generated",r.data)
  // })
  // .catch((err)=>{
  //   console.log("Performa error",err)
  // })
  // }

  const senddetails = e => {
    e.preventDefault();
    processing();
  };

  const installment = () => {
    processing();
  };

  const processing = () => {
    setloading2(true);
    if (id.slice(0, 3) === 'CLI') {
      formData.client_lead_id = id;
    }
    // else{
    //   formData.customer_lead_id=id
    // }
    formData.purpose = particularlead_data.purpose;
    formData.installment_days = installment_days;
    formData.grand_gst_total = gst_total;
    formData.installment_number = installments_count;
    formData.installments = installments;
    formData.grand_gst_total = Number(formData.grand_total * 0.18);

    if (
      particularlead_data.quotation_id &&
      particularlead_data.quotation_id.altered_quotation != undefined
    ) {
      formData.quote_amount =
        particularlead_data.quotation_id.altered_quotation;
    } else {
      formData.quote_amount = particularlead_data.quotation_id.quote_amount;
    }
    console.log(formData);

    axios
      .put(`${ipaddress}/Quotation/`, {
        id: quotation_details.id,
        accepted_status: 'accepted',
      })
      .then(r => {
        setload(false);
        console.log('Accepted', r.data);
        move_to_register();
      });
  };

  const move_to_register = () => {
    axios
      .post(`${ipaddress}/Dummy/`, formData)
      .then(r => {
        setloading2(false);
        console.log('Regstration Completed', r.data);
        toast.success('Successfully Registered', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });

        setTimeout(() => {
          if (id.slice(0, 3) === 'CLI') {
            navigate('/leads');
          } else {
            navigate('/customerleads');
          }
        }, 2000);
      })
      .catch(err => {
        setloading2(false);
        console.log('Error', err);
        console.log(formData);
      });
  };

  const [formstate, setformstate] = useState(false);
  const [otpstate, setotpstate] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');

  const sendotp = () => {
    // e.preventDefault()
    axios
      .post(`${ipaddress}/send-otp/`, {
        email: email,
      })
      .then(r => {
        console.log('Otp sent suceessfully', r.data);
        setbackendotp(r.data.otp);
      })
      .catch(err => {
        console.log('Otp sending error', err);
      });
  };

  const verifyotp = () => {
    console.log(otp, backendotp);
    if (otp === backendotp) {
      toast.success('OTP verified successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
      setotpstate(false);
      setformstate(true);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    }
  };

  // Installments
  const [installments, setInstallments] = useState([]);
  const [installments_count, setinstallments_count] = useState(0);
  const [installments_amount, setinstallments_amount] = useState(0);
  const [gst_total, setgst_total] = useState(0);
  const [installment_days, setinstallment_days] = useState(0);
  const [duration, setduration] = useState();

  useEffect(() => {
    if (installments_count) {
      setInstallments(Array.from({ length: installments_count }, () => ''));
    }
  }, [installments_count]);

  const handleInstallmentChange = (index, value) => {
    setInstallments(prev => {
      const newAmounts = [...prev];
      newAmounts[index] = value;
      return newAmounts;
    });
  };

  const [loading2, setloading2] = useState();
  const [loading3, setloading3] = useState();

  const [state, setstate] = useState(false);

  const [discountdata, setdiscountdata] = useState({
    emp_id: user.emp_id,
    customer_lead_id: '',
    client_lead_id: '',
    Date_of_Birth: '',
    Remarks: '',
    Course: '',
    Course_Duration: '',
    Batch_Type: '',
    Class_Type: '',
    Batch_Start_Time: '',
    Batch_End_Time: '',
    Tentative_Start_Date: '',
    Registration_Date: '',
    level_lead: 'registered',
    installment_status: false,
    initial_payment: 0,
    form_url: `https://crmclient.skilllearningacademy.com/registration_form`,
    performa_url: `https://crmclient.skilllearningacademy.com/performa_invoice`,
    installment_number: 0,
    grand_total: 0,
    installment_days: 0,
    grand_gst_total: 0,
    installments: [],
    // performa_url:`https://crmclient.skilllearningacademy.com/performa_invoice`
  });
  const ask_discount = () => {
    setloading3(true);
    if (id.slice(0, 3) === 'CLI') {
      discountdata.client_lead_id = id;
    } else {
      discountdata.customer_lead_id = id;
    }

    discountdata.Course = particularlead_data.course_enquiredd;
    discountdata.Remarks = formData.Remarks;
    discountdata.Course_Duration = duration;
    discountdata.Batch_Type = formData.Batch_Type;
    discountdata.Class_Type = formData.Class_Type;
    discountdata.Batch_Start_Time = formData.Batch_Start_Time;
    discountdata.Batch_End_Time = formData.Batch_End_Time;
    discountdata.Tentative_Start_Date = formData.Tentative_Start_Date;
    discountdata.Registration_Date = formData.Registration_Date;
    discountdata.installment_status = formData.installment_status;
    discountdata.initial_payment = formData.initial_payment;
    discountdata.installment_days = Number(installment_days);
    discountdata.grand_total = Number(installments_amount);
    discountdata.grand_gst_total = Number(gst_total);
    discountdata.installment_number = Number(installments_count);
    discountdata.installments = installments;

    console.log('Discount', discountdata);
    axios
      .post(`${ipaddress}/Dummy/`, discountdata)
      .then(r => {
        setloading3(false);
        setstate(false);
        console.log('Success', r.data);
        toast.success('Data sent to admin', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setTimeout(() => {
          if (id.slice(0, 3) === 'CLI') {
            navigate('/leads');
          } else {
            navigate('/customerleads');
          }
        }, 2000);
      })
      .catch(err => {
        setloading3(false);
      });
  };

  // Update email id
  const [value, setvalue] = useState('');
  const [load, setload] = useState(false);
  const update_email = () => {
    setload(true);
    if (id.slice(0, 3) === 'CUS') {
      setvalue('Customer');
    }
    if (id.slice(0, 3) === 'CLI') {
      setvalue('Client');
    }
  };

  useEffect(() => {
    if (value !== '') {
      update();
    }
  }, [value]);

  const update = () => {
    axios
      .post(`${ipaddress}/UpdateEmail/`, {
        email: email,
        id: id,
        customer: value,
      })
      .then(r => {
        toast.success('Email updated!', {
          theme: 'colored',
          autoClose: 1000,
          position: 'top-center',
        });
        console.log('Email updated', r.data);
        setload(false);
        setvalue('');
      });
  };

  // Email verification
  const [load2, setload2] = useState();
  const [message, setmessage] = useState();

  const verify_email = email => {
    if (email.length > 0) {
      setload2(true);
      setTimeout(() => {
        verify(email);
      }, 1000);
    }
  };

  const verify = email => {
    axios.get(`${ipaddress}/EmailCheck/${email}/`).then(r => {
      console.log('Email verified', r.data);
      setload2(false);

      if (r.data.message === 'mail_notexist_customerlead') {
        setmessage(true);
        setemail(email);
      } else {
        setmessage(false);
        setemail(email);
      }
    });
  };

  // Accept or Reject Quotation
  // const[load,setload]=useState(false)

  const accept_quotation = (id, status, client_id) => {
    setload(true);
    axios
      .patch(`${ipaddress}/Quotation/`, {
        id: id,
        accepted_status: status,
      })
      .then(r => {
        setload(false);
        console.log('Accepted', r.data);
        if (status === 'accepted') {
          navigate(`/client_register_form/${client_id}`);
        }
      });
  };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ backgroundColor: '#E8E8E8', minHeight: '100vh' }}
    >
      <div className="container-fluid row m-0 p-2 p-md-3">
        <div className="mb-4 d-flex align-items-center ps-md-0">
          <Link
            className="text-dark"
            to="/leads"
          >
            <Arrow_svg />
          </Link>
          <h5
            className="m-0 ms-2 ms-md-4"
            style={{
              color: '#002147',
              letterSpacing: '1px',
              fontWeight: 600,
              fontSize: '17px',
            }}
          >
            QUOTATION ACCEPTANCE FORM
          </h5>
        </div>
        <div className="col-12 bg-white py-3 py-md-5 px-md-4">
          <form
            className=""
            onSubmit={senddetails}
          >
            {/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
            <div className="row m-0 border-bottom pb-2">
              <h6 className="text-color fw-bold mb-3">Personal Details</h6>
              <div className="col-md-6 col-lg-3 mb-3">
                <label
                  for="inputEmail4"
                  className="form-label"
                >
                  Lead Id<span className="required-field">*</span>
                </label>
                <input
                  type="text"
                  value={id}
                  disabled="true"
                  className="form-control shadow-none  py-2"
                  id="inputEmail4"
                />
              </div>

              <div
                className="col-md-6 mb-3 d-flex align-items-center"
                style={{ zIndex: 0 }}
              >
                <div className="w-50">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Email
                  </label>
                  <div class="input-group form-inputs">
                    <input
                      type="email"
                      className="form-control shadow-none  py-2 border-0 bg-transparent"
                      id="inputPassword4"
                      name="email"
                      value={email}
                      onChange={e => {
                        verify_email(e.target.value);
                      }}
                    />
                    <span
                      class="input-group-text border-0 bg-transparent"
                      id="basic-addon2"
                    >
                      <div
                        class={`spinner-border spinner-border-sm text-success ${
                          load2 ? '' : 'd-none'
                        }`}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#198754"
                        class={`bi bi-check2-circle ${
                          !load2 && message == true ? '' : 'd-none'
                        }`}
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                      </svg>
                    </span>
                  </div>
                  <p
                    className={`text-danger mt-1 ${
                      message == false ? '' : 'd-none'
                    }`}
                    style={{ fontSize: '13px' }}
                  >
                    *Email already exist
                  </p>
                </div>
                <div className=" ms-2 h-100 d-flex align-items-end">
                  <button
                    className="btns text-white py-2 px-3"
                    onClick={() => {
                      update_email();
                    }}
                  >
                    {load ? 'Updating...' : 'Update Email'}
                  </button>

                  <button
                    className="btns text-white py-2 px-3 ms-3"
                    onClick={() => {
                      sendotp();
                      setotpstate(true);
                    }}
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            </div>

            <div className={`${formstate ? '' : ''}`}>
              {/* ----------PROOF DETAILS----------------------- */}
              {/* <div className="row m-0 border-bottom py-3">
                <h6 className='text-color fw-bold mb-3'>Proof Documents</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Date of birth <span className='required-field'>*</span></label>
  <input required type="date" className="form-control shadow-none " id="inputPassword4" value={formData.Date_of_Birth} name='Date_of_Birth' onChange={handleInputChange}/>

  </div>
</div> */}

              {/* --------------------------------Course Details------------------------------ */}
              <div className="row m-0 border-bottom py-3">
                <h6 className="text-color fw-bold mb-3">Purpose</h6>
                <div className="col-md-6 col-lg-4 mb-4">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Purpose<span className="required-field">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    disabled
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="purpose"
                    value={particularlead_data.purpose}
                    onChange={e => {
                      handleInputChange(e.target.value);
                    }}
                  />
                </div>
                {particularlead_data.quotation_id &&
                particularlead_data.quotation_id.altered_quotation ? (
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Quotation Amount<span className="required-field">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      disabled
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      name="purpose"
                      value={
                        particularlead_data.quotation_id &&
                        particularlead_data.quotation_id.altered_quotation &&
                        particularlead_data.quotation_id.altered_quotation
                      }
                      onChange={e => {
                        handleInputChange(e.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-md-6 col-lg-4 mb-4">
                    <label
                      for="inputPassword4"
                      className="form-label"
                    >
                      Quotation Amount<span className="required-field">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      disabled
                      className="form-control shadow-none  py-2"
                      id="inputPassword4"
                      name="purpose"
                      value={
                        particularlead_data.quotation_id &&
                        particularlead_data.quotation_id.quote_amount &&
                        particularlead_data.quotation_id.quote_amount
                      }
                      onChange={e => {
                        handleInputChange(e.target.value);
                      }}
                    />
                  </div>
                )}

                <div className="col-md-6 col-lg-4 mb-4">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Accepted Amount<span className="required-field">*</span>
                  </label>
                  <input
                    required
                    type="number"
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="grand_total"
                    value={formData.grand_total}
                    onChange={e => {
                      const { name, value } = e.target;
                      setFormData(prev => ({
                        ...prev,
                        [name]: Number(value),
                      }));
                    }}
                  />
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                  <label
                    for="inputPassword4"
                    className="form-label"
                  >
                    Initial Payment Amount
                  </label>
                  <input
                    required
                    type="number"
                    value={formData.initial_payment}
                    className="form-control shadow-none  py-2"
                    id="inputPassword4"
                    name="initial_payment"
                    onChange={e => {
                      const { name, value } = e.target;
                      setFormData(prev => ({
                        ...prev,
                        [name]: Number(value),
                      }));
                    }}
                  />
                </div>
              </div>

              {/* ------------------------------------------------Complete Batch Details---------------------------------------------- */}
              <div className="row m-0 border-bottom py-3">
                <div className="col-12">
                  <label
                    for="inputPassword4"
                    className="form-label d-flex"
                  >
                    {' '}
                    Description <span className="required-field">*</span>
                  </label>
                  <textarea
                    rows={3}
                    required
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                    className="form-control  shadow-none"
                    id=""
                  ></textarea>
                </div>
                <div
                  className={`col-12 d-flex align-items-center mt-3 ${
                    formData.Remarks.length > 0 ? '' : 'd-none'
                  }`}
                >
                  <input
                    name="installment_status"
                    type="checkbox"
                    id="checkbox1"
                    onClick={e => {
                      const { checked } = e.target;
                      if (checked == true) {
                        setstate(true);
                      }
                      setFormData(prev => ({
                        ...prev,
                        installment_status: checked,
                      }));
                    }}
                  />
                  <label
                    htmlFor="checkbox1"
                    className="ms-2 text-color"
                    style={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Pay Through Installment
                  </label>
                </div>
              </div>
              <div className="col-12 text-end mt-3">
                <button
                  type="submit"
                  className={`btns text-white fw-medium px-2 px-lg-5`}
                >
                  {loading2 ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Modal Form */}

      <div
        className={`${
          otpstate ? 'animate__animated animate__fadeIn' : 'd-none'
        }`}
        style={{
          backgroundColor: 'rgba(0,0,0,0.4)',
          height: '100vh',
          position: 'fixed',
          width: '100%',
          zIndex: 10,
          top: 0,
          left: 0,
        }}
      >
        <div class="w-25 bg-white rounded py-3 px-3 mx-auto mt-4">
          <div class="pb-3 d-flex justify-content-between">
            <h5
              class="modal-title"
              id="exampleModalLabel"
            >
              Enter your OTP
            </h5>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setotpstate(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </div>
          <div class="mt-2">
            <input
              value={otp}
              onChange={e => {
                setotp(e.target.value);
              }}
              type="text"
              name=""
              className="form-control py-2  shadow-none border-secondary-subtle"
              id=""
            />
            <div className="text-end mt-4">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  verifyotp();
                }}
              >
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Discount form */}
      <div
        className={`${state ? 'animate__animated animate__fadeIn' : 'd-none'}`}
        style={{
          backgroundColor: 'rgba(0,0,0,0.6)',
          width: '100%',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <div className="row m-0">
          <div className="mx-auto mt-4 bg-white rounded col-md-8 p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="text-color fw-bold text-center m-0">
                Apply for the Discount
              </h5>
              <svg
                onClick={() => {
                  setstate(false);
                }}
                style={{ cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </div>
            <div className="row m-0 pt-2">
              <div className="col-md-6 mb-3">
                <label
                  htmlFor=""
                  className="fw-medium"
                >
                  Number of Installments
                </label>
                <input
                  type="number"
                  value={installments_count}
                  onChange={e => {
                    setinstallments_count(e.target.value);
                  }}
                  className="form-control py-3 shadow-none border-secondary-subtle mt-2"
                  name=""
                  id=""
                />
              </div>
              <div className="col-md-6 mb-3">
                ` `
                <label
                  htmlFor=""
                  className="fw-medium"
                >
                  Accepted Quotation
                </label>
                <input
                  type="number"
                  value={formData.grand_total}
                  onChange={e => {
                    const { name, value } = e.target;
                    setFormData(prev => ({
                      ...prev,
                      [name]: Number(value),
                    }));
                  }}
                  className="form-control py-3 shadow-none border-secondary-subtle mt-2"
                  name="grand_total"
                  id=""
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor=""
                  className="fw-medium"
                >
                  Enter Gst Total
                </label>
                <input
                  disabled="true"
                  type="number"
                  value={formData.grand_total * 0.18}
                  onChange={e => {
                    setgst_total(e.target.value);
                  }}
                  className="form-control py-3 shadow-none border-secondary-subtle mt-2"
                  name=""
                  id=""
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor=""
                  className="fw-medium"
                >
                  Enter Installment Days
                </label>
                <input
                  type="number"
                  value={installment_days}
                  onChange={e => {
                    setinstallment_days(e.target.value);
                  }}
                  className="form-control py-3 shadow-none border-secondary-subtle mt-2"
                  name=""
                  id=""
                />
              </div>

              {installments.map((amount, index) => (
                <div
                  key={index}
                  className="col-lg-4 mb-4"
                >
                  <div>
                    <label htmlFor="">Installment Amount {index + 1}</label>
                    <input
                      type="text"
                      value={amount}
                      className="form-control shadow-none mt-2 py-3"
                      name={`installment_amount_${index}`}
                      onChange={e =>
                        handleInstallmentChange(index, Number(e.target.value))
                      }
                      id=""
                    />
                  </div>
                </div>
              ))}

              <div className="text-end mt-3">
                <button
                  className="text-white px-4 py-2 btns"
                  onClick={() => {
                    installment();
                  }}
                >
                  {loading3 ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client_quotation_register_form;
