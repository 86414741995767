import React, { useState, useEffect } from 'react';
import ClientForms from '../ClientForms';
import { ipaddress, ipaddress2, role } from '../../App';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as XLSX from 'xlsx';
import Slider from 'react-slick';
import Slick_button_right from '../../Slick_button_right';
import Slick_button_left from '../../Slick_button_left';
import Modal from 'react-bootstrap/Modal';
import Small_preloader from '../../Small_preloader';
import Lead_details from '../Employee_leads/Lead_details';
import Excel_download from '../../Svgs/Excel_download';
import LeadStatus from '../LeadStatus/LeadStatus';
import RemarksSections from './RemarksSections';
import CheckBoxAssign from '../../Admin_panel/All-leads/AssignAnyone.jsx/CheckBoxAssign';
import InputAssign from '../../Admin_panel/All-leads/AssignAnyone.jsx/InputAssign';

const B2C_allleads = ({ count1, settab }) => {
  const [show, setshow] = useState(false);
  const location = useLocation();

  settab('all');

  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View Invoice
    </Tooltip>
  );

  const [tab, setTab] = useState('all');
  const navigate = useNavigate();

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Upload Excel
    </Tooltip>
  );
  const renderTooltip2 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Download Template
    </Tooltip>
  );

  const renderTooltip4 = value => (
    <Tooltip id="button-tooltip">{value}</Tooltip>
  );

  const [loading, setLoading] = useState(true);

  const [leads, setleads] = useState([]);
  const [referredleads, setreferredleads] = useState([]);
  const [allleads, setallleads] = useState([]);
  const [employees, setemployees] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [count, setCount] = useState(0);

  // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
  const fetchemployees = () => {
    axios
      .get(`${ipaddress}/AllprofileView/`)
      .then(r => {
        console.log('Employees names', r.data);
        const data = r.data.filter(x => x.user.blockstatus == false);
        setemployees(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // ------------------------------------------------Fetch leads-----------------------------------------------
  const [original_leads, setoriginal_leads] = useState({});

  const fetchleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerNewleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer new Leads', r.data);
        setoriginal_leads(r.data);
        setleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [leadapi, setleadapi] = useState('');

  useEffect(() => {
    if (leadapi !== '') {
      axios
        .get(`${leadapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_leads(r.data);
          setleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [leadapi]);

  // ------------------------------------------------Fetch referred leads-----------------------------------------------
  const [original_refferedleads, setoriginal_referredleads] = useState({});

  const fetchreferred_leads = () => {
    axios
      .get(`${ipaddress}/AllReferalCustomerLeads/`)
      .then(r => {
        console.log('Customer referred Leads', r.data);
        setoriginal_leads(r.data);
        setreferredleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [refferedapi, setrefferedapi] = useState('');

  useEffect(() => {
    if (refferedapi !== '') {
      axios
        .get(`${refferedapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_referredleads(r.data);
          setrefferedapi(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [refferedapi]);

  const [original_allleads, setoriginalallleads] = useState({});

  const fetchallleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/AllCustomerleads/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('All Customer Leads', r.data);
        setoriginalallleads(r.data);
        setallleads(r.data.results);
      })
      .catch(err => {
        console.log('all leads error', err);
      });
  };

  const downloadallleads = () => {
    // setload(true)
    axios
      .get(`${ipaddress}/AllCustomerleads/${user.emp_id}/?download=file`)
      .then(r => {
        const worksheet = XLSX.utils.json_to_sheet(r.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${user.firstname}'s leads.xlsx`);

        console.log('Excel data', r.data);
      })
      .catch(err => {
        console.log('all leads error', err);
      });
  };

  const [allleadapi, setallleadapi] = useState('');

  useEffect(() => {
    if (allleadapi !== '') {
      axios
        .get(`${allleadapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginalallleads(r.data);
          setallleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [allleadapi]);

  const [load2, setload2] = useState(false);

  useEffect(() => {
    setload(true);
    fetchemployees();
    fetchallleads();
  }, [count, count1]);

  const [lead_id, setlead_id] = useState('');
  const [client_lead_id, setclient_lead_id] = useState('');
  const [stage, setstage] = useState('');

  // --------------------------------------Fetch Walkin Leads----------------------------------------------------
  const [walkinleads, setwalkinleads] = useState([]);
  const [scanned_walkinleads, setscanned_walkinleads] = useState([]);

  const fetchwalkinleads = () => {
    axios
      .get(`${ipaddress}/ManagerWalkinleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer walkin Leads', r.data);

        setwalkinleads(r.data.walkin_leads);
        setscanned_walkinleads(r.data.scanned_walkin_leads);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Followup Leads----------------------------------------------------
  const [followupleads, setfollowupleads] = useState([]);
  const [original_followupleads, setoriginal_followupleads] = useState({});

  const fetchfollowupleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [followupapi, setfollowupapi] = useState('');

  useEffect(() => {
    if (followupapi !== '') {
      axios
        .get(`${followupapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [followupapi]);

  // Fetch today followup leads
  const fetch_today_followupleads = () => {
    axios
      .post(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer today followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending followup leads
  const fetch_pending_followupleads = () => {
    axios
      .put(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.overdue_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Registered Leads----------------------------------------------------
  const [registeredleads, setregisteredleads] = useState([]);
  const [original_registeredleads, setoriginal_registeredleads] = useState({});
  const fetchregisteredleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerRegisteredleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer registered Leads', r.data);
        setoriginal_registeredleads(r.data);
        setregisteredleads(r.data);
      })
      .catch(err => {
        console.log('registered leads', err);
      });
  };

  const [registeredapi, setregisteredapi] = useState('');

  useEffect(() => {
    if (registeredapi !== '') {
      axios
        .get(`${registeredapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_registeredleads(r.data);
          setregisteredleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [registeredapi]);

  // Fetch pending registered leads
  const fetch_pending_registeredleads = () => {
    axios
      .get(`${ipaddress}/TodayRegistrationCandidates/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending registered Leads', r.data);
        setoriginal_registeredleads(r.data);
        setregisteredleads(r.data.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Prospects Leads----------------------------------------------------
  const [prospectsleads, setprospectsleads] = useState([]);
  const [original_prospectleads, setoriginal_prospectleads] = useState({});

  const fetchprospectsleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerProspectleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer prospects Leads', r.data);
        setoriginal_prospectleads(r.data);
        setprospectsleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [prospectapi, setprospectapi] = useState('');

  useEffect(() => {
    if (prospectapi !== '') {
      axios
        .get(`${prospectapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_prospectleads(r.data);
          setprospectsleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [prospectapi]);

  // Fetch pending prospect leads
  const fetch_pending_prospectleads = () => {
    axios
      .post(`${ipaddress}/ManagerProspectleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending prospect Leads', r.data);
        setoriginal_prospectleads(r.data);
        setprospectsleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Closed Leads----------------------------------------------------
  const [closedleads, setclosedleads] = useState([]);
  const [original_closed, setoriginal_closed] = useState({});

  const fetchclosedleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerClosedleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer closed Leads', r.data);
        setoriginal_closed(r.data);
        setclosedleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [closedapi, setclosedapi] = useState('');

  useEffect(() => {
    if (closedapi !== '') {
      axios
        .get(`${closedapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_closed(r.data);
          setclosedleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [closedapi]);

  // -------------------------------------Select the Leads----------------------------------------
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selecetdemp, setselectedemp] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
      setSelectAll(false);
    }
  };

  const handleSelectAll = stage => {
    let allleads_ = [];
    console.log('ALLLEADS', allleads);

    if (!selectAll) {
      if (stage === 'newlead') {
        allleads_ = allleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'walkin') {
        allleads_ = walkinleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'followup') {
        allleads_ = followupleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'prospect') {
        allleads_ = prospectsleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'registered') {
        allleads_ = registeredleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'closed') {
        allleads_ = closedleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'other') {
        allleads_ = otherdepartment.map(lead => lead.customer_lead_id);
      }
      setSelectedLeads(allleads_);
    } else {
      setSelectedLeads([]);
    }
    setSelectAll(!selectAll);
  };

  const assign_leads = value => {
    const emp_name = employees.find(x => x.user.emp_id === value);
    axios
      .post(`${ipaddress}/AssignToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        customer_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
        toast.success(`Lead assigned to ${emp_name.user.first_name}`, {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setSelectAll(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.log('Assigning error', err);
      });
  };

  // -------------------------------------------------Search leads from all leads table-----------------------------------

  const [searchvalue, setsearchvalue] = useState('');
  const [category_name, setcategory_name] = useState('select');
  const [category_name2, setcategory_name2] = useState('select');
  const [category_name3, setcategory_name3] = useState('select');
  const [category_name4, setcategory_name4] = useState('select');
  const [category_name5, setcategory_name5] = useState('select');
  const [category_name6, setcategory_name6] = useState('select');
  const [category_name7, setcategory_name7] = useState('select');
  const [category_name8, setcategory_name8] = useState('select');
  const [category_name9, setcategory_name9] = useState('select');
  const [category_name10, setcategory_name10] = useState('select');

  const [category_state, setcategory_state] = useState(false);

  // Search leads

  // /MUIZAPISOURCE
  const search_all_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchCategoryCustomerView/${user.emp_id}/${category_name}/${value}/`,
        )
        .then(r => {
          console.log('Customer search Leads', r.data);
          setallleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchallleads();
    }
  };

  const search_new_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchleads();
    }
  };

  const search_referred_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name3}/${value}/?level=referred`,
        )
        .then(r => {
          console.log('Searched referred Leads', r.data);
          setreferredleads(r.data);
        })
        .catch(err => {
          console.log(err);
          // console.log(``)
        });
    } else {
      fetchreferred_leads();
    }
  };

  const search_walkin1_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name4}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setwalkinleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_walkin2_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name9}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setscanned_walkinleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_followup_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name5}/${value}/?level=followup`,
        )
        .then(r => {
          console.log('Searched followup Leads', r.data);
          // setoriginal_followupleads(r.data)
          setfollowupleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchfollowupleads();
    }
  };

  const search_prospect_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name6}/${value}/?level=prospect`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setprospectsleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchprospectsleads();
    }
  };

  const search_registered_leads = value => {
    if (value.length > 0) {
      console.log(value);
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name7}/${value}/?level=registered`,
        )
        .then(r => {
          console.log('Searched registered Leads', r.data);
          setregisteredleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchregisteredleads();
    }
  };

  const search_closed_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name8}/${value}/?level=closed`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setclosedleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchclosedleads();
    }
  };

  const search_other_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name10}/${value}/?level=Other Department Lead`,
        )
        .then(r => {
          console.log('Searched other Leads', r.data);
          setotherdepartment(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetch_otherDepartmentleads();
    }
  };

  const search_closed_leads2 = () => {
    axios
      .get(
        `${ipaddress}/SearchStageWiseCustomerView/only_job/true/?level=closed`,
      )
      .then(r => {
        console.log('Searched new Leads', r.data);
        setclosedleads(r.data);
      })
      .catch(err => {
        console.log(err);
        // console.log(`${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`)
        // console.log(``)
      });
  };

  // ---------------------------------------------------Delete New laeds-----------------------------------------------------
  const delete_lead = id => {
    axios.put(`${ipaddress}/DeleteView/${id}/`).then(r => {
      console.log('Deleted successfully', r.data);
      setCount(count + 1);
      toast.success('B2C lead deleted successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    });
  };

  // To view the assigned permissions

  const [permissions, setpermissions] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/PermissionsView/${user.emp_id}/`).then(r => {
      console.log('Permissions', r.data);
      setpermissions(r.data);
    });
  }, []);

  const [walkin_status, setwalkin_status] = useState(true);

  const [course_name, setcourse_name] = useState('');
  const [course_id, setcourse_id] = useState();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  // Combination of search

  const fields = [
    'Name',
    'Email',
    'Contact_Number',
    'Course_Enquired',
    'Source',
    'Date',
    'Lead_Stage',
  ];

  const [fields_array, setfields_array] = useState({
    Course_Enquired: false,
    Source: false,
    Lead_Stage: false,
    Employee: false,
    Email: false,
    Name: false,
    Date: false,
    Contact_Number: false,
  });

  const handleFieldChange = field => {
    setfields_array(prev => ({
      ...prev,
      [field]: !prev[field], // Toggle the state of the specific field dynamically
    }));
  };

  const [formdata, setformdata] = useState({
    course_enquired: '',
    source: '',
    level_lead: '',
    email: '',
    lead_name: '',
    Date: '',
    contact_number: '',
  });

  const combine_fields = e => {
    const { name, value } = e.target;
    setformdata(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const [load, setload] = useState(false);

  const cancel_combined_search = () => {
    setshow(false);
    setformdata({
      course_enquired: '',
      source: '',
      level_lead: '',
      emp_id: '',
      email: '',
      lead_name: '',
      Date: '',
      contact_number: '',
    });
    setfields_array({
      Course_Enquired: false,
      Source: false,
      Lead_Stage: false,
      Employee: false,
      Email: false,
      Name: false,
      Date: false,
      Contact_Number: false,
    });
  };

  const combined_search = () => {
    formdata.emp_id = Number(user.id);

    axios
      .post(`${ipaddress}/CombinationSearchCustomerView/`, formdata)
      .then(r => {
        console.log('Combined filter data', r.data);
        setallleads(r.data);
        cancel_combined_search();
      })
      .catch(err => {
        console.log('Combined search error', err);
      });
  };

  // Move to Other department

  const [otherdepartment, setotherdepartment] = useState([]);
  const [original_others, setoriginal_others] = useState({});

  const fetch_otherDepartmentleads = () => {
    setload(true);
    axios
      .get(
        `${ipaddress}/MoveToOtherDepartmentLead/?employee_id=${user.emp_id}&type=customer`,
      )
      .then(r => {
        setload(false);
        console.log('Other department leads', r.data);
        setoriginal_others(r.data);
        setotherdepartment(r.data.results);
      });
  };

  const [otherapi, setotherapi] = useState('');

  useEffect(() => {
    if (otherapi !== '') {
      axios
        .get(`${otherapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_others(r.data);
          setotherdepartment(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [otherapi]);

  const movetotherDepartmentleads = id => {
    axios
      .post(`${ipaddress}/MoveToOtherDepartmentLead/`, {
        customer_lead_id: id,
        employee_id: user.emp_id,
      })
      .then(r => {
        console.log('Other department leads', r.data);
        setCount(count + 1);
        toast.success('Moved to Other Department Leads', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };

  const downloadExcel = value => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${user.firstname}_leads.xlsx`);
  };

  const [lead_detail_modal, setlead_detail_modal] = useState(false);

  const [sortOrder, setSortOrder] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const priorityValue = priority => {
    const mapping = {
      high: 3,
      medium: 2,
      low: 1,
    };
    return mapping[priority.toLowerCase()] || 0;
  };

  const sortLeads = (field, direction) => {
    setSortConfig({ key: field, direction: direction });
    let sortedLeads = [...allleads];

    if (field === 'created_at') {
      sortedLeads.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        if (direction === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    } else if (field === 'priority') {
      sortedLeads.sort((a, b) => {
        const priorityA = priorityValue(a.priority);
        const priorityB = priorityValue(b.priority);

        if (direction === 'asc') {
          return priorityA - priorityB;
        } else {
          return priorityB - priorityA;
        }
      });
    }

    setallleads(sortedLeads);
  };

  const [availablecourses, setAvailablecourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchCourses = () =>
    axios
      .get(`${ipaddress}/AllcoursesnameView/`)
      .then(r => {
        console.log('Courses successfully fetched XYZ', r.data);
        setAvailablecourses(r.data.coursename);
      })
      .catch(err => {
        console.log('Courses fetching Error', err);
      });

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleSearchChange = value => {
    setSearchTerm(value);

    if (category_name === 'course_enquired') {
      const matches = availablecourses.filter(course =>
        course.course_name.toLowerCase().startsWith(value.toLowerCase()),
      );
      setFilteredCourses(matches);
    } else {
      // Reset the filteredCourses if other options are selected
      setFilteredCourses([]);
    }
  };

  return (
    <div>
      <div className="">
        <div className="px-1 px-md-0">
          <div id="myTabContent">
            {/* --------------------------------------------------All Leads--------------------------------------------------- */}
            <div>
              {load ? (
                <Small_preloader />
              ) : (
                <div class={` mt-3 bg-white py-3 py-md-4 `}>
                  <div
                    className={`row m-0 tw-my-3 tw-py-3 ${
                      selectedLeads.length > 0 ? '' : 'd-none'
                    }`}
                  >
                    <div className="col-lg-3 col-md-6 pe-md-0 ms-auto text-end">
                      <select
                        value={selecetdemp}
                        disabled={selectedLeads.length > 0 ? false : true}
                        name=""
                        id=""
                        onChange={e => {
                          assign_leads(e.target.value);
                        }}
                        className={`form-select shadow-none`}
                        style={{ cursor: 'pointer' }}
                      >
                        <option value="">Assign to</option>
                        {employees.map(x => {
                          return (
                            <>
                              <option value={x.user.emp_id}>
                                {x.user.first_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-3 px-2">
                    <h6 className="text-primary m-0 ">All B2C Leads</h6>
                    <div className="d-flex align-items-center w-50 justify-content-end">
                      <select
                        onChange={e => {
                          setcategory_name(e.target.value);
                          setcategory_state(!category_state);

                          if (e.target.value === 'custom') {
                            setshow(true);
                            setcategory_name(e.target.value);
                          } else {
                            fetchallleads();
                          }
                        }}
                        value={category_name}
                        name=""
                        id=""
                        className="ms-3 form-select shadow-none"
                        style={{ width: '30%', cursor: 'pointer' }}
                      >
                        <option value="select">None</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="course_enquired">Course</option>
                        <option value="source">Source</option>
                        <option value="level_lead">Lead Stage</option>
                        <option value="contact_number">Phone Number</option>
                        <option value="priority">Priority</option>
                        <option value="created_at">Date</option>
                        <option value="followup_date">Followup Date</option>
                        <option value="custom">Custom search</option>
                      </select>
                      <div
                        class={`input-group form-inputs border ms-3 ${
                          category_name === 'created_at' ||
                          category_name === 'priority' ||
                          category_name === 'custom' ||
                          category_name === 'followup_date' ||
                          category_name === 'contact_number' ||
                          category_name === 'level_lead' ||
                          category_name === 'select'
                            ? 'd-none'
                            : ''
                        }`}
                        style={{ width: '50%' }}
                      >
                        <span
                          class="input-group-text bg-transparent border-0"
                          id="basic-addon1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 19 18"
                            fill="none"
                          >
                            <path
                              d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z"
                              fill="#8E9696"
                            />
                          </svg>
                        </span>
                        {/* NEMO */}
                        {category_name !== 'course_enquired' && (
                          <input
                            type="text"
                            onChange={e => {
                              search_all_leads(e.target.value);
                            }}
                            class="form-control bg-transparent border-0 ps-0 shadow-none"
                            placeholder="Search here......"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        )}

                        {category_name === 'course_enquired' && (
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={e => handleSearchChange(e.target.value)}
                            className="form-control bg-transparent border-0 ps-0 shadow-none"
                            placeholder="Search here......"
                          />
                        )}

                        {/* Autocomplete dropdown for course names */}
                        {category_name === 'course_enquired' && searchTerm && (
                          <ul
                            className="dropdown-menu show"
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                            }}
                          >
                            {filteredCourses.length > 0 ? (
                              filteredCourses.map((course, index) => (
                                <button
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => {
                                    console.log('NEMO', course.course_name);
                                    setSearchTerm(course.course_name); // Select the course
                                    search_all_leads(searchTerm);
                                    setFilteredCourses([]); // Hide the dropdown
                                  }}
                                >
                                  {course.course_name}
                                </button>
                              ))
                            ) : (
                              <li className="dropdown-item text-muted">
                                No matches found
                              </li>
                            )}
                          </ul>
                        )}
                      </div>

                      {/* Filter based on Date */}
                      <input
                        type="date"
                        onChange={e => {
                          search_all_leads(e.target.value);
                        }}
                        className={`form-control w-50 ms-3 ${
                          (category_name === 'created_at' ||
                            category_name === 'followup_date') &&
                          category_name !== 'select'
                            ? ''
                            : 'd-none'
                        }`}
                      />

                      {/* Filter based on Mobile Number */}
                      <input
                        type="tel"
                        maxLength={10}
                        onChange={e => {
                          search_all_leads(e.target.value);
                        }}
                        className={`form-control w-50 ms-3 shadow-none ${
                          category_name === 'contact_number' &&
                          category_name !== 'select'
                            ? ''
                            : 'd-none'
                        }`}
                        placeholder="Search here..."
                      />

                      {/* Filter based on Priority */}
                      <select
                        onChange={e => {
                          search_all_leads(e.target.value);
                        }}
                        name=""
                        id=""
                        className={`ms-3 form-select shadow-none ${
                          category_name === 'priority' &&
                          category_name !== 'select'
                            ? ''
                            : 'd-none'
                        }`}
                        style={{ width: '50%', cursor: 'pointer' }}
                      >
                        <option value="">Select Priority...</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </select>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={renderTooltip4('Download Leads')}
                      >
                        <button
                          className="btn btn-sm px-2 ms-3 py-1 border-0"
                          onClick={() => {
                            downloadExcel(allleads);
                            // downloadallleads()
                          }}
                        >
                          <Excel_download />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <h6
                    className={`text-color text-center ${
                      allleads.length > 0 ? 'd-none' : ''
                    }`}
                  >
                    No Leads Available...🧐
                  </h6>
                  <div
                    className={`table-responsive bg-white border-0 ${
                      allleads.length > 0 ? '' : 'd-none'
                    }`}
                    style={{ minHeight: '100vh' }}
                  >
                    <table class={`w-100 caption-top rounded`}>
                      <thead className="invoice-table">
                        <tr
                          className=""
                          style={{ backgroundColor: '#034592' }}
                        >
                          <th
                            scope="col"
                            className={`${
                              permissions.assign_leads ||
                              user.designation === 'admin'
                                ? 'border-0 px-3'
                                : 'd-none'
                            }`}
                          >
                            <input
                              type="checkbox"
                              checked={selectAll ? true : false}
                              style={{ cursor: 'pointer' }}
                              onChange={() => {
                                handleSelectAll('newlead');
                              }}
                              name=""
                              id=""
                            />
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="text-white  m-0 py-2 ps-2">Name</p>
                          </th>
                          {/* <th scope="col" className='border-0'><p className='  m-0 py-2'>Created at</p></th> */}
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <div className="d-flex align-items-center">
                              <p className="  m-0 py-2 text-white">
                                Created at
                              </p>
                              <div className="ms-2 d-flex flex-column">
                                <button
                                  onClick={() =>
                                    sortLeads('created_at', 'desc')
                                  }
                                  className={`btn btn-sm border-0 p-0 m-0 ${
                                    sortConfig.key === 'created_at' &&
                                    sortConfig.direction === 'desc'
                                      ? 'text-warning'
                                      : 'text-white'
                                  }`}
                                  title="Sort Ascending"
                                  style={{ lineHeight: '1' }}
                                >
                                  ▲
                                </button>
                                <button
                                  onClick={() => sortLeads('created_at', 'asc')}
                                  className={`btn btn-sm border-0 p-0 m-0 ${
                                    sortConfig.key === 'created_at' &&
                                    sortConfig.direction === 'asc'
                                      ? 'text-warning'
                                      : 'text-white'
                                  }`}
                                  title="Sort Descending"
                                  style={{ lineHeight: '1' }}
                                >
                                  ▼
                                </button>
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">Whatsapp No</p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">Mobile No</p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">Email</p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">
                              Course Enquired
                            </p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">Lead Stage</p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <p className="  text-white m-0 py-2">Source</p>
                          </th>
                          <th
                            scope="col"
                            className="border-0 py-3"
                          >
                            <div className="d-flex align-items-center">
                              <p className="  text-white m-0 py-2">Priority</p>
                              <div className="ms-2 d-flex flex-column">
                                <button
                                  onClick={() => sortLeads('priority', 'desc')}
                                  className={`btn btn-sm border-0 p-0 m-0 ${
                                    sortConfig.key === 'priority' &&
                                    sortConfig.direction === 'desc'
                                      ? 'text-warning'
                                      : 'text-white'
                                  }`}
                                  title="Sort Ascending"
                                  style={{ lineHeight: '1' }}
                                >
                                  ▲
                                </button>
                                <button
                                  onClick={() => sortLeads('priority', 'asc')}
                                  className={`btn btn-sm border-0 p-0 m-0 ${
                                    sortConfig.key === 'priority' &&
                                    sortConfig.direction === 'asc'
                                      ? 'text-warning'
                                      : 'text-white'
                                  }`}
                                  title="Sort Descending"
                                  style={{ lineHeight: '1' }}
                                >
                                  ▼
                                </button>
                              </div>
                            </div>
                          </th>
                          <th className="border-0 py-3">Remarks</th>
                          <th className="border-0 py-3"></th>
                          <th className="border-0 py-3"></th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {console.log('ALLLEADS', allleads)}
                        {allleads.map((x, index) => {
                          return (
                            <tr>
                              <CheckBoxAssign
                                handleCheckboxChange={handleCheckboxChange}
                                leadID={x.customer_lead_id}
                                selectedLeads={selectedLeads}
                              />
                              <td className="text-primary fw-medium table-row py-3">
                                <p
                                  className=" m-0 tw-relative py-2 ps-2"
                                  style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setlead_id(x.customer_lead_id);
                                    setlead_detail_modal(true);
                                  }}
                                >
                                  {x.name}
                                  {x.priority && (
                                    <span className="tw-absolute px-2  tw-rounded-full tw-text-red-600  -tw-bottom-2 tw-right-8 ">
                                      {x.priority}
                                    </span>
                                  )}
                                  {console.log('FOLLOW', x)}
                                  {x.followup_notification &&
                                    x.level_lead_name === 'followup' && (
                                      <span
                                        className={`tw-w-full font-medium px-1 tw-capitalize rounded-md ${
                                          x.followup_notification.includes(
                                            'today',
                                          )
                                            ? 'tw-text-yellow-300'
                                            : x.followup_notification.includes(
                                                'pending',
                                              )
                                            ? 'tw-text-red-500'
                                            : x.followup_notification.includes(
                                                'upcoming',
                                              )
                                            ? 'tw-text-green-500'
                                            : 'tw-text-black'
                                        }`}
                                        style={{
                                          fontSize: '0.7em',
                                          position: 'absolute',
                                          top: '-0.1em',
                                          right: '-2.2em',
                                          padding: '2px 4px',
                                          transform: 'translateY(-50%)',
                                        }}
                                      >
                                        {x.followup_notification}
                                      </span>
                                    )}
                                </p>
                              </td>
                              <td className="table-row py-3">
                                <p
                                  className=" m-0 py-2"
                                  style={{ width: '200px' }}
                                >
                                  {x.created_at
                                    ? `${x.created_at.slice(
                                        0,
                                        10,
                                      )} (${x.created_at.slice(11, 19)})`
                                    : ''}
                                </p>
                              </td>
                              <td className="table-row py-3">
                                <p className=" m-0 py-2">{x.whatsapp}</p>
                              </td>
                              <td className="table-row py-3">
                                <p className=" m-0 py-2">{x.contact_number}</p>
                              </td>
                              <td className="table-row py-3">
                                <p
                                  className=" m-0 py-2"
                                  style={{ width: '280px' }}
                                >
                                  {x.email}
                                </p>
                              </td>
                              <td className="table-row py-3">
                                <p
                                  className={
                                    x.othercourseenquired === null
                                      ? ' m-0 py-2'
                                      : 'd-none'
                                  }
                                >
                                  {x.course_enquiredd}
                                </p>
                                <p
                                  className={
                                    x.othercourseenquired === null
                                      ? 'd-none'
                                      : ' m-0 py-2'
                                  }
                                >
                                  {x.othercourseenquired}{' '}
                                  <span
                                    style={{
                                      fontStyle: 'italic',
                                      color: 'orangered',
                                      fontSize: '13px',
                                    }}
                                  >
                                    (Other )
                                  </span>
                                </p>
                              </td>
                              <td
                                className={`${
                                  x.level_lead_name === 'registered' ||
                                  x.admin_approval
                                    ? 'd-none'
                                    : 'table-row'
                                }`}
                              >
                                {/* /MUIZWORK STATUS */}

                                <LeadStatus
                                  movetotherDepartmentleads={
                                    movetotherDepartmentleads
                                  }
                                  setShow2={setShow2}
                                  setShow1={setShow1}
                                  setcourse_id={setcourse_id}
                                  setcourse_name={setcourse_name}
                                  setlead_id={setlead_id}
                                  setstage={setstage}
                                  x={x}
                                />
                              </td>
                              <td
                                className={`${
                                  x.invoice_status &&
                                  x.level_lead_name === 'registered'
                                    ? 'table-row py-3'
                                    : 'd-none'
                                }`}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      backgroundColor: '#002147',
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/installment_form/${x.customer_lead_id}`,
                                      );
                                    }}
                                    className={`btn btn-sm text-white rounded-0 ${
                                      x.invoice_status &&
                                      x.level_lead_name === 'registered'
                                        ? ''
                                        : 'd-none'
                                    }`}
                                  >
                                    Invoice Generated
                                  </span>
                                </div>
                              </td>
                              <td className="table-row tw-px-4 py-3">
                                <p className=" m-0 py-2">
                                  {/* OTHER SOURCE */}
                                  {/* {console.log('MUIZ', x)} */}
                                  {x.source}
                                </p>
                              </td>
                              <td className="table-row py-3">
                                <p className=" m-0  py-2">
                                  {x.priority ? x.priority : '-'}
                                </p>
                              </td>

                              <td
                                className={`table-row py-3 ${
                                  !x.invoice_status &&
                                  x.level_lead_name === 'registered'
                                    ? ''
                                    : 'd-none'
                                }`}
                              >
                                <div className="py-2">
                                  <p className=" text-success m-0">
                                    Performa Invoice Generated
                                  </p>
                                </div>
                              </td>

                              {/* /hover */}
                              <RemarksSections x={x} />

                              <td className={`table-row py-3`}>
                                <div className="py-2">
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 250 }}
                                    overlay={renderTooltip4('Edit')}
                                  >
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/edit_customer_lead/${x.customer_lead_id}`,
                                          {
                                            state: {
                                              from: `${location.pathname}`,
                                            },
                                          },
                                        );
                                      }}
                                      className="btn btn-sm"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#624DE3"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                        />
                                      </svg>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </td>

                              <td
                                className={`table-row py-3 ${
                                  x.level_lead_name === 'registered' &&
                                  !x.invoice_status &&
                                  x.admin_approval
                                    ? ''
                                    : 'd-none'
                                }`}
                              >
                                <div className="py-2">
                                  <p className=" text-warning m-0">
                                    Admin has to approve
                                  </p>
                                </div>
                              </td>

                              <td
                                className={`table-row py-3 ${
                                  x.level_lead_name === 'newlead' &&
                                  x.admin_approval &&
                                  x.invoice_status == false
                                    ? ''
                                    : 'd-none'
                                }`}
                              >
                                <div className="py-2">
                                  <p className=" text-warning m-0">
                                    Admin has to approve
                                  </p>
                                </div>
                              </td>

                              <td
                                className={`table-row py-3 ${
                                  x.invoice_status &&
                                  x.level_lead_name === 'registered'
                                    ? ''
                                    : 'd-none'
                                }`}
                              >
                                <div className=" py-2">
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 250 }}
                                    overlay={renderTooltip3}
                                  >
                                    <div className="">
                                      <svg
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          navigate(
                                            `/invoice/${x.customer_lead_id}`,
                                          );
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-receipt-cutoff"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                                        <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z" />
                                      </svg>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        className={`btn btn-sm px-3 btn-success`}
                        disabled={
                          original_allleads.previous !== null ? false : true
                        }
                        onClick={() => {
                          setallleadapi(
                            original_allleads && original_allleads.previous,
                          );
                        }}
                      >
                        Previous
                      </button>
                      <button
                        className={`btn btn-sm px-3 btn-success`}
                        disabled={
                          original_allleads.next !== null ? false : true
                        }
                        onClick={() => {
                          setallleadapi(
                            original_allleads && original_allleads.next,
                          );
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* --------------------------MODAL FORMS-------------------------- */}
        <ClientForms
          show1={show1}
          setshow1={setShow1}
          show2={show2}
          setshow2={setShow2}
          lead_id={lead_id}
          client_lead_id={client_lead_id}
          setCount={setCount}
          stage1={stage}
          setstage={setstage}
          course_name={course_name}
          course_id={course_id}
        />

        {/* Combination of search */}
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title"
          centered
        >
          <Modal.Header className="d-flex align-items-center justify-content-between">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="fs-5"
            >
              Combination of Search
            </Modal.Title>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                cancel_combined_search();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2 bg-light">
              <h6 className="fs-5 mb-4  text-primary">Select Categories</h6>
              <div className="d-flex align-items-center justify-content-between px-3 row m-0">
                {fields.map(x => {
                  return (
                    <div className="col-md-6 col-lg-4">
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleFieldChange(x);
                        }}
                        className="d-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class={`bi bi-check-square ${
                            fields_array[x] ? 'd-none' : ''
                          }`}
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0D6EFD"
                          class={`bi bi-check-square-fill ${
                            fields_array[x] ? '' : 'd-none'
                          }`}
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                        <span className="ms-2">{x}</span>
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className={`m-0 border-top pt-4 mb-3 mt-2`}>
                <div
                  className={`row m-0 align-items-center mb-4 ${
                    fields_array.Lead_Stage ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on Lead Stage */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Lead Stage
                    </label>
                  </div>
                  <div className="col-md-6">
                    <select
                      value={formdata.level_lead}
                      onChange={combine_fields}
                      name="level_lead"
                      id=""
                      className={`bg-transparent border-0 border-bottom rounded-0 form-select shadow-none py-2 mt-2`}
                      style={{ border: '2px solid white' }}
                    >
                      <option value="">All Leads</option>
                      <option value="newlead">New Lead</option>
                      <option value="walkin">Walkin</option>
                      <option value="followup">Followup</option>
                      <option value="prospect">Prospect</option>
                      <option value="registered">Registered</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                </div>

                <div
                  className={`row align-items-center m-0 mb-4 ${
                    fields_array.Course_Enquired ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on course */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Course Enquired
                    </label>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      value={formdata.course_enquired}
                      name="course_enquired"
                      onChange={combine_fields}
                      className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                      style={{ border: '2px solid white' }}
                    />
                  </div>
                </div>

                <div
                  className={`row align-items-center m-0 mb-4 ${
                    fields_array.Source ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on source */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Source
                    </label>
                  </div>
                  <div className="col-md-6">
                    <select
                      value={formdata.source}
                      onChange={combine_fields}
                      name="source"
                      id=""
                      className={`form-select bg-transparent border-0 border-bottom rounded-0 shadow-none py-2 mt-2`}
                      style={{ border: '2px solid white' }}
                    >
                      <option
                        value="none"
                        selected
                      >
                        Choose...
                      </option>
                      <option value="other">Other</option>
                      <option value="walkin">Walkin</option>
                      <option value="naukri">Naukri</option>
                      <option value="linkedin">Linkedin</option>
                      <option value="just dial">Just Dial</option>
                      <option value="whatsapp">Whatsapp</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                    </select>
                  </div>
                </div>

                <div
                  className={`row m-0 align-items-center mb-4 ${
                    fields_array.Name ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on course */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Name
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      value={formdata.lead_name}
                      name="lead_name"
                      onChange={combine_fields}
                      className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                      style={{ border: '2px solid white' }}
                    />
                  </div>
                </div>

                <div
                  className={`row m-0 align-items-center mb-4 ${
                    fields_array.Email ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on course */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Email
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      value={formdata.email}
                      name="email"
                      onChange={combine_fields}
                      className={`form-control shadow-none py-2 bg-transparent mt-2 rounded-0 border-0 border-bottom`}
                      style={{ border: '2px solid white' }}
                    />
                  </div>
                </div>

                <div
                  className={`row m-0 align-items-center mb-4 ${
                    fields_array.Contact_Number ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on course */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Contact Number
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="tel"
                      maxLength={10}
                      value={formdata.contact_number}
                      name="contact_number"
                      onChange={combine_fields}
                      className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                      style={{ border: '2px solid white' }}
                    />
                  </div>
                </div>

                <div
                  className={`row m-0 align-items-center mb-4 ${
                    fields_array.Date ? '' : 'd-none'
                  }`}
                >
                  {/* Filter based on course */}
                  <div className="col-md-6">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Date
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="date"
                      value={formdata.Date}
                      name="Date"
                      onChange={combine_fields}
                      className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                      style={{ border: '2px solid white' }}
                    />
                  </div>
                </div>

                <div className="text-end mt-2">
                  <button
                    className="btn btn-sm btn-primary px-4 py-2"
                    onClick={combined_search}
                  >
                    <span
                      class={`spinner-border spinner-border-sm ${
                        load ? '' : 'd-none'
                      }`}
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className={load ? 'd-none' : ''}>Search</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Lead_details
          show={lead_detail_modal}
          setshow={setlead_detail_modal}
          id={lead_id}
        />
      </div>
    </div>
  );
};

export default B2C_allleads;
