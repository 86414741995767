import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ipaddress, ipaddress2, role } from '../../App';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Preloader from '../../Components/Preloader';
import ClientForms from '../../Components/ClientForms';
import * as XLSX from 'xlsx';
import Small_preloader from '../../Small_preloader';
import Lead_details from '../../Components/Employee_leads/Lead_details';
import Excel_download from '../../Svgs/Excel_download';
import RemarksSections from '../../Components/Customerleads/RemarksSections';

const Client_registered_leads = ({ settab }) => {
  settab('registered');
  const [tab, setTab] = useState('newleads');
  const navigate = useNavigate();

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Upload Excel
    </Tooltip>
  );

  const renderTooltip2 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Download Template
    </Tooltip>
  );

  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View Invoice
    </Tooltip>
  );

  const [loading, setLoading] = useState(true);

  const [client_lead_id, setclient_lead_id] = useState('');
  const [lead_id, setlead_id] = useState('');
  const [stage, setstage] = useState('');

  const [leads, setleads] = useState([]);
  const [followupleads, setfollowupleads] = useState([]);
  const [prospectleads, setprospectleads] = useState([]);
  const [registeredleads, setregisteredleads] = useState([]);
  const [closedleads, setclosedleads] = useState([]);
  const [allleads, setallleads] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [count, setCount] = useState(0);

  const [employees, setemployees] = useState([]);

  // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
  const fetchemployees = () => {
    axios
      .get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
      .then(r => {
        console.log('Employees data', r.data);
        setemployees(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [original_newleads, setoriginal_newleads] = useState({});

  const fetchleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=newlead`)
      .then(r => {
        console.log('Client new Leads', r.data);
        setoriginal_newleads(r.data);
        setleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [newleadapi, setnewleadapi] = useState('');

  useEffect(() => {
    if (newleadapi !== '') {
      axios
        .get(`${newleadapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_newleads(r.data);
          setleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [newleadapi]);

  // --------------------------------------Fetch Walkin Leads----------------------------------------------------
  const [walkinleads, setwalkinleads] = useState([]);
  const [original_walkindata, setoriginal_walkindata] = useState({});

  const fetchwalkinleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=walkin`)
      .then(r => {
        console.log('Client walkin Leads', r.data);
        setoriginal_walkindata(r.data);
        setwalkinleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [walkinapi, setwalkinapi] = useState('');

  useEffect(() => {
    if (walkinapi !== '') {
      axios
        .get(`${walkinapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_walkindata(r.data);
          setwalkinleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [walkinapi]);

  // Fetch Followup Leads
  const [original_followupleads, setoriginal_followupleads] = useState({});

  const fetchfollowupleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=followup`)
      .then(r => {
        console.log('Client Followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [followupapi, setfollowupapi] = useState('');

  useEffect(() => {
    if (followupapi !== '') {
      axios
        .get(`${followupapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [followupapi]);

  // Fetch today followup leads
  const fetch_today_followupleads = () => {
    axios
      .post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`, {
        value: 'today',
      })
      .then(r => {
        console.log('Client today followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending followup leads
  const fetch_pending_followupleads = () => {
    axios
      .post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`, {
        value: 'pending',
      })
      .then(r => {
        console.log('Client pending followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.overdue_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Prospects Leads
  const [original_prospectdata, setoriginal_prospectdata] = useState({});

  const fetchprospectsleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=prospect`)
      .then(r => {
        console.log('Client Prospect Leads', r.data);
        setoriginal_prospectdata(r.data);
        setprospectleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [prospectapi, setprospectapi] = useState('');

  useEffect(() => {
    if (prospectapi !== '') {
      axios
        .get(`${prospectapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [prospectapi]);

  // Fetch pending prospect leads
  const fetch_pending_prospectleads = () => {
    axios
      .post(`${ipaddress}/ClientProspectleadsView/${user.emp_id}/`)
      .then(r => {
        console.log('Client pending prospect Leads', r.data);
        setoriginal_prospectdata(r.data);
        setprospectleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending prospect leads
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');

  const fetch_rangewise_leads = (start_date, end_date, stage) => {
    setLoading(true);
    axios
      .get(
        `${ipaddress}/FilterClientLeadsView/?start_date=${start_date}&end_date=${end_date}&level_name=${stage}`,
      )
      .then(r => {
        setLoading(false);
        console.log('Client range Leads', r.data);
        if (stage === 'prospect') {
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
          setlead_count(r.data.count);
        }
        if (stage === 'registered') {
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results);
        }
        if (stage === 'followup') {
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetch_today_prospectleads = stage => {
    const date = new Date();
    const todaydate = date.toISOString().slice(0, 10);
    axios
      .get(
        `${ipaddress}/FilterClientLeadsView/?start_date=${todaydate}&level_name=${stage}`,
      )
      .then(r => {
        console.log('Client today Leads', r.data);
        if (stage === 'prospect') {
          setoriginal_prospectdata(r.data);
          setprospectleads(r.data.results);
        }
        if (stage === 'registered') {
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results);
        }
        if (stage === 'followup') {
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Registered Leads
  const [original_registereddata, setoriginal_registereddata] = useState({});
  const [lead_count, setlead_count] = useState(0);

  const fetchregisteredleads = () => {
    setLoading(true);
    axios
      .get(`${ipaddress}//AllB2Bleads/?stage=registered`)
      .then(r => {
        console.log('Client Regsitered Leads', r.data);
        setoriginal_registereddata(r.data);
        setregisteredleads(r.data.results.data);
        setlead_count(r.data.count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const location = useLocation();
  const queryparams = new URLSearchParams(location.search);
  const s_date = queryparams.get('start_date');
  const e_date = queryparams.get('end_date');
  const month = queryparams.get('month');

  useEffect(() => {
    if (s_date) {
      fetch_rangewise_leads(s_date, e_date, 'registered');
      console.log('RANGEEE');
    } else if (month === 'current_month') {
      axios.get(`${ipaddress}/SendDateAndTime/`).then(r => {
        console.log('Month dates', r.data);
        fetch_rangewise_leads(
          r.data.start_date,
          r.data.end_date_inclusive,
          'registered',
        );
      });
    } else {
      fetchregisteredleads();
      console.log('FLEADSSS');
    }
  }, [s_date, count]);

  const [registeredapi, setregisteredapi] = useState('');

  useEffect(() => {
    if (registeredapi !== '') {
      axios
        .get(`${registeredapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_registereddata(r.data);
          setregisteredleads(r.data.results.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [registeredapi]);

  // Fetch pending registered leads
  const fetch_pending_registeredleads = () => {
    axios
      .post(`${ipaddress}/TodayRegistrationCandidates/${user.emp_id}/`)
      .then(r => {
        console.log('Client pending registered Leads', r.data);
        setoriginal_registereddata(r.data);
        setregisteredleads(r.data.results.client_leads);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch Closed Leads
  const [original_closeddata, setoriginal_closeddata] = useState({});

  const fetchclosedleads = () => {
    axios
      .get(`${ipaddress}/AllB2Bleads/?stage=closed`)
      .then(r => {
        console.log('Client Closed Leads', r.data);
        setoriginal_closeddata(r.data);
        setclosedleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [closedapi, setclosedapi] = useState('');

  useEffect(() => {
    if (closedapi !== '') {
      axios
        .get(`${closedapi}`)
        .then(r => {
          console.log('Customer followup Leads', r.data);
          setoriginal_closeddata(r.data);
          setclosedleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [closedapi]);

  const [all_employees, setall_employees] = useState([]);

  useEffect(() => {
    // fetchallleads()
    axios.get(`${ipaddress2}/AllprofileView/`).then(r => {
      console.log('All employeeess', r.data);
      const data = r.data.filter(x => x.user.blockstatus == false);
      setall_employees(data);
    });
  }, [count]);

  // -------------------------------------Select the Leads----------------------------------------
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selecetdemp, setselectedemp] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
      setSelectAll(false);
    }
  };

  const handleSelectAll = stage => {
    let allleads = [];

    if (!selectAll) {
      if (stage === 'newlead') {
        allleads = leads.map(lead => lead.client_lead_id);
      }
      if (stage === 'walkin') {
        allleads = walkinleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'followup') {
        allleads = followupleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'prospect') {
        allleads = prospectleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'registered') {
        allleads = registeredleads.map(lead => lead.client_lead_id);
      }
      if (stage === 'closed') {
        allleads = closedleads.map(lead => lead.client_lead_id);
      }
      setSelectedLeads(allleads);
    } else {
      setSelectedLeads([]);
    }
    setSelectAll(!selectAll);
  };

  const assign_leads = value => {
    const emp_name = employees.find(x => x.emp_id === value);
    axios
      .post(`${ipaddress}/AssignClientToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        client_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully client assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
        toast.success(`Assigned to ${emp_name.firstname}`, {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setSelectAll(false);
      })
      .catch(err => {
        console.log('Assigning error', err);
        console.log({
          emp_id: value,
          client_lead_ids: selectedLeads,
        });
      });
  };

  // -----------------------------------------Search and take all leads-------------------------------------------------------
  // -------------------------------------------------Search leads from all leads table-----------------------------------

  const [searchvalue, setsearchvalue] = useState('');
  const [category_name, setcategory_name] = useState('select');
  const [category_name2, setcategory_name2] = useState('select');
  const [category_name3, setcategory_name3] = useState('select');
  const [category_name4, setcategory_name4] = useState('select');
  const [category_name5, setcategory_name5] = useState('select');
  const [category_name6, setcategory_name6] = useState('select');
  const [category_name7, setcategory_name7] = useState('select');
  const [category_name8, setcategory_name8] = useState('select');

  const [category_state, setcategory_state] = useState(false);

  const search_new_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name2}/${value}/?level=newlead`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchleads();
    }
  };

  const search_walkin_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name3}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setwalkinleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_followup_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name4}/${value}/?level=followup`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setfollowupleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchfollowupleads();
    }
  };

  const search_prospect_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name5}/${value}/?level=prospect`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setprospectleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchprospectsleads();
    }
  };

  const search_registered_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name6}/${value}/?level=registered`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setregisteredleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setCount(count + 1);
    }
  };

  const search_closed_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseAllB2Cleads/${category_name7}/${value}/?level=closed`,
        )
        .then(r => {
          console.log('searched new Leads', r.data);
          setclosedleads(r.data);
          console.log(
            `${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchclosedleads();
    }
  };

  // Upload Excel
  const [excel, setexcel] = useState(null);

  const uploadexcel = file => {
    const formdata = new FormData();
    formdata.append('file', excel);

    axios
      .post(`${ipaddress}/ImportExcelClientView/`, formdata)
      .then(r => {
        console.log('Uploaded successfully', r.data);
        toast.success('File uploaded successfully', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setexcel(null);
      })
      .catch(err => {
        console.log('Uploading error', err);
      });
  };

  // ---------------------------------------------------Delete New laeds-----------------------------------------------------
  const delete_lead = id => {
    axios.put(`${ipaddress}/DeleteView/${id}/`).then(r => {
      console.log('Deleted successfully', r.data);
      setCount(count + 1);
      toast.success('B2B lead deleted successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    });
  };

  // --------------------------------------------------Download Excel Template----------------------------------------------------
  const download_template = () => {
    axios.get(`${ipaddress}/DownloadClientExcel/`).then(r => {
      console.log('Triggered', r.data);
      const pdfUrl = r.data.url;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = pdfUrl;
      link.download = r.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // To view the assigned permissions

  const [permissions, setpermissions] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`).then(r => {
      console.log('Permissions', r.data);
      setpermissions(r.data);
    });
  }, []);

  const [course_id, setcourse_id] = useState();
  const [course_name, setcourse_name] = useState('');
  const [show1, setshow1] = useState(false);

  // Combination of search

  const fields = [
    'Name',
    'Email',
    'Contact_Number',
    'Course_Enquired',
    'Source',
    'Date',
    'Employee',
    'Lead_Stage',
  ];

  const [fields_array, setfields_array] = useState({
    Course_Enquired: false,
    Source: false,
    Lead_Stage: false,
    Employee: false,
    Email: false,
    Name: false,
    Date: false,
    Contact_Number: false,
  });

  const handleFieldChange = field => {
    setfields_array(prev => ({
      ...prev,
      [field]: !prev[field], // Toggle the state of the specific field dynamically
    }));
  };

  const [formdata, setformdata] = useState({
    course_enquired: '',
    source: '',
    level_lead: '',
    emp_id: '',
    email: '',
    lead_name: '',
    Date: '',
    contact_number: '',
  });

  const combine_fields = e => {
    const { name, value } = e.target;
    setformdata(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const [load, setload] = useState(false);
  const [show, setshow] = useState(false);

  const cancel_combined_search = () => {
    setshow(false);
    setformdata({
      course_enquired: '',
      source: '',
      level_lead: '',
      emp_id: '',
      email: '',
      lead_name: '',
      Date: '',
      contact_number: '',
    });
    setfields_array({
      Course_Enquired: false,
      Source: false,
      Lead_Stage: false,
      Employee: false,
      Email: false,
      Name: false,
      Date: false,
      Contact_Number: false,
    });
  };

  const combined_search = () => {
    formdata.emp_id = Number(user.id);

    axios
      .post(`${ipaddress}/CombinationSearchClientView/`, formdata)
      .then(r => {
        console.log('Combined filter data', r.data);
        setregisteredleads(r.data);
        cancel_combined_search();
      })
      .catch(err => {
        console.log('Combined search error', err);
      });
  };

  const downloadExcel = (value, name) => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };

  const [lead_detail_modal, setlead_detail_modal] = useState(false);

  return (
    <div>
      {loading ? (
        <Small_preloader />
      ) : (
        <div className="d-flex">
          <div className="w-100">
            <div className="bg-light h-100">
              <div className="px-1 px-lg-0">
                <div className="d-flex justify-content-between align-items-center pt-3">
                  <h5>B2B Leads</h5>
                  <div
                    className={`d-flex align-items-center justify-content-end`}
                    style={{ width: '40%' }}
                  >
                    <select
                      value={selecetdemp}
                      disabled={selectedLeads.length > 0 ? false : true}
                      name=""
                      id=""
                      onChange={e => {
                        assign_leads(e.target.value);
                      }}
                      className={`ms-3 form-select shadow-none ${
                        user.designation === role ||
                        user.designation === 'admin'
                          ? ''
                          : 'd-none'
                      }`}
                      style={{ width: '50%', cursor: 'pointer' }}
                    >
                      <option value="">Assign to</option>
                      {all_employees.map(x => {
                        return (
                          <>
                            <option value={x.user.emp_id}>
                              {x.user.first_name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div id="myTabContent">
                  {/* ---------------------------------------------------New Client Leads-------------------------------------------------- */}
                  <div>
                    <div class={`table-responsive mt-3 bg-white py-3 py-md-4`}>
                      <div className="d-flex justify-content-between align-items-center pb-3 px-2">
                        <h6 className="text-primary">
                          Registered B2B Leads ({lead_count})
                        </h6>
                        <div className="d-flex align-items-center w-50 justify-content-end">
                          <select
                            onChange={e => {
                              setcategory_name6(e.target.value);
                              setcategory_state(!category_state);

                              if (e.target.value === 'pending') {
                                fetch_pending_registeredleads();
                              } else if (e.target.value === 'custom') {
                                setshow(true);
                                setcategory_name6(e.target.value);
                                fields_array.Lead_Stage = true;
                                formdata.level_lead = 'registered';
                              } else if (e.target.value === 'today') {
                                fetch_today_prospectleads('registered');
                              } else {
                                setCount(count + 1);
                              }
                            }}
                            name=""
                            id=""
                            className="ms-3 form-select shadow-none"
                            style={{ width: '30%', cursor: 'pointer' }}
                          >
                            <option value="select">None</option>
                            <option value="name">Name</option>
                            <option value="email">Email</option>
                            <option value="course_enquired">Course</option>
                            <option value="source">Source</option>
                            <option value="mobile_number">Phone Number</option>
                            <option value="employee">Employee</option>
                            <option value="created_at">Date</option>
                            <option value="today">Today Registrations</option>
                            <option value="range">Date Range</option>
                            <option value="custom">Combined search</option>
                          </select>
                          <div
                            class={`input-group rounded bg-white border ms-3 ${
                              category_name6 === 'created_at' ||
                              category_name6 === 'employee' ||
                              category_name6 === 'custom' ||
                              category_name6 === 'range' ||
                              category_name6 === 'today' ||
                              category_name6 === 'mobile_number' ||
                              category_name6 === 'level_lead' ||
                              category_name6 === 'select'
                                ? 'd-none'
                                : ''
                            }`}
                            style={{ width: '50%' }}
                          >
                            <span
                              class="input-group-text bg-transparent border-0"
                              id="basic-addon1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z"
                                  fill="#8E9696"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              onChange={e => {
                                search_registered_leads(e.target.value);
                              }}
                              class="form-control bg-transparent border-0 ps-0 shadow-none"
                              placeholder="Search here..."
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>

                          {/* Filter based on Date */}
                          <input
                            type="date"
                            onChange={e => {
                              search_registered_leads(e.target.value);
                            }}
                            className={`form-control w-50 ms-3 ${
                              category_name6 === 'created_at' &&
                              category_name6 !== 'select'
                                ? ''
                                : 'd-none'
                            }`}
                          />

                          <input
                            type="date"
                            onChange={e => {
                              setstart_date(e.target.value);
                              fetch_rangewise_leads(
                                e.target.value,
                                end_date,
                                'registered',
                              );
                            }}
                            className={`form-control w-50 ms-3 ${
                              category_name6 === 'range' &&
                              category_name6 !== 'select'
                                ? ''
                                : 'd-none'
                            }`}
                          />

                          {/* Filter based on Date */}
                          <input
                            type="date"
                            onChange={e => {
                              setend_date(e.target.value);
                              fetch_rangewise_leads(
                                start_date,
                                e.target.value,
                                'registered',
                              );
                            }}
                            className={`form-control w-50 ms-3 ${
                              category_name6 === 'range' &&
                              category_name6 !== 'select'
                                ? ''
                                : 'd-none'
                            }`}
                          />

                          {/* Filter based on Phone */}
                          <input
                            type="tel"
                            maxLength={10}
                            onChange={e => {
                              search_registered_leads(e.target.value);
                            }}
                            className={`form-control w-50 ms-3 ${
                              category_name6 === 'mobile_number' &&
                              category_name6 !== 'select'
                                ? ''
                                : 'd-none'
                            }`}
                          />

                          {/* Filter based on Employee */}
                          <select
                            onChange={e => {
                              search_registered_leads(e.target.value);
                            }}
                            className={`form-select shadow-none border-white-subtle w-50 ms-3 ${
                              category_name6 === 'employee' &&
                              category_name6 !== 'select'
                                ? ''
                                : 'd-none'
                            }`}
                          >
                            <option value="">Select...</option>
                            {all_employees.map(x => {
                              return (
                                <>
                                  <option value={x.emp_id}>
                                    {x.firstname}
                                  </option>
                                </>
                              );
                            })}
                          </select>

                          <button
                            className="btn ms-3 border-0"
                            onClick={() => {
                              downloadExcel(registeredleads, 'registeredleads');
                            }}
                          >
                            <Excel_download />
                          </button>
                        </div>
                      </div>
                      <h6
                        className={`text-color text-center ${
                          registeredleads.length > 0 ? 'd-none' : 'py-3'
                        }`}
                      >
                        No Leads available in Registered Category...🧐
                      </h6>

                      <div className="table-responsive">
                        <table
                          class={`w-100 caption-top ${
                            registeredleads.length > 0 ? '' : 'd-none'
                          }`}
                        >
                          <thead className="">
                            <tr style={{ backgroundColor: '#034592' }}>
                              <th
                                scope="col"
                                className={`border-0 px-3`}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectAll ? true : false}
                                  style={{ cursor: 'pointer' }}
                                  onChange={() => {
                                    handleSelectAll('registered');
                                  }}
                                  name=""
                                  id=""
                                />
                              </th>
                              <th
                                scope="col"
                                className="py-3"
                              >
                                <p className=" m-0 fw-normal text-white">
                                  Name
                                </p>
                              </th>
                              <th scope="col">
                                <span className="fw-normal">
                                  <p className=" m-0 fw-normal text-white">
                                    Created at
                                  </p>
                                </span>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Mobile No
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Email
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Course Enquired
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Source
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Employee
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Assigned By
                                </p>
                              </th>
                              <th scope="col">
                                <p className=" m-0 fw-normal text-white">
                                  Remarks
                                </p>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {registeredleads.map((x, index) => {
                              return (
                                <tr className="table-row">
                                  <td
                                    className="border-0 px-3"
                                    scope="row"
                                  >
                                    <div className="py-2">
                                      <input
                                        type="checkbox"
                                        checked={selectedLeads.includes(
                                          x.client_lead_id,
                                        )}
                                        onChange={e =>
                                          handleCheckboxChange(
                                            e,
                                            x.client_lead_id,
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="py-4">
                                    <p
                                      className=" m-0 tw-relative text-primary"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setlead_id(x.client_lead_id);
                                        setlead_detail_modal(true);
                                      }}
                                    >
                                      {x.name}
                                      {x.priority && (
                                        <span className="tw-absolute px-2  tw-rounded-full tw-text-red-600  -tw-bottom-2 tw-right-8 ">
                                          {x.priority}
                                        </span>
                                      )}
                                    </p>
                                  </td>
                                  <td className="py-3">
                                    <p
                                      className=" m-0"
                                      style={{ width: '200px' }}
                                    >
                                      {x.created_at
                                        ? `${x.created_at.slice(
                                            0,
                                            10,
                                          )} (${x.created_at.slice(11, 19)})`
                                        : ''}
                                    </p>
                                  </td>
                                  <td className="py-3">
                                    <p className=" m-0">{x.contact_number}</p>
                                  </td>
                                  <td className="py-3">
                                    <p
                                      className=" m-0"
                                      style={{ width: '280px' }}
                                    >
                                      {x.email}
                                    </p>
                                  </td>
                                  <td className="py-3">
                                    <p
                                      className={
                                        x.othercourseenquired === null
                                          ? ' m-0'
                                          : 'd-none'
                                      }
                                    >
                                      {x.course_enquiredd}
                                    </p>
                                    <p
                                      className={
                                        x.othercourseenquired === null
                                          ? 'd-none'
                                          : ' m-0'
                                      }
                                    >
                                      {x.othercourseenquired}{' '}
                                      <span
                                        style={{
                                          fontStyle: 'italic',
                                          color: 'orangered',
                                          fontSize: '13px',
                                        }}
                                      >
                                        (Other)
                                      </span>
                                    </p>
                                  </td>
                                  <td className="py-3">
                                    <p className=" m-0">{x.source}</p>
                                  </td>
                                  <td className="py-3">
                                    <p className=" m-0">{x.emp_name}</p>
                                  </td>
                                  <td className="py-3">
                                    <p className=" m-0">
                                      {x.assigned_byy ? x.assigned_byy : '-'}
                                    </p>
                                  </td>

                                  <RemarksSections x={x} />

                                  <td className={`py-3`}>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <p
                                        className={` text-success ${
                                          x.invoice_status == false &&
                                          x.admin_approval == false
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Performa Invoice Generated
                                      </p>
                                      <p
                                        className={` text-warning ${
                                          x.admin_approval ? '' : 'd-none'
                                        }`}
                                      >
                                        Admin has to approve
                                      </p>

                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          navigate(
                                            `/installment_form/${x.client_lead_id}`,
                                          );
                                        }}
                                        className={`btn btn-sm text-white btn-success ${
                                          x.invoice_status &&
                                          x.level_lead_name === 'registered' &&
                                          x.admin_approval == false
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Invoice Generated
                                      </span>
                                    </div>
                                  </td>

                                  <td
                                    className={`py-3 ${
                                      x.invoice_status &&
                                      x.admin_approval == false
                                        ? ''
                                        : 'd-none'
                                    }`}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 250 }}
                                      overlay={renderTooltip3}
                                    >
                                      <div className="">
                                        <svg
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            navigate(
                                              `/invoice/${x.client_lead_id}`,
                                            );
                                          }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-receipt-cutoff"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                                          <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z" />
                                        </svg>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3 px-2">
                        <button
                          className={`btn btn-sm px-3 text-white rounded-0`}
                          style={{ backgroundColor: '#002147' }}
                          disabled={
                            original_registereddata.previous !== null
                              ? false
                              : true
                          }
                          onClick={() => {
                            setregisteredapi(
                              original_registereddata &&
                                original_registereddata.previous,
                            );
                          }}
                        >
                          Prev
                        </button>
                        <button
                          className={`btn btn-sm px-3 text-white rounded-0`}
                          style={{ backgroundColor: '#002147' }}
                          disabled={
                            original_registereddata.next !== null ? false : true
                          }
                          onClick={() => {
                            setregisteredapi(
                              original_registereddata &&
                                original_registereddata.next,
                            );
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* --------------------------MODAL FORMS-------------------------- */}
          <ClientForms
            show1={show1}
            setshow1={setshow1}
            client_lead_id={client_lead_id}
            lead_id={lead_id}
            setCount={setCount}
            stage1={stage}
            setstage={setstage}
            course_id={course_id}
            course_name={course_name}
          />

          {/* Combination of search */}
          <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title"
            centered
          >
            <Modal.Header className="d-flex align-items-center justify-content-between">
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="fs-5"
              >
                Combination of Search
              </Modal.Title>
              <svg
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  cancel_combined_search();
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2 bg-light">
                <h6 className="fs-5 mb-4  text-primary">Select Categories</h6>
                <div className="d-flex align-items-center justify-content-between px-3 row m-0">
                  {fields.map(x => {
                    return (
                      <div className="col-md-6 col-lg-4">
                        <p
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleFieldChange(x);
                          }}
                          className="d-flex align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class={`bi bi-check-square ${
                              fields_array[x] ? 'd-none' : ''
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#0D6EFD"
                            class={`bi bi-check-square-fill ${
                              fields_array[x] ? '' : 'd-none'
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                          </svg>
                          <span className="ms-2">{x}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div className={`m-0 border-top pt-4 mb-3 mt-2`}>
                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Lead_Stage ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on Lead Stage */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Lead Stage
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        value={formdata.level_lead}
                        onChange={combine_fields}
                        name="level_lead"
                        id=""
                        className={`bg-transparent border-0 border-bottom rounded-0 form-select shadow-none py-2 mt-2`}
                        style={{ border: '2px solid white' }}
                      >
                        <option value="">All Leads</option>
                        <option value="newlead">New Lead</option>
                        <option value="walkin">Walkin</option>
                        <option value="followup">Followup</option>
                        <option value="prospect">Prospect</option>
                        <option value="registered">Registered</option>
                        <option value="closed">Closed</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className={`row align-items-center m-0 mb-4 ${
                      fields_array.Course_Enquired ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on course */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Course Enquired
                      </label>
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        value={formdata.course_enquired}
                        name="course_enquired"
                        onChange={combine_fields}
                        className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                        style={{ border: '2px solid white' }}
                      />
                    </div>
                  </div>

                  <div
                    className={`row align-items-center m-0 mb-4 ${
                      fields_array.Source ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on source */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Source
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        value={formdata.source}
                        onChange={combine_fields}
                        name="source"
                        id=""
                        className={`form-select bg-transparent border-0 border-bottom rounded-0 shadow-none py-2 mt-2`}
                        style={{ border: '2px solid white' }}
                      >
                        <option
                          value="none"
                          selected
                        >
                          Choose...
                        </option>
                        <option value="other">Other</option>
                        <option value="walkin">Walkin</option>
                        <option value="naukri">Naukri</option>
                        <option value="linkedin">Linkedin</option>
                        <option value="just dial">Just Dial</option>
                        <option value="whatsapp">Whatsapp</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                      </select>
                    </div>
                  </div>

                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Employee ? '' : 'd-none'
                    }`}
                  >
                    <div className="col-md-6">
                      <label htmlFor="">Employee</label>
                    </div>
                    <div className="col-md-6">
                      <select
                        value={formdata.emp_id}
                        onChange={combine_fields}
                        name="emp_id"
                        id=""
                        className={`form-select bg-transparent border-0 border-bottom rounded-0 shadow-none py-2 mt-2`}
                        style={{ border: '2px solid white' }}
                      >
                        <option value="">Select...</option>
                        {all_employees.map(x => {
                          return (
                            <>
                              <option value={x.user.emp_id}>
                                {x.user.first_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Name ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on course */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Name
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={formdata.lead_name}
                        name="lead_name"
                        onChange={combine_fields}
                        className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                        style={{ border: '2px solid white' }}
                      />
                    </div>
                  </div>

                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Email ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on course */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Email
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={formdata.email}
                        name="email"
                        onChange={combine_fields}
                        className={`form-control shadow-none py-2 bg-transparent mt-2 rounded-0 border-0 border-bottom`}
                        style={{ border: '2px solid white' }}
                      />
                    </div>
                  </div>

                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Contact_Number ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on course */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Contact Number
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="tel"
                        maxLength={10}
                        value={formdata.contact_number}
                        name="contact_number"
                        onChange={combine_fields}
                        className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                        style={{ border: '2px solid white' }}
                      />
                    </div>
                  </div>

                  <div
                    className={`row m-0 align-items-center mb-4 ${
                      fields_array.Date ? '' : 'd-none'
                    }`}
                  >
                    {/* Filter based on course */}
                    <div className="col-md-6">
                      <label
                        htmlFor=""
                        className="fw-normal"
                      >
                        Date
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="date"
                        value={formdata.Date}
                        name="Date"
                        onChange={combine_fields}
                        className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`}
                        style={{ border: '2px solid white' }}
                      />
                    </div>
                  </div>

                  <div className="text-end mt-2">
                    <button
                      className="btn btn-sm btn-primary px-4 py-2"
                      onClick={combined_search}
                    >
                      <span
                        class={`spinner-border spinner-border-sm ${
                          load ? '' : 'd-none'
                        }`}
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className={load ? 'd-none' : ''}>Search</span>
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Lead_details
            show={lead_detail_modal}
            setshow={setlead_detail_modal}
            id={lead_id}
          />
        </div>
      )}
    </div>
  );
};

export default Client_registered_leads;
