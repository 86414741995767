import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Leave_request = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState();

  const [query, setquery] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [trainer, settrainer] = useState({});

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [batches, setBatches] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress2}/StudentBatches/${user.email}/`).then(r => {
      console.log('Student batches', r.data);
      setBatches(r.data);
    });
  }, []);

  const [batch_id, setBatch_id] = useState();

  const [state, setstate] = useState(false);

  const send_leave = () => {
    setLoading(true);
    setTimeout(() => {
      send();
    }, 1000);
  };

  const send = () => {
    axios
      .post(`${ipaddress2}/LeaveRequest/${user.email}/`, {
        batch_id: batch_id,
        leave: query,
        from_date: start_date,
        to_date: end_date,
      })
      .then(r => {
        console.log('Leave Request sent', r.data);
        setquery('');
        settrainer({});
        setstart_date('');
        setend_date('');
        setLoading(false);
        setstate(true);
      });
  };

  const fetch_trainer = id => {
    axios.get(`${ipaddress2}/BatchTrainers/${id}/`).then(r => {
      console.log('Trainer name', r.data);
      settrainer(r.data);
    });
  };

  const [load, setload] = useState();

  const chaeck_leave = end_date => {
    // axios
    //   .post(`${ipaddress2}/LeaveChecking/${user.email}/`, {
    //     from_date: start_date,
    //     to_date: end_date,
    //   })

    //   .then(r => {
    //     console.log(r.data);
    //     setend_date(end_date);
    //     setleave_limit(false);
    // })

    // .catch(err => {
    //     setleave_limit(true);
    // });

    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    // Calculate the difference in time (milliseconds)
    const diffInTime = endDate.getTime() - startDate.getTime();

    if (diffInTime > 7) {
      setBreakMess(true);
    }

    setend_date(end_date);
    setleave_limit(false);
  };

  const [breakMess, setBreakMess] = useState(false);

  const [leave_limit, setleave_limit] = useState(false);
  const [btn_state, setbtn_state] = useState(false);
  const [load2, setload2] = useState();
  const [notification, setnotification] = useState('');

  const performa_invoice_generation = () => {
    setload2(true);
    setTimeout(() => {
      send_data();
    }, 1000);
  };
  const send_data = () => {
    axios
      .post(`${ipaddress2}/invoiceGenerationForLeave/${user.email}/`)
      .then(r => {
        console.log('Performa Invoice Generated', r.data);
        setload2(false);
        setbtn_state(true);
      });
  };

  const Leave_request = () => {
    setload2(true);
    // setTimeout(() => {
    send_data2();
    // }, 1000);
  };
  const send_data2 = () => {
    axios
      .post(`${ipaddress2}/SendLeaveNotificationToEmployee/`, {
        customer_id: user.id,
        notification: notification,
      })
      .then(r => {
        console.log('Leave requested successfully', r.data);
        setload2(false);
        setbtn_state(true);
        setleave_limit(false);
        setquery('');
        settrainer({});
        setstart_date('');
        setend_date('');
        toast.success('Request successfully sent', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      })
      .catch(err => {
        toast.warn('You already requested for the leave', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setleave_limit(false);
        setquery('');
        settrainer({});
        setstart_date('');
        setend_date('');
      });
  };

  const [request, setrequest] = useState(false);

  return (
    <div
      className="d-flex animate__animated animate__fadeIn"
      style={{ backgroundColor: '#E8E8E8' }}
    >
      <Sidebar activevalue={'leave'} />

      <div className="w-100">
        <Navbar />

        <div
          className="p-3 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="bg-white py-3 py-lg-4">
            {/* Conatiner 1 */}
            <div
              className={`row m-0 mx-auto leave-div justify-content-center py-5 shadow-sm ${
                state ? 'd-none' : ''
              }`}
              style={{ border: '1px solid #002147' }}
            >
              <h5 className="text-center fw-bold">
                <span
                  className="ms-2"
                  style={{ fontSize: '16px' }}
                >
                  Leave Application Form
                </span>
              </h5>
              <p className="text-center mt-2">
                Please provide information about your leave
              </p>
              <div className="col-lg-11 d-flex align-items-center mt-4">
                <div className="w-100">
                  <div className="row m-0">
                    <div className="col-md-6 ps-0">
                      <div className="">
                        <label
                          htmlFor=""
                          className="fw-medium"
                        >
                          Select the Batch
                        </label>
                        <select
                          type="text"
                          onChange={e => {
                            if (e.target.value !== 'select') {
                              fetch_trainer(e.target.value);
                              setBatch_id(e.target.value);
                            }
                          }}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #57309C',
                          }}
                          className="form-select bg-white mt-2 shadow-none py-3"
                        >
                          <option value="select">Select the Batch</option>
                          {batches.map(x => {
                            return (
                              <>
                                <option value={x.id}>
                                  {x.name.sub_course_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 pe-0">
                      <label
                        htmlFor=""
                        className="fw-medium"
                      >
                        Approver
                      </label>
                      <input
                        type="text"
                        value={trainer.first_name && trainer.first_name}
                        className="form-control mt-2 shadow-none bg-white py-3"
                        style={{ border: '1px solid #57309C' }}
                      />
                    </div>

                    <div className="col-md-6 ps-0 mt-4">
                      <label
                        htmlFor=""
                        className="fw-medium"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        value={start_date}
                        onChange={e => {
                          setstart_date(e.target.value);
                        }}
                        className="form-control mt-2 shadow-none bg-white py-3"
                        style={{ border: '1px solid #57309C' }}
                      />
                    </div>

                    <div className="col-md-6 pe-0 mt-4">
                      <label
                        htmlFor=""
                        className="fw-medium"
                      >
                        To
                      </label>
                      <input
                        value={end_date}
                        onChange={e => {
                          chaeck_leave(e.target.value);
                        }}
                        type="date"
                        disabled={start_date !== '' ? false : true}
                        className="form-control mt-2 shadow-none bg-white py-3"
                        style={{ border: '1px solid #57309C' }}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor=""
                      className="fw-medium"
                    >
                      Reason for the Leave
                    </label>
                    <textarea
                      value={query}
                      onChange={e => {
                        setquery(e.target.value);
                      }}
                      type="text"
                      className="form-control mt-2 shadow-none bg-white"
                      rows={4}
                      placeholder="Type your messages..."
                      style={{ border: '1px solid #57309C' }}
                    />
                  </div>

                  {breakMess && (
                    <div className="tw-text-red-500 tw-w-full tw-h-full tw-mt-4">
                      Please note that the leave duration exceeds 7 days, Hence
                      a Break Fee will be applicable, which must be paid.
                    </div>
                  )}

                  <div className="mt-4 pt-3 d-flex justify-content-end">
                    <button
                      style={{ border: '1px solid #002147', color: '#002147' }}
                      className="rounded-0 btn btn-sm py-2 px-4 d-flex justify-content-center align-items-center"
                      onClick={send_leave}
                    >
                      <span
                        onClick={() => {
                          setBatch_id('');
                          setend_date('');
                          setstart_date('');
                          setquery('');
                        }}
                      >
                        Cancel
                      </span>
                    </button>
                    <button
                      disabled={
                        batch_id > 0 &&
                        start_date !== '' &&
                        end_date !== '' &&
                        query.length > 0
                          ? false
                          : true
                      }
                      className="ms-4 btns py-2 px-3 text-white d-flex justify-content-center align-items-center"
                      onClick={send_leave}
                    >
                      <span
                        class={`spinner-border spinner-border-sm ${
                          loading ? '' : 'd-none'
                        }`}
                        aria-hidden="true"
                      ></span>
                      <span
                        role="status"
                        className={`ms-2 ${loading ? '' : 'd-none'}`}
                      >
                        Applying...
                      </span>
                      <span className={`${loading ? 'd-none' : ''}`}>
                        Apply for Leave
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Container 2 */}
            <div
              className={`row m-0 animate__animated animate__bounceIn rounded mx-auto leave-div justify-content-center py-5 shadow-sm mt-3 ${
                state ? '' : 'd-none'
              }`}
              style={{ backgroundColor: '#f0f1f9' }}
            >
              <div className="col-md-8 d-flex align-items-center mt-4">
                <div className="w-100 d-flex flex-column align-items-center">
                  <img
                    src={require('../images/student/Group.png')}
                    style={{ width: '70%' }}
                    alt=""
                  />
                  <p className="fw-bold mt-5 fs-5">Wait for Approval</p>
                  <p
                    className="m-0"
                    style={{ fontSize: '12px' }}
                  >
                    Your request has been sent!
                  </p>
                  <button
                    onClick={() => {
                      navigate('/student_dashboard');
                    }}
                    className="btn text-white mt-2 px-4 py-2"
                    style={{ backgroundColor: '#57309C' }}
                  >
                    Back to Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={`animate__animated animate__fadeIn ${
          leave_limit ? '' : 'd-none'
        }`}
        style={{
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          position: 'fixed',
          zIndex: 6,
        }}
      >
        <div className="row m-0 w-100 pt-4">
          <div
            className="bg-white p-3 col-lg-8 py-4 mx-auto"
            style={{ borderRadius: '10px' }}
          >
            <p className="text-end">
              <svg
                onClick={() => {
                  setleave_limit(false);
                }}
                style={{ cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </p>
            <div className={`${request ? 'd-none' : ''}`}>
              <p
                className="fw-bold text-center"
                style={{
                  color: '#48267F',
                  letterSpacing: '1px',
                  fontSize: '16px',
                }}
              >
                {user.email},
              </p>
              <p
                className="fw-medium text-center"
                style={{ fontSize: '15px' }}
              >
                You does not have enough leaves available. You already reached
                the leave limit.
              </p>

              <p
                className="fw-medium text-center mt-5"
                style={{ fontSize: '15px' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#FE4C00"
                  class="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                </svg>{' '}
                <span
                  className="ms-1"
                  style={{ fontSize: '16px' }}
                >
                  If you want to apply for the further leaves, you have to
                  contact Administrator.
                </span>
              </p>

              <div className="text-end mt-4">
                <button
                  className={`btn text-white px-3 ${btn_state ? 'd-none' : ''}`}
                  style={{ backgroundColor: '#47267D' }}
                  onClick={() => {
                    setrequest(true);
                  }}
                >
                  <span>Request for Leave</span>
                </button>

                <button
                  className={`btn text-white px-3 ${btn_state ? '' : 'd-none'}`}
                  style={{ backgroundColor: '#47267D' }}
                  onClick={() => {
                    navigate('/student_performa_invoice');
                  }}
                >
                  View Performa Invoice
                </button>
              </div>
            </div>

            <div className={`${request ? '' : 'd-none'}`}>
              <p
                className="fw-bold text-center"
                style={{
                  color: '#48267F',
                  letterSpacing: '1px',
                  fontSize: '16px',
                }}
              >
                {user.email},
              </p>
              <p
                className="fw-medium text-center"
                style={{ fontSize: '15px' }}
              >
                Enter the number of days leave you want
              </p>

              <div className="row m-0">
                <div className="col-md-8 mx-auto">
                  <textarea
                    type="text"
                    className="form-control py-3 shadow-none border-secondary-subtle"
                    value={notification}
                    onChange={e => {
                      setnotification(e.target.value);
                    }}
                    placeholder="Enter the reason..."
                  />
                </div>
              </div>
              <div className="text-end mt-4">
                <button
                  className={`btn text-white px-3 ${btn_state ? 'd-none' : ''}`}
                  style={{ backgroundColor: '#47267D' }}
                  onClick={() => {
                    Leave_request();
                  }}
                >
                  <span>Send the request</span>
                </button>

                <button
                  className={`btn text-white px-3 ${btn_state ? '' : 'd-none'}`}
                  style={{ backgroundColor: '#47267D' }}
                  onClick={() => {
                    navigate('/student_performa_invoice');
                  }}
                >
                  View Performa Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Leave_request;
