import axios from 'axios';
import { useEffect, useState } from 'react';
import { ipaddress } from '../../App';

function CollectionsWiseFiltering({ id, typeWise }) {
  const [data, setData] = useState([]);

  const fetchCourseCollections = () => {
    axios
      .get(`${ipaddress}/CourseWiseDailyCollectionsAPIView/${id}/`)
      .then(response => {
        const result = response.data || [];
        setData(result);
      })
      .catch(error => console.error('Error fetching course data:', error));
  };

  const fetchSourceCollections = () => {
    axios
      .get(`${ipaddress}/SourceWiseEmployeeMonthlyCollectionsAPIView/${id}/`)
      .then(response => {
        const result = response.data.collections || [];
        console.log('SOURCECOL', result);
        setData(result);
      })
      .catch(error => console.error('Error fetching source data:', error));
  };

  useEffect(() => {
    if (typeWise === 'collections_course') {
      fetchCourseCollections();
    } else if (typeWise === 'collections_source') {
      fetchSourceCollections();
    }
  }, [typeWise]);

  // Function to extract all unique dates from the daily collections across all items
  const getAllUniqueDates = () => {
    const allDates = [];
    data?.forEach(item => {
      item.daily_collections.forEach(collection => {
        if (!allDates.includes(collection.date)) {
          allDates.push(collection.date);
        }
      });
    });
    return allDates;
  };

  const dates = getAllUniqueDates();

  return (
    <div>
      <table className="tw-w-full tw-table-auto tw-rounded-lg tw-shadow-md">
        <thead>
          <tr className="tw-bg-gray-200">
            <th className="tw-py-3 tw-px-4">S.No</th>
            <th className="tw-py-3 tw-px-4">Name</th>
            <th className="tw-py-3 tw-px-4">Type</th>
            <th className="tw-py-3 tw-px-4">Total Collections</th>
            {console.log('DATES', dates)}
            {dates?.map((date, idx) => (
              <th
                key={idx}
                className="tw-py-3 tw-px-4"
              >
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td
                colSpan={4 + dates.length}
                className="tw-text-center tw-py-6 tw-text-gray-500"
              >
                No Data Available
              </td>
            </tr>
          ) : (
            data?.map((item, index) => (
              <tr
                key={index}
                className="tw-bg-white hover:tw-bg-gray-100"
              >
                <td className="tw-py-3 tw-px-4">{index + 1}</td>
                <td className="tw-py-3 tw-px-4">{item.course || 'other'}</td>
                <td className="tw-py-3 tw-px-4">
                  {typeWise === 'collections_course' ? 'Course' : 'Source'}
                </td>
                <td className="tw-py-3 tw-px-4">{item.total_billing || 0}</td>
                {dates?.map((date, idx) => {
                  const collection = item.daily_collections.find(
                    col => col.date === date,
                  );
                  return (
                    <td
                      key={idx}
                      className="tw-py-3 tw-px-4"
                    >
                      {collection ? collection.total_collected : 0}
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CollectionsWiseFiltering;
