import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ipaddress } from '../../App';

function ApproveRefund() {
  const { id } = useParams();
  const [amount, setAmount] = useState(null);
  const [status, setStatus] = useState(null); // Tracks approval status (e.g., "Approved")
  const [isApproved, setIsApproved] = useState(false);

  const fetchDetails = () => {
    const IPADDRESS = `${ipaddress}/Refund/`;
    axios
      .patch(IPADDRESS, { id })
      .then(res => {
        console.log(res.data);
        setAmount(res.data.amount); // Assuming API returns `amount`
        setStatus(res.data.data); // Tracks whether refund is "Approved"
      })
      .catch(err => console.error(err));
  };

  const handleApproval = () => {
    if (!isApproved) {
      alert('Please check the box to approve the refund.');
      return;
    }

    const IPADDRESS = `${ipaddress}/Refund/`;
    axios
      .put(IPADDRESS, { id })
      .then(res => {
        alert('Refund approved successfully!');
        console.log(res.data);
        setStatus('Approved'); // Update status after approval
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100">
      <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-6 tw-w-96 tw-text-center">
        <h2 className="tw-text-xl tw-font-semibold tw-mb-4">Approve Refund</h2>
        {amount !== null ? (
          <>
            <p className="tw-text-gray-600 tw-mb-4">
              Refund Amount: <span className="tw-font-bold">₹{amount}</span>
            </p>
            {status === 'Approved' ? (
              <p className="tw-text-green-600 tw-font-medium">
                You have already approved this refund.
              </p>
            ) : (
              <>
                <div className="tw-flex tw-items-center tw-gap-2 tw-justify-center tw-mb-4">
                  <input
                    type="checkbox"
                    id="approve"
                    className="tw-w-5 tw-h-5 tw-accent-blue-500"
                    checked={isApproved}
                    onChange={e => setIsApproved(e.target.checked)}
                  />
                  <label
                    htmlFor="approve"
                    className="tw-text-gray-700"
                  >
                    Approve Refund
                  </label>
                </div>
                <button
                  onClick={handleApproval}
                  className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-hover:bg-blue-600 tw-transition-all"
                >
                  Submit
                </button>
              </>
            )}
          </>
        ) : (
          <p className="tw-text-gray-500">Loading refund details...</p>
        )}
      </div>
    </div>
  );
}

export default ApproveRefund;
