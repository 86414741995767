import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';

const All_students_list = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
    // initialSlide: 0,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [type, settype] = useState('all');
  const [all_students, setall_students] = useState([]);
  const [droppedout_students, setdroppedout_students] = useState([]);
  const [count, setcount] = useState(0);
  useEffect(() => {
    axios.get(`${ipaddress}/AllStudents/${user.emp_id}/False/`).then(r => {
      console.log('All students', r.data);
      setall_students(r.data);
    });
  }, [count]);

  const fetch_dropout = () => {
    axios.get(`${ipaddress}/AllStudents/${user.emp_id}/True/`).then(r => {
      console.log('Droppedout students', r.data);
      setdroppedout_students(r.data);
    });
  };

  const [refunded_students, setrefunded_students] = useState([]);

  const fetch_refund = () => {
    axios.get(`${ipaddress}/Refund/`).then(r => {
      console.log('Refunded students', r.data);
      setrefunded_students(r.data);
    });
  };

  const [reason, setreason] = useState('');
  const [amount, setamount] = useState(0);

  // To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const dropout = () => {};

  // const fetch_dropout=()=>{
  // axios.get(`${ipaddress}/DropoutView/`)
  // .then((r)=>{
  //     console.log("Droppedout students",r.data)
  // })
  // }

  const [student_id, setstudent_id] = useState();

  const [image, setimage] = useState(null);

  const dropout_student = () => {
    axios
      .patch(`${ipaddress}/DropoutView/`, {
        customer_id: student_id,
        droppedoutreason: reason,
        droppedout: true,
      })
      .then(r => {
        console.log('Droppedout successfully', r.data);
        setcount(count + 1);
        setreason('');
      });
  };

  const refund = () => {
    const formdata = new FormData();
    formdata.append('customer', student_id);
    formdata.append('refundedamount', amount);
    formdata.append('document', image);

    axios.post(`${ipaddress}/Refund/`, formdata).then(r => {
      console.log('Refunded successfully', r.data);
      toast.success('Refunded successfully', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setcount(count + 1);
      setamount(0);
      setimage(null);
    });
  };

  const block_student = (id, status, name) => {
    axios
      .post(`${ipaddress2}/BlockStudent/`, {
        customer_id: id,
        status: status,
      })

      .then(r => {
        setcount(count + 1);
        console.log(r.data);
        if (status == true) {
          toast.warn(`You blocked ${name}`, {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        } else {
          toast.success(`You unblocked ${name}`, {
            autoClose: 2000,
            theme: 'colored',
            position: 'top-center',
          });
        }
      });
  };

  return (
    <div className="d-flex">
      <Sidebar activevalue={'students_list'} />
      <div className="w-100">
        <Navbar />

        <div
          className="p-3 bg-light main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="">
            <div className="">
              {/* <Slider {...settings} className='px-2'> */}
              <div className="d-flex">
                <div className="">
                  <p
                    onClick={() => {
                      settype('all');
                    }}
                    className="py-2 justify-content-center px-4 d-flex align-items-center"
                    style={{
                      color: type === 'all' ? '#ffff' : '#002147',
                      cursor: 'pointer',
                      border: '1px solid #002147',
                      backgroundColor: type === 'all' ? '#002147' : '',
                    }}
                  >
                    All Students
                  </p>
                </div>
                <div className="text-center">
                  <p
                    onClick={() => {
                      settype('dropout');
                      fetch_dropout();
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center"
                    style={{
                      color: type === 'dropout' ? '#ffff' : '#002147',
                      cursor: 'pointer',
                      border: '1px solid #002147',
                      backgroundColor: type === 'dropout' ? '#002147' : '',
                    }}
                  >
                    Droppedout Students
                  </p>
                </div>
                <div className="text-center">
                  <p
                    onClick={() => {
                      settype('refund');
                      fetch_refund();
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center"
                    style={{
                      color: type === 'refund' ? '#ffff' : '#002147',
                      cursor: 'pointer',
                      border: '1px solid #002147',
                      backgroundColor: type === 'refund' ? '#002147' : '',
                    }}
                  >
                    Refunded Students
                  </p>
                </div>
              </div>
              {/* </Slider> */}
            </div>
          </div>

          {/* All Students */}

          <div className={`${type === 'all' ? '' : 'd-none'}`}>
            <h6
              className={`text-center py-4 text-secondary ${
                all_students.length > 0 ? 'd-none' : ''
              }`}
            >
              No data available...🧐
            </h6>
            <div
              className={`table-responsive ${
                all_students.length > 0 ? '' : 'd-none'
              }`}
            >
              <table class="w-100">
                <thead className="table-secondary">
                  <tr
                    style={{ backgroundColor: '#034592' }}
                    className="text-white"
                  >
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className="text-center fw-normal m-0">SI.No</p>
                    </th>
                    <th scope="col">
                      <p className="text-center fw-normal m-0">Name</p>
                    </th>
                    <th scope="col">
                      <p className="text-center fw-normal m-0">Email</p>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {all_students.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <th
                          scope="row"
                          className="py-3"
                        >
                          <p className="text-center m-0">{index + 1}</p>
                        </th>
                        <td>
                          <p className="text-center m-0">{x.customer_name}</p>
                        </td>
                        <td>
                          <p className="text-center m-0">{x.email}</p>
                        </td>
                        <td>
                          <div className="text-center">
                            <button
                              className="btns text-white py-1 px-3"
                              data-bs-toggle="modal"
                              data-bs-target="#dropout_modal"
                              onClick={() => {
                                setstudent_id(x.id);
                              }}
                            >
                              Dropout
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            <button
                              className={`btns text-white py-1 px-3 ${
                                x.refund_status ? 'd-none' : ''
                              }`}
                              data-bs-toggle="modal"
                              data-bs-target="#refund_modal"
                              onClick={() => {
                                setstudent_id(x.id);
                              }}
                            >
                              Refund
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            <button
                              className={`btn btn-sm btn-danger rou px-3 ${
                                x.block_status ? 'd-none' : ''
                              }`}
                              onClick={() => {
                                block_student(x.id, true, x.customer_name);
                              }}
                            >
                              Block
                            </button>
                            <button
                              className={`btn btn-sm rounded-0 btn-success px-3 ${
                                x.block_status ? '' : 'd-none'
                              }`}
                              onClick={() => {
                                block_student(x.id, false, x.customer_name);
                              }}
                            >
                              Unblock
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Dropout */}

          <div className={`${type === 'dropout' ? '' : 'd-none'}`}>
            <h6
              className={`text-center py-4 text-secondary ${
                droppedout_students.length > 0 ? 'd-none' : ''
              }`}
            >
              No data available...🧐
            </h6>

            <div
              className={`table-responsive ${
                droppedout_students.length > 0 ? '' : 'd-none'
              }`}
            >
              <table class="w-100">
                <thead className="table-secondary">
                  <tr style={{ backgroundColor: '#034592' }}>
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className="text-center text-white fw-normal m-0">
                        SI.No
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Name
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Email
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Droppedout on
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Reason
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {droppedout_students.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <th scope="row">
                          <p className="text-center m-0">{index + 1}</p>
                        </th>
                        <td className="py-3">
                          <p className="text-center m-0">{x.customer_name}</p>
                        </td>
                        <td>
                          <p className="text-center m-0">{x.email}</p>
                        </td>
                        <td>
                          <p className="text-center m-0">
                            {x.droppedoutdate && x.droppedoutdate.slice(0, 10)}
                          </p>
                        </td>
                        <td>
                          <p className="text-center m-0">
                            {x.droppedoutreason}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Refund */}
          <div className={`${type === 'refund' ? '' : 'd-none'}`}>
            <h6
              className={`text-center py-4 text-secondary ${
                refunded_students.length > 0 ? 'd-none' : ''
              }`}
            >
              No data available...🧐
            </h6>
            <div
              className={`table-responsive ${
                refunded_students.length > 0 ? '' : 'd-none'
              }`}
            >
              <table class="w-100">
                <thead className="table-secondary">
                  <tr style={{ backgroundColor: '#034592' }}>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        SI.No
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="py-3"
                    >
                      <p className="text-center text-white fw-normal m-0">
                        Name
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Refunded Amount
                      </p>
                    </th>
                    <th scope="col">
                      <p className="text-center text-white fw-normal m-0">
                        Refunded on
                      </p>
                    </th>
                    {/* <th scope="col"><p className='text-center m-0'>Reason</p></th> */}
                  </tr>
                </thead>
                <tbody>
                  {refunded_students.map((x, index) => {
                    return (
                      <tr className="table-row">
                        <th
                          scope="row"
                          className="py-3"
                        >
                          <p className="text-center m-0">{index + 1}</p>
                        </th>
                        <td>
                          <p className="text-center m-0">
                            {x.customer &&
                              x.customer.customer_name &&
                              x.customer.customer_name}
                          </p>
                        </td>
                        <td>
                          <p className="text-center m-0">{x.refundedamount}</p>
                        </td>
                        <td>
                          <p className="text-center m-0">{x.refundeddate}</p>
                        </td>
                        {/* <td><p className='text-center m-0'>{x.droppedoutreason}</p></td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Dropout modal */}
      <div
        class="modal fade"
        id="dropout_modal"
        tabindex="-1"
        aria-labelledby="dropout_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center justify-content-between">
              <h1
                class="modal-title fs-5"
                id="exampleModalLabel"
              >
                Provide the Reason for Dropout
              </h1>
              <svg
                style={{ cursor: 'pointer' }}
                data-bs-dismiss="modal"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </div>
            <div class="modal-body">
              <textarea
                name=""
                value={reason}
                onChange={e => {
                  setreason(e.target.value);
                }}
                className="form-control shadow-none border-secondary-subtle"
                rows={3}
                id=""
                placeholder="Enter the reason..."
              ></textarea>

              <div className="text-end mt-3">
                <button
                  className="btn btn-sm btn-primary px-3"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    dropout_student();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Refund modal */}
      <div
        class="modal fade"
        id="refund_modal"
        tabindex="-1"
        aria-labelledby="refund_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header d-flex align-items-center justify-content-between">
              <h1
                class="modal-title fs-5"
                id="exampleModalLabel"
              >
                Enter the amount to be Refund
              </h1>
              <svg
                style={{ cursor: 'pointer' }}
                data-bs-dismiss="modal"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </div>
            <div class="modal-body">
              <input
                type="number"
                value={amount}
                name=""
                onChange={e => {
                  setamount(e.target.value);
                }}
                className="form-control shadow-none border-secondary-subtle py-3"
                id=""
                placeholder="Enter the amount..."
              />

              <div className="row m-0">
                <div className="col-12 align-items-center ps-0 mt-3 d-flex">
                  <input
                    id="fileInput"
                    type="file"
                    // accept=".pdf"
                    name="Photograph"
                    onChange={e => {
                      const { name, files } = e.target;
                      setimage(e.target.files[0]);
                    }}
                    className="bg-light text-center p-3 btn"
                  />
                  <label
                    for="fileInput"
                    class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal"
                    style={{ backgroundColor: '#5D5FE3' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                    >
                      <path
                        d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z"
                        fill="white"
                      />
                    </svg>{' '}
                    <span className="ms-2">Upload Screenshot</span>
                  </label>
                  <span className="d-block text-success ms-2">
                    {image && image.name.slice(0, 16)}
                  </span>
                </div>
              </div>

              <div className="text-end mt-3">
                <button
                  className="btn btn-sm btn-primary px-3"
                  data-bs-dismiss="modal"
                  onClick={refund}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All_students_list;
