import { useNavigate } from 'react-router-dom';

function StatusDropDown({
  setShow2,
  setclient_lead_id,
  setcourse_id,
  setcourse_name,
  setlead_id,
  setshow1,
  setstage,
  movetotherDepartmentleads,
  x,
}) {
  const navigate = useNavigate();

  console.log('XYZ', x);
  return (
    <div class="dropdown py-2 mx-auto px-3">
      <button
        class="btn btn-sm tw-uppercase text-white dropdown-toggle rounded-0"
        style={{ backgroundColor: '#002147' }}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {x.level_lead_name}
      </button>
      <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
        <ul
          className="ps-0 d-flex flex-column align-items-center gap-2"
          style={{ listStyleType: 'none' }}
        >
          <li className={``}>
            <button
              style={{ width: '100px' }}
              className={`btn btn-sm text-white btn-primary`}
              onClick={() => {
                setlead_id(x.lead_id);
                setclient_lead_id(x.client_lead_id);

                setstage(x.level_lead_name);
                setShow2(true);
              }}
            >
              Walkin
            </button>
          </li>
          <li>
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              onClick={() => {
                setclient_lead_id(x.client_lead_id);
                setlead_id(x.customer_lead_id);
                setstage(x.level_lead_name);
                setcourse_id(x.course_enquired);
                setcourse_name(x.course_enquiredd);
                setshow1(true);
              }}
            >
              Followup
            </button>
          </li>
          <li
            className={`${
              x.level_lead_name === 'registered' ||
              x.level_lead_name === 'closed'
                ? 'd-none'
                : ''
            }`}
          >
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#prospectsform"
              onClick={() => {
                setclient_lead_id(x.client_lead_id);
                setlead_id(x.lead_id);
                setcourse_id(x.course_enquired);
                setcourse_name(x.course_enquiredd);
              }}
            >
              Prospects
            </button>
          </li>
          <li
            className={`${
              x.level_lead_name === 'registered' ||
              x.level_lead_name === 'closed'
                ? 'd-none'
                : ''
            }`}
          >
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              onClick={() => {
                navigate(`/mark_as_register_form/${x.client_lead_id}`);
              }}
            >
              Register
            </button>
          </li>
          <li
            className={`${
              x.level_lead_name === 'registered' ||
              x.level_lead_name === 'closed'
                ? 'd-none'
                : ''
            }`}
          >
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#closedform"
              onClick={() => {
                setclient_lead_id(x.client_lead_id);
                setlead_id(x.lead_id);
                setstage(x.level_lead_name);
              }}
            >
              Closed
            </button>
          </li>
          <li className={``}>
            <button
              style={{ width: '100px' }}
              className={`btn btn-sm text-white btn-primary`}
              onClick={() => {
                console.log(x);
                movetotherDepartmentleads(
                  x.customer_lead_id || x.client_lead_id,
                );
              }}
            >
              Other Department Lead
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default StatusDropDown;
