import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { Zoom } from 'react-toastify';
import { ipaddress } from '../../App';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const Prospect_form = ({ show1, setShow1, form_value, type }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [availablecourses, setAvailablecourses] = useState([]);
  const [classtypes, setclasstypes] = useState([]);
  const [batchtypes, setbatchtypes] = useState([]);
  const [particular_lead_details, setparticular_lead_details] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress}/Allclasstypes/`).then(r => {
      console.log('Class types', r.data);
      setclasstypes(r.data.classtypes);
    });

    axios.get(`${ipaddress}/Allbatchtypes/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data.batchtypes);
    });
  }, []);

  const [course_fees, setcourse_fees] = useState({});

  useEffect(() => {
    if (form_value.course_enquired > 0) {
      axios
        .get(`${ipaddress}/CourseFees/${form_value.course_enquired}`)
        .then(r => {
          console.log('Course Fees', r.data);
          setcourse_fees(r.data);
        })
        .catch(err => {
          console.log('Courses fetching Error', err);
        });
    }
  }, [form_value.course_enquired]);

  // To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const [errorMessage, setErrorMessage] = useState('');

  // Prospect Back date validation
  const handleDateValidation2 = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert('You can only select today’s date or future dates.');
      return false;
    }

    setprospects({
      ...prospects,
      [name]: date,
    });

    return true;
  };

  // ---------------------------------------------------Prospects Form--------------------------------------------------------
  const [prospects, setprospects] = useState({
    customer_lead_id: '',
    client_lead_id: '',
    emp_id: user.emp_id,
    level_lead: 'prospect',
    preferred_course: '',
    preferred_batch_type: '',
    preferred_batch_start_time: '',
    preferred_class_type: '',
    expected_registration_date: '',
    tentative_batch_start_date: '',
    billing_amount: '',
    expected_collection: '',
    mode_of_payment: '',
    date_of_payment: '',
    time_of_payment: '',
    remarks: '',
    priority: '',
  });

  const handleprospectsdata = e => {
    const { name, value } = e.target;
    setprospects({
      ...prospects,
      [name]: value,
    });
  };

  const navigate = useNavigate();
  const [load, setload] = useState(false);

  const prospectsdata = e => {
    e.preventDefault();
    setload(true);

    prospects.billing_amount = course_fees.single_shot_fees;

    const data = { ...prospects, ...form_value };

    data.emp_id = user.emp_id;

    let apiurl = '';

    if (type === 'customer') {
      apiurl = 'CustomerLeadsView';
    }
    if (type === 'client') {
      apiurl = 'ClientLeadsView';
    }

    axios
      .post(`${ipaddress}/${apiurl}/`, data)
      .then(r => {
        setShow(false);
        toast.success('Moved to Prospect', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setload(false);
        setprospects({
          customer_lead_id: '',
          client_lead_id: '',
          emp_id: user.emp_id,
          level_lead: 'prospect',
          preferred_course: '',
          preferred_batch_type: '',
          preferred_batch_start_time: '',
          preferred_class_type: '',
          expected_registration_date: '',
          tentative_batch_start_date: '',
          billing_amount: '',
          expected_collection: '',
          mode_of_payment: '',
          date_of_payment: '',
          time_of_payment: '',
          remarks: '',
          priority: '',
        });
        console.log('Moved to Prospects', r.data);
        console.log('Moved to Prospects form', prospects);
        setTimeout(() => {
          if (type === 'customer') {
            navigate('/customerleads');
          }
          if (type === 'client') {
            navigate('/leads');
          }
        }, 2000);
      })
      .catch(err => {
        setload(false);
        console.log('Moved to prospects error', err);
        console.log('Moved to prospects form', prospects);
      });
  };

  const [show, setShow] = useState(false);

  return (
    <div>
      {/* PROSPECT FORM */}

      <Modal
        size="xl"
        aria-labelledby="example-modal-sizes-title-xl"
        show={show1}
        onHide={() => {
          setShow1(false);
        }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
              <h5 className="text-primary">Prospects Form</h5>
              <button
                onClick={() => {
                  setShow1(false);
                }}
                className="bg-transparent border-0 ms-auto"
              >
                <i class="fa-solid fa-circle-xmark fs-5"></i>
              </button>
            </div>
            <form
              action=""
              onSubmit={prospectsdata}
            >
              <div className="row m-0">
                <div className="col-md-6 col-lg-4 mb-3">
                  <input
                    type="text"
                    disabled="true"
                    className="form-control shadow-none py-3"
                    value={form_value && form_value.course_enquired}
                  />
                </div>

                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      disabled="true"
                      type="number"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleprospectsdata}
                      value={course_fees.single_shot_fees}
                      name="billing_amount"
                    />
                    <label for="floatingInput">Total Fees Amount</label>
                  </div>
                </div>

                {/* Installments */}
                {course_fees.installments &&
                  course_fees.installments.map((x, index) => {
                    return (
                      <div className="col-md-6 col-lg-4">
                        <div class="form-floating mb-3">
                          <input
                            disabled="true"
                            type="number"
                            class="form-control shadow-none"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={x.amount}
                            name="billing_amount"
                          />
                          <label for="floatingInput">
                            {' '}
                            Installment {index + 1} Amoun
                          </label>
                        </div>
                      </div>
                    );
                  })}

                <div className="col-md-6 col-lg-4 mb-3">
                  <select
                    id=""
                    className="form-select py-3 shadow-none"
                    onChange={handleprospectsdata}
                    value={prospects.preferred_batch_type}
                    name="preferred_batch_type"
                  >
                    <option value="">Preferred Batch Type</option>
                    {batchtypes.map(x => {
                      return (
                        <>
                          <option value={x}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <select
                    id=""
                    className="form-select py-3 shadow-none"
                    onChange={handleprospectsdata}
                    value={prospects.preferred_class_type}
                    name="preferred_class_type"
                  >
                    <option value="">Preferred Class Type</option>
                    {classtypes.map(c => {
                      return (
                        <>
                          <option value={c}>{c}</option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="datetime-local"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={e => {
                        handleDateValidation2(
                          e.target.value,
                          'tentative_batch_start_date',
                        );
                      }}
                      value={prospects.tentative_batch_start_date}
                      name="tentative_batch_start_date"
                    />
                    <label for="floatingInput">
                      Tentative Batch Start Date
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="time"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleprospectsdata}
                      value={prospects.preferred_batch_start_time}
                      name="preferred_batch_start_time"
                    />
                    <label for="floatingInput">
                      Preferred Batch Start Time
                    </label>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="datetime-local"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={e => {
                        handleDateValidation2(
                          e.target.value,
                          'expected_registration_date',
                        );
                      }}
                      value={prospects.expected_registration_date}
                      name="expected_registration_date"
                    />
                    <label for="floatingInput">
                      Expected Registration Date
                    </label>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="number"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleprospectsdata}
                      value={prospects.expected_collection}
                      name="expected_collection"
                    />
                    <label for="floatingInput">Expected Collection</label>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <select
                    id=""
                    className="form-select py-3 shadow-none"
                    onChange={handleprospectsdata}
                    value={prospects.mode_of_payment}
                    name="mode_of_payment"
                  >
                    <option value="">Mode of Payment</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="UPI">UPI</option>
                  </select>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="date"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleprospectsdata}
                      value={prospects.date_of_payment}
                      name="date_of_payment"
                    />
                    <label for="floatingInput">Date of Payment</label>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div class="form-floating mb-3">
                    <input
                      type="time"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={handleprospectsdata}
                      value={prospects.time_of_payment}
                      name="time_of_payment"
                    />
                    <label for="floatingInput">Time of Payment</label>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <select
                    id="inputState"
                    className="form-select shadow-none bg-light py-3"
                    name="priority"
                    value={prospects.priority}
                    onChange={handleprospectsdata}
                  >
                    <option selected>Choose Priority...</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </select>
                </div>
                <div className="col-12">
                  <div class="">
                    <textarea
                      className="p-2 rounded border-secondary"
                      id=""
                      placeholder="Remarks..."
                      rows="3"
                      style={{ width: '100%' }}
                      onChange={handleprospectsdata}
                      value={prospects.remarks}
                      name="remarks"
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 text-end mt-4">
                  <button
                    type="submit"
                    disabled={load}
                    className="btn btn-sm px-5 py-2 btn-primary text-white fw-medium"
                  >
                    {load ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Prospect_form;
