import axios from 'axios';
import { ipaddress } from '../../App';
import { useEffect, useState } from 'react';
import THead from './THead';
import ActivityAllData from './ActivityAllData';
import { daysArray, getLeadsCountForDay } from './utils';
import Preloader from '../Preloader';

function ActivityWiseFiltering({ id, stage, typeWise }) {
  const [data, setData] = useState();
  const [filterType, setFilterType] = useState('prospect');
  const [filteredSortedData, setFilteredSortedData] = useState([]);

  const levels = ['prospect', 'reg', 'walkin', 'followup'];
  const result = {};

  const fetchAllLeads = stage => {
    axios
      .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/${stage}/`)
      .then(r => {
        console.log('DATAWISE', r.data);
        setData(r.data.customers_data);
      });
  };

  useEffect(() => {
    fetchAllLeads('total_leads');
  }, []);

  levels.forEach(level => {
    result[level] = {};

    data?.forEach(item => {
      if (item.level_lead_name === level) {
        const source = item?.[typeWise] || 'unknown';
        if (!result[level][source]) {
          result[level][source] = [];
        }
        result[level][source].push(item);
      }
    });
  });

  return data ? (
    <div>
      <div className="tw-flex tw-gap-2 tw-px-4 tw-mb-4">
        {levels.map(level => (
          <button
            key={level}
            className={`tw-px-4 tw-py-2 tw-uppercase tw-rounded-lg tw-text-white ${
              filterType === level
                ? 'tw-bg-blue-600'
                : 'tw-bg-gray-300 hover:tw-bg-gray-400'
            }`}
            onClick={() => {
              setFilterType(level);
              setFilteredSortedData([]);
            }}
          >
            {level}
          </button>
        ))}
      </div>

      <div className="tw-overflow-x-auto tw-px-4">
        <table className="tw-w-full tw-table-auto tw-rounded-lg tw-shadow-md">
          <THead />
          <tbody>
            {Object.entries(result?.[filterType] || {}).length === 0 ? (
              <tr>
                <td
                  colSpan={3 + daysArray.length}
                  className="tw-text-center tw-py-6 tw-text-gray-500"
                >
                  No Data Available
                </td>
              </tr>
            ) : (
              Object.entries(result?.[filterType] || {}).map(
                ([source, items]) => (
                  <tr key={source}>
                    <td className="tw-py-3 tw-text-center">1</td>
                    <td
                      className="tw-py-3 tw-cursor-pointer tw-text-blue-600 hover:tw-underline"
                      onClick={() => setFilteredSortedData(items)}
                    >
                      {source}
                    </td>
                    <td className="tw-py-3 tw-text-center">{items.length}</td>
                    {daysArray.map(day => (
                      <td
                        key={day}
                        className="tw-py-3 tw-text-center"
                      >
                        {getLeadsCountForDay(items, day)}
                      </td>
                    ))}
                  </tr>
                ),
              )
            )}
          </tbody>
        </table>
      </div>
      <ActivityAllData result={filteredSortedData} />
    </div>
  ) : (
    <div>
      <Preloader />
    </div>
  );
}

export default ActivityWiseFiltering;
