import axios from 'axios';
import { useEffect, useState } from 'react';
import { ipaddress } from '../../App';
import { useNavigate } from 'react-router-dom';

function MonthlyRecord({ id }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const getMonthlyRecords = () => {
    axios
      .get(`${ipaddress}/MonthlyRecordsOfEmployee/${id}`)
      .then(res => {
        console.log(res.data);
        setData(res.data.monthly_records);
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      });
  };

  useEffect(() => {
    getMonthlyRecords();
  }, [id]);

  // Extract dates and categories only if data is available
  const dates = data?.map(entry => Object.keys(entry)[0]);
  const categories = data.length > 0 ? Object.keys(data[0][dates[0]]) : [];

  return (
    <div>
      {error ? (
        <div className="tw-text-red-500 tw-font-semibold">{error}</div>
      ) : (
        <table className="tw-w-full tw-table-auto tw-rounded-lg tw-shadow-md">
          <thead>
            <tr className="tw-bg-gray-200">
              <th className="tw-py-3 tw-px-4">S.No</th>
              <th className="tw-py-3 tw-px-4">Category</th>
              {dates?.map((date, index) => (
                <th
                  key={index}
                  className="tw-py-3 tw-px-4"
                >
                  {new Date(date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <tr
                key={index}
                className="tw-bg-white hover:tw-bg-gray-100"
              >
                <td className="tw-py-3 tw-px-4">{index + 1}</td>
                <td className="tw-py-3 tw-px-4">
                  {category.replaceAll('_', ' ')}
                </td>
                {dates.map((date, dateIndex) => (
                  <td
                    key={dateIndex}
                    className="tw-py-3 tw-px-4"
                    onClick={() => {
                      console.log(date);

                      navigate(`/today_leads/${id}`, {
                        state: { dateWise: date },
                      });
                    }}
                  >
                    {data[dateIndex][date][category]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default MonthlyRecord;
