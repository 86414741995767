import React, { useState, useEffect } from 'react';
import { ipaddress, ipaddress2, role } from '../App';
import axios from 'axios';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as XLSX from 'xlsx';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Preloader from '../Components/Preloader';
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';
import Customer_new_leads from './All-leads/Customer_new_leads';
import Customer_followup_leads from './All-leads/Customer_followup_leads';
import Customer_prospect_leads from './All-leads/Customer_prospect_leads';
import Customer_closed_leads from './All-leads/Customer_closed_leads';
import Customer_registered_leads from './All-leads/Customer_registered_leads';
import Customer_walkin_leads from './All-leads/Customer_walkin_leads';
import Customer_other_leads from './All-leads/Customer_other_leads';
import Customer_all_leads from './All-leads/Customer_all_leads';
import TodayWalkin from '../Components/TodayWalkinPage/TodayWalkin';
import AchievedWalkins from '../Components/AchivedWalkins/AchievedWalkins';
import RegisSummary from './RegisSummary/RegisSummary';

const All_customer_leads = ({ designation }) => {
  const [show, setshow] = useState(false);

  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View Invoice
    </Tooltip>
  );

  const [tab, setTab] = useState('all');
  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
    // initialSlide: 0,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [employees, setemployees] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [count, setCount] = useState(0);

  // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
  const fetchemployees = () => {
    axios
      .get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
      .then(r => {
        console.log('Employees data', r.data);
        setemployees(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [selectedLeads, setselecetedLeads] = useState([]);
  const [SelectAll, setSelectAll] = useState(false);

  const assign_leads = value => {
    const emp_name = employees.find(x => x.emp_id === value);
    axios
      .post(`${ipaddress}/AssignToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        customer_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully assigned', r.data);
        //   setselectedemp("")
        selectedLeads([]);
        toast.success(`Lead assigned to ${emp_name.firstname}`, {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setSelectAll(false);
      })
      .catch(err => {
        console.log('Assigning error', err);
      });
  };

  const leadtypes = [
    {
      name: 'All Leads',
      type: 'all',
      slug: '/customerleads/',
    },
    {
      name: 'New Leads',
      type: 'newlead',
    },
    {
      name: 'Walkin/Meetings Leads',
      type: 'walkin',
    },
    {
      name: 'Referred Leads',
      type: 'referred',
    },
    {
      name: 'Followup Leads',
      type: 'followup',
    },
    {
      name: 'Prospect Leads',
      type: 'prospect',
    },
    {
      name: 'Registered Leads',
      type: 'registered',
    },
    {
      name: 'Closed Leads',
      type: 'closed',
    },
    {
      name: 'Other Department Leads',
      type: 'otherdepartment',
    },
  ];

  const navigation = value => {
    const data = leadtypes.find(x => x.type === value);
    navigate(`/all_leads/${data.type}`);
    setTab(value);
  };

  return (
    <div>
      <div className="d-flex">
        <div className="w-100 main-div">
          <div className="bg-light pt-1 h-100">
            <div className="px-1">
              {/* Slider */}
              <Slider
                {...settings}
                className="px-2 mt-2 d-block d-lg-none"
              >
                <div className="p-2 text-center">
                  <p
                    onClick={() => {
                      setTab('all');
                      navigate('/all_leads');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'all' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'all' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    All Leads
                  </p>
                </div>
                <div className="p-2 text-center">
                  <p
                    onClick={() => {
                      setTab('newleads');
                      navigate('/all_leads/newlead');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'newleads' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'newleads' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    New Leads
                  </p>
                </div>

                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('walkin');
                      navigate('/all_leads/walkin');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'walkin' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'walkin' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Walkin Leads
                  </p>
                </div>
                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('followup');
                      navigate('/all_leads/followup');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'followup' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'followup' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Followup Leads
                  </p>
                </div>
                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('prospects');
                      navigate('/all_leads/prospect');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'prospects' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'prospects' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Prospect Leads
                  </p>
                </div>
                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('registered');
                      navigate('/all_leads/registered');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'registered' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'registered' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Registered Leads
                  </p>
                </div>
                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('closed');
                      navigate('/all_leads/closed');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'closed' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'closed' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Closed Leads
                  </p>
                </div>

                <div className="p-2">
                  <p
                    onClick={() => {
                      setTab('other');
                      navigate('/all_leads/otherdepartment');
                    }}
                    className="py-2 px-4 justify-content-center d-flex align-items-center pb-3"
                    style={{
                      color: tab === 'other' ? '#57309C' : '',
                      cursor: 'pointer',
                      borderBottom:
                        tab === 'other' ? '2px solid #57309C' : 'none',
                    }}
                  >
                    Other Department Leads
                  </p>
                </div>
              </Slider>
              <div className="row m-0 mb-3">
                <div className="col-lg-2 ps-md-4 col-md-6 ms-auto pe-md-0">
                  <select
                    name=""
                    onChange={e => {
                      navigation(e.target.value);
                    }}
                    value={tab}
                    className="form-control text-white  fw-bold shadow-none text-center"
                    id=""
                    style={{
                      backgroundColor: '#002147',
                      cursor: 'pointer',
                      border: '1px solid #002147',
                    }}
                  >
                    {leadtypes.map(x => {
                      return (
                        <>
                          <option
                            style={{ lineHeight: '2.5em', padding: '10px' }}
                            value={x.type}
                          >
                            {x.name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Routes>
                  <Route
                    path="/*"
                    element={<Customer_all_leads settab={setTab} />}
                  />
                  <Route
                    path="/newlead"
                    element={<Customer_new_leads settab={setTab} />}
                  />
                  <Route
                    path="/walkin"
                    element={<Customer_walkin_leads settab={setTab} />}
                  />
                  <Route
                    path="/followup"
                    element={<Customer_followup_leads settab={setTab} />}
                  />
                  <Route
                    path="/prospect"
                    element={<Customer_prospect_leads settab={setTab} />}
                  />
                  <Route
                    path="/registered"
                    element={<Customer_registered_leads settab={setTab} />}
                  />
                  <Route
                    path="/closed"
                    element={<Customer_closed_leads settab={setTab} />}
                  />
                  <Route
                    path="/otherdepartment"
                    element={<Customer_other_leads settab={setTab} />}
                  />
                  <Route
                    path="/today-walkins"
                    element={<TodayWalkin settab={setTab} />}
                  />
                  <Route
                    path="/achieved-walkins"
                    element={<AchievedWalkins settab={setTab} />}
                  />
                  <Route
                    path="/registration-summary"
                    element={<RegisSummary settab={setTab} />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All_customer_leads;
