import React, { useEffect, useState } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Upcoming_batches from './Upcoming_batches';

const Batch_details = () => {
  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View your Queries
    </Tooltip>
  );

  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  const [batches, setBatches] = useState([]);
  const [count, setcount] = useState(0);

  useEffect(() => {
    axios.get(`${ipaddress2}/Batches/`).then(r => {
      console.log('All batches', r.data);
      setBatches(r.data);
    });
  }, [count]);

  const [completed_batches, setcompleted_batches] = useState([]);
  const fetch_completed_batches = () => {
    axios.get(`${ipaddress2}/completed-batches/`).then(r => {
      console.log('Completed batches', r.data);
      setcompleted_batches(r.data);
    });
  };

  const formatTime = timeString => {
    const [hours, minutes] = timeString.split('T')[1].split(':');
    return `${hours}:${minutes}`;
  };

  const [value, setValue] = useState('running');

  const [show, setshow] = useState(false);
  const [trainer, settrainer] = useState('');
  const [batch_id, setbatch_id] = useState('');
  const [newtrainer, setnewtrainer] = useState('');
  const [trainers, settrainers] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress2}/Trainers/`).then(r => {
      console.log('Trainers', r.data);
      settrainers(r.data);
    });
  }, []);

  const update_trainer = () => {
    axios
      .put(`${ipaddress2}/AddBatch/${user.emp_id}/`, {
        empid: newtrainer,
        batch_id: Number(batch_id),
      })
      .then(r => {
        toast.success('Trainer updated successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setcount(count + 1);
        setnewtrainer('');
        setshow(false);
      });
  };

  return (
    <div className="d-flex">
      <Sidebar activevalue={'batches'} />

      <div className="w-100">
        <Navbar />

        <div
          className="bg-light p-4 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="mb-4">
            <button
              onClick={() => {
                setValue('running');
              }}
              className={`btn btn-sm fw-medium py-2 px-3 rounded-0`}
              style={{
                fontSize: '14px',
                backgroundColor: value === 'running' ? '#002147' : '#ffff',
                color: value === 'running' ? '#ffff' : '#002147',
                border: '1px solid #002147',
              }}
            >
              Current Batches
            </button>
            <button
              onClick={() => {
                setValue('upcoming');
                fetch_completed_batches();
              }}
              className={`btn btn-sm fw-medium py-2 px-3 rounded-0`}
              style={{
                fontSize: '14px',
                backgroundColor: value === 'upcoming' ? '#002147' : '#ffff',
                color: value === 'upcoming' ? '#ffff' : '#002147',
                border: '1px solid #002147',
              }}
            >
              Upcoming Batches
            </button>
            <button
              onClick={() => {
                setValue('completed');
                fetch_completed_batches();
              }}
              className={`btn btn-sm fw-medium py-2 px-3 rounded-0`}
              style={{
                fontSize: '14px',
                backgroundColor: value === 'completed' ? '#002147' : '#ffff',
                color: value === 'completed' ? '#ffff' : '#002147',
                border: '1px solid #002147',
              }}
            >
              Completed Batches
            </button>
          </div>

          <div
            className={`table-responsive ${
              value === 'running' ? '' : 'd-none'
            }`}
          >
            <h6
              className={` py-3 text-secondary ${
                batches.length > 0 ? 'd-none' : ''
              }`}
            >
              No Running batches available...🧐
            </h6>
            <table class={`w-100 ${batches.length > 0 ? '' : 'd-none'}`}>
              <thead className="table-secondary">
                <tr style={{ backgroundColor: '#034592' }}>
                  <th
                    scope="col"
                    className="text-white py-4"
                  >
                    <p className=" m-0 fw-medium ps-2">SI.No</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Course Name</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Start Date</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Start Time</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">End Date</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">End Time</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Trainer </p>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {batches.map((x, index) => {
                  return (
                    <tr className="table-row">
                      <td
                        className="py-4"
                        scope="row"
                      >
                        <p
                          className=" m-0 ps-2"
                          style={{ width: '50px' }}
                        >
                          {index + 1}
                        </p>
                      </td>
                      <td
                        className="py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/students_under_batch/${x.id}`);
                        }}
                      >
                        <p className=" m-0 text-primary fw-medium">
                          {x.name.sub_course_name}
                        </p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{x.start_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{formatTime(x.start_time)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{x.end_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{formatTime(x.end_time)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">
                          {x.trainer && x.trainer.first_name}
                        </p>
                      </td>
                      <td>
                        <div className=" py-2">
                          <svg
                            onClick={() => {
                              setbatch_id(x.id);
                              settrainer(x.trainer.first_name);
                              setshow(true);
                            }}
                            style={{ cursor: 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div
            className={`table-responsive ${
              value === 'upcoming' ? '' : 'd-none'
            }`}
          >
            <Upcoming_batches />
          </div>

          <div
            className={`table-responsive ${
              value === 'completed' ? '' : 'd-none'
            }`}
          >
            <h6
              className={` py-3 text-secondary ${
                completed_batches.length > 0 ? 'd-none' : ''
              }`}
            >
              No Completed batches available...🧐
            </h6>
            <table
              class={`w-100 ${completed_batches.length > 0 ? '' : 'd-none'}`}
            >
              <thead className="table-secondary">
                <tr style={{ backgroundColor: '#034592' }}>
                  <th
                    scope="col"
                    className="text-white py-4"
                  >
                    <p className=" m-0 fw-medium ps-2">SI.No</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Course Name</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Start Date</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">Start Time</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">End Date</p>
                  </th>
                  <th
                    scope="col"
                    className="text-white "
                  >
                    <p className=" m-0 fw-medium">End Time</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {completed_batches.map((x, index) => {
                  return (
                    <tr className="table-row">
                      <td
                        className="py-4"
                        scope="row"
                      >
                        <p
                          className=" m-0 ps-2"
                          style={{ width: '50px' }}
                        >
                          {index + 1}
                        </p>
                      </td>
                      <td
                        className="py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/students_under_batch/${x.id}`);
                        }}
                      >
                        <p className=" m-0 text-primary fw-medium">
                          {x.batch_id}
                        </p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{x.start_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{formatTime(x.start_time)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{x.end_time.slice(0, 10)}</p>
                      </td>
                      <td className="py-3">
                        <p className=" m-0">{formatTime(x.end_time)}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Edit Batch */}
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign New Trainer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            <div className="col-12 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Current Trainer
              </label>
              <input
                type="text"
                className="form-control py-3 mt-2"
                value={trainer}
              />
            </div>
            <div className="col-12 mb-4">
              <label
                htmlFor=""
                className="fw-medium"
              >
                Select New Trainer
              </label>
              <select
                type="text"
                onChange={e => {
                  setnewtrainer(e.target.value);
                }}
                className="form-select py-3 mt-2 shadow-none border-secondary-subtle"
                value={newtrainer}
              >
                <option value="">Select Trainer...</option>
                {trainers.map(x => {
                  return (
                    <>
                      <option value={x.trainer_name && x.trainer_name.emp_id}>
                        {x.trainer_name && x.trainer_name.first_name}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>

            <div className="text-end">
              <button
                className="btn btn-primary px-4"
                onClick={update_trainer}
              >
                Assign
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Batch_details;
