import dayjs from 'dayjs';

export const buildQueryString = params => {
  return Object.entries(params)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== '',
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
};

export const formatDate = date =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

const queryParams = {
  // emp_id: empID,
  // start_date: formattedStartDate,
  // end_date: formattedEndDate,
};
