function CheckBoxAssign({ selectedLeads, handleCheckboxChange, leadID }) {
  return (
    <td
      className="table-row px-3"
      scope="row"
    >
      <div className="py-2">
        <input
          type="checkbox"
          checked={selectedLeads.includes(leadID)}
          onChange={e => handleCheckboxChange(e, leadID)}
        />
      </div>
    </td>
  );
}

export default CheckBoxAssign;
