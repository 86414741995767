import { useState } from 'react';

function RegisSummaryTable({ fetchRegistrationDetails, regisSum }) {
  const [active, setActive] = useState();

  return (
    <div className="tw-w-full  tw-bg-white tw-p-6 tw-rounded-md tw-shadow-lg ">
      <h1 className="tw-text-2xl tw-font-semibold tw-text-gray-800 tw-border-b tw-pb-3 tw-mb-6">
        Registrations Summary
      </h1>
      <div className="tw-flex tw-flex-wrap tw-gap-4">
        {regisSum?.map((item, idx) => (
          <div
            onClick={() => {
              setActive(idx);
              fetchRegistrationDetails(
                item.customer_lead_id__course_enquired__course_name,
                item.customer_lead_id__source,
              );
            }}
            key={idx}
            className={`tw-w-[140px] ${
              active === idx
                ? 'tw-bg-purple-500 tw-text-white'
                : 'tw-bg-white tw-text-gray-700'
            } tw-cursor-pointer tw-p-4 tw-border tw-border-gray-200 tw-rounded-md tw-bg-gray-50 tw-shadow-sm hover:tw-shadow-md tw-transition-shadow tw-duration-200`}
          >
            <div className="tw-mb-3">
              <h2 className="tw-text-sm tw-font-medium ">Course Name:</h2>
              <p className="tw-text-sm tw-text-wrap ">
                {item.customer_lead_id__course_enquired__course_name || 'N/A'}
              </p>
            </div>
            <div>
              <h2 className="tw-text-sm tw-font-medium ">Source Name:</h2>
              <p className="tw-text-sm tw-text-wrap ">
                {item.customer_lead_id__source || 'N/A'}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RegisSummaryTable;
