import { useNavigate } from 'react-router-dom';

function LeadStatusCustomer({
  setShow1,
  setcourse_id,
  setcourse_name,
  setlead_id,
  setstage,
  x,
}) {
  const navigate = useNavigate();

  return (
    <div class="dropdown mx-auto py-2 tw">
      <button
        class="btn btn-sm text-white tw-uppercase dropdown-toggle rounded-0"
        style={{ backgroundColor: '#002147' }}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {x.level_lead_name}
      </button>
      <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
        <ul
          className="ps-0 d-flex flex-column align-items-center gap-2"
          style={{ listStyleType: 'none' }}
        >
          <li>
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              onClick={() => {
                setlead_id(x.customer_lead_id);
                setstage('newlead');
                setcourse_name(x.course_enquiredd);
                setcourse_id(x.course_enquired);
                setShow1(true);
              }}
            >
              Followup
            </button>
          </li>
          <li>
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#prospectsform"
              onClick={() => {
                setlead_id(x.customer_lead_id);
                setcourse_name(x.course_enquiredd);
                setcourse_id(x.course_enquired);
              }}
            >
              Prospects
            </button>
          </li>
          <li>
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              onClick={() => {
                navigate(`/mark_as_register_form/${x.customer_lead_id}`);
              }}
            >
              Register
            </button>
          </li>
          <li>
            <button
              style={{ width: '100px' }}
              className="btn btn-sm text-white btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#closedform"
              onClick={() => {
                setlead_id(x.customer_lead_id);
                setstage('newlead');
              }}
            >
              Closed
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LeadStatusCustomer;
