import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFieldArray, useForm } from 'react-hook-form';
import { ipaddress } from '../App';
import QuotationProducts from './QuotationProducts';

const Generate_quotation = ({
  show,
  setshow,
  lead_id,
  fetchnewleads,
  service,
  service_amount,
  setshow1,
  show1,
  quotation_id,
  quoteData,
  setQuoteData,
}) => {
  // const [altered_amount, setaltered_amount] = useState('');

  // console.log('DORY', quoteData);

  // Handle form submission for altering the quotation
  const alter_quotation = async data => {
    try {
      const response = await axios.put(`${ipaddress}/alter-quotation`, data);
      toast.success('Quotation altered successfully!');
      fetchnewleads();
      setshow1(false);
    } catch (error) {
      toast.error('Failed to alter quotation!');
    }
  };

  return (
    <div>
      <QuotationProducts
        setQuoteData
        quoteData={quoteData}
        lead_id={lead_id}
        setshow={setshow}
        show={show}
        quotation_id={quotation_id}
      />

      {/* Edit quotation */}
      {/* <Modal
        show={show1}
        onHide={() => setshow1(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alter the Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0 p-4">
            <div className="col-12 mb-4">
              <label className="fw-medium">Lead Id</label>
              <input
                type="text"
                disabled
                value={lead_id}
                className="form-control shadow-none border-secondary-subtle py-3 mt-2"
              />
            </div>
            <div className="col-12 mb-4">
              <label className="fw-medium">Product Description</label>
              <input
                type="text"
                disabled
                value={service}
                className="form-control shadow-none border-secondary-subtle py-3 mt-2"
              />
            </div>
            <div className="col-12 mb-4">
              <label className="fw-medium">Quotation Amount</label>
              <input
                type="number"
                disabled
                value={service_amount}
                className="form-control shadow-none border-secondary-subtle py-3 mt-2"
              />
            </div>

            <div className="col-12 mb-4">
              <label className="fw-medium">Altered Quotation Amount</label>
              <input
                type="number"
                value={altered_amount}
                onChange={e => setaltered_amount(e.target.value)}
                className="form-control shadow-none border-secondary-subtle py-3 mt-2"
              />
            </div>

            <div className="mt-4 text-end">
              <button
                className="btn btn-primary px-4"
                // onClick={handleSu  bmit(alter_quotation)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Generate_quotation;
