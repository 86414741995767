import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Chart as ChartJS } from 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';
import Sidebar from '../Components/Sidebar';
import Navbar from './Navbar';
import axios, { all } from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import ReactCardFlip from 'react-card-flip';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildQueryString, formatDate } from '../utils/QueryBuilder';
import dayjs from 'dayjs';

const Admin_dashboard = () => {
  const location = useLocation();

  const isMonthly = location.pathname === 'all_monthly_leads';

  const date = new Date();

  const todayDate = dayjs().format('YYYY-MM-DD');

  // console.log('TODAY', todayDate);

  const today_date = date.toISOString().slice(0, 10);
  const [filter_type, setfilter_type] = useState('today');
  const [start_date, setstart_date] = useState(todayDate);
  const [end_date, setend_date] = useState('');

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [user_details, setuser_details] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/get-user-details/${user.email}/`).then(r => {
      console.log('User details', r.data);
      setuser_details(r.data);
    });
  }, []);

  const [titles, settitles] = useState([]);
  const [total_marks, settotal_marks] = useState([]);
  const [scored_marks, setscored_marks] = useState([]);
  const [old_collection, setold_collection] = useState([]);
  const [new_collection, setnew_collection] = useState([]);

  const employeecomparision = {
    labels: titles,
    datasets: [
      {
        label: 'Billing Amount',
        data: total_marks,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Old Collection',
        data: scored_marks,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'New Collection',
        data: new_collection,
        fill: false,
        backgroundColor: '#ff5733',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20,
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };

  const [value_state, setvalue_state] = useState('all');

  const [today_billing, settoday_billing] = useState({});
  const [monthwise_billing, setmonthwise_billing] = useState({});
  const [monthwise_prediction, setmonthwise_prediction] = useState({});
  const [collectables, setcollectables] = useState({});
  const [refunds, setrefunds] = useState({});
  const [monthly_collectables, setmonthly_collectables] = useState({});
  const [leads_count, setleads_count] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/TodaysBilling/`).then(r => {
      console.log('Todays billing', r.data);
      settoday_billing(r.data);
    });

    axios.get(`${ipaddress2}/RefundedAmount/`).then(r => {
      console.log('Refunded Amount', r.data);
      setrefunds(r.data);
    });

    axios.get(`${ipaddress2}/MonthCollection/`).then(r => {
      console.log('Monthy collectables', r.data);
      setmonthly_collectables(r.data);
    });

    axios
      .get(
        `${ipaddress2}/MonthWiseCollection/${date.getFullYear()}/${
          date.getMonth() + 1
        }`,
      )
      .then(r => {
        console.log('Month wise collection', r.data);
        setmonthwise_billing(r.data);
      });

    axios
      .get(
        `${ipaddress2}/MonthWisePrediction/${date.getFullYear()}/${
          date.getMonth() + 1
        }`,
      )
      .then(r => {
        console.log('Month wise prediction', r.data);
        setmonthwise_prediction(r.data);
      });

    axios.get(`${ipaddress2}/Collectables/${user.email}/`).then(r => {
      console.log('Collectables', r.data);
      setcollectables(r.data);
    });

    fetch_revenue(year2);

    fetch_statistics(year3, month2);
  }, []);

  const [statistics, setstatistics] = useState();
  const [statistics2, setstatistics2] = useState();

  const fetch_statistics = (year, month) => {
    axios
      .get(`${ipaddress2}/month-wise-collection?month=${year}-${month}`)
      .then(r => {
        console.log('Statistics graph', r.data);
        setstatistics(r.data.billing_amount);
        setstatistics2(r.data.amount_achieved);
      })
      .catch(err => {
        console.log('Statistics error', err);
      });
  };

  console.log('STATS', statistics);
  console.log('STATS', statistics2);
  // Pie Chart
  const chart2 = {
    labels: ['Projected Amount', 'Collected Amount'],
    datasets: [
      {
        label: 'Collection',
        data: [statistics, statistics2],
        fill: false,
        backgroundColor: ['#8D78C1', '#282263'],
        tension: 0.1,
      },
    ],
  };

  const fetch_revenue = year => {
    axios.get(`${ipaddress2}/month-wise-collection/${year}/`).then(r => {
      console.log('Collection graph', r.data.datasets);
      const data = r.data.datasets;

      const titles = data.map(item => item.month);
      const totalMarks = data.map(item => item.billing_amount);
      const oldCollection = data.map(item => item.old_collection);
      const newCollection = data.map(item => item.new_collection);
      settitles(titles);
      settotal_marks(totalMarks);
      setscored_marks(oldCollection);
      setnew_collection(newCollection);
    });
  };

  // To get years
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(year);
    }
    return years;
  };

  const current_date = new Date();

  const [year, setyear] = useState(() => new Date().getFullYear());
  const [year3, setyear3] = useState(() => new Date().getFullYear());
  const [year2, setyear2] = useState(() => new Date().getFullYear());
  const [month, setmonth] = useState(() =>
    String(new Date().getMonth() + 1).padStart(2, '0'),
  );
  const [month2, setmonth2] = useState(() =>
    String(new Date().getMonth() + 1).padStart(2, '0'),
  );

  useEffect(() => {
    const currentDate = new Date();
    setyear(currentDate.getFullYear());
    setyear2(currentDate.getFullYear());
    setyear3(currentDate.getFullYear());
    setmonth(String(currentDate.getMonth() + 1).padStart(2, '0'));
    setmonth2(String(currentDate.getMonth() + 1).padStart(2, '0'));
  }, []);

  const [months, setmonths] = useState([]);

  useEffect(() => {
    const monthArray = Array.from({ length: 12 }, (_, i) =>
      String(i + 1).padStart(2, '0'),
    );
    setmonths(monthArray);

    filter_employees(month, year);
    fetch_month_prediction(month, year);

    fetch_employees();
  }, []);

  // const [year, setYear] = useState();
  // const [month, setMonth] = useState();

  console.log('MONTH', start_date);

  const [month_wise_prediction, setmonth_wise_prediction] = useState({});
  const [monthYear, setMonthYear] = useState({ month: '', year: '' });

  const fetch_month_prediction = () => {
    axios
      .get(
        `${ipaddress}/MonthWisePrediction/${start_date.slice(
          0,
          4,
        )}/${start_date.slice(5, 7)}/`,
      )
      .then(r => {
        console.log('Month Wise Prediction', r.data);
        setmonth_wise_prediction(r.data);
      })
      .catch(err => {
        console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);
      });
  };

  const [employees, setemployees] = useState([]);

  const filter_employees = (month, year) => {
    console.log(year, month);
    axios
      .get(`${ipaddress}/top-employees/?month=${year}-${month}`)
      .then(r => {
        console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);

        console.log('Employees filtered successfully', r.data);
        setemployees(r.data);
      })
      .catch(err => {
        console.log(`${ipaddress}/top-employees/?month=${year}-${month}`);
      });
  };

  // Fetching employee targets and achieved counts
  const [target_state, settarget_state] = useState(true);
  const [emp_targets, setemp_targets] = useState([]);

  const fetch_employees = () => {
    axios
      .get(`${ipaddress}/daily-employee-activity-billing-summary/`)
      .then(r => {
        console.log('Employee and targets', r.data);
        setemp_targets(r.data);
      });
  };

  const [state, setstate] = useState(true);
  const [state2, setstate2] = useState(true);

  const [flipped, setflipped] = useState(false);

  // Filter the Leads count

  const fetch_lead_count = () => {
    axios.get(`${ipaddress2}/AdminCountDisplay/`).then(r => {
      console.log('Leads Count', r.data);
      setleads_count(r.data);
    });
  };

  useEffect(() => {
    fetch_lead_count();
  }, []);

  const filter_lead_count = (start_date, end_date) => {
    axios
      .get(
        `${ipaddress2}/AdminCountDisplay/?start_date=${start_date}&end_date=${end_date}`,
      )
      .then(r => {
        console.log('Filtered Leads Count', r.data);
        setleads_count(r.data);
      });
  };

  const navigate = useNavigate();
  const [lead_type, setlead_type] = useState('customer');

  const [allLeads, setAllLeads] = useState();

  const fetchAllLeads = () => {
    axios
      .get(`${ipaddress}/AllB2Cleads/?all=true`)
      .then(r => {
        console.log('All New Customer Leads', r.data);
        console.log('All New Customer Leads', r.data.results);
        setAllLeads(r.data.results.total_leads);
      })
      .catch(err => {
        console.log('all leads error MUIZ', err);
      });
  };

  const [coursePrCount, setCoursePrCount] = useState();

  const fetchCourseWiseProspectCount = () => {
    axios
      .get(`${ipaddress}/CourseWiseProspectCount/`)
      .then(res => {
        setCoursePrCount(res.data);
        console.log('CourseWiseProspectCount', res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAllLeads();
    fetchCourseWiseProspectCount();
  }, []);

  const [achivedWalkins, setAchieveWalkins] = useState();

  const fetchAchivedWalkins = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/AcheivedWalkin/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('ACHIVED', res.data);
        setAchieveWalkins(res.data.Acheived_walkin_count);
      })
      .catch(err => console.log(err));
  };

  const [todayWalkins, setTodayWalkins] = useState();

  const fetchTodayWalkins = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/TodayExpectedWalkinsRecords/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setTodayWalkins(res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAchivedWalkins();
    fetchTodayWalkins();
    fetchRegistrationSummary();
  }, []);

  const [regisSum, setRegisSum] = useState();

  const fetchRegistrationSummary = () => {
    const queryParams = {
      // emp_id: empID,
      start_date: formatDate(start_date),
      end_date: formatDate(end_date),
    };

    const IPADDRESS = `${ipaddress}/RegistrationsSourceWiseAndCourseWise/?${buildQueryString(
      queryParams,
    )}`;

    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('MUIZ', res.data);
        setRegisSum(res.data);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="d-flex tw-relative">
      <Sidebar activevalue={'dashboard'} />
      <div className="w-100">
        <Navbar
          lead_type={lead_type}
          allLeads={allLeads}
          leads_count={leads_count}
        />
        <div
          className="main-container  pt-4 px-2 px-lg-3 pb-4"
          style={{ minHeight: '100vh', backgroundColor: '#F0F0F0' }}
        >
          <div className=" ">
            <div className="row m-0">
              <div
                className="col-12 ps-0"
                style={{ position: 'relative' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <svg
                    onClick={() => {
                      setflipped(!flipped);
                    }}
                    style={{ cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class={`bi bi-phone-flip1 ${flipped ? 'text-primary' : ''}`}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11 1H5a1 1 0 0 0-1 1v6a.5.5 0 0 1-1 0V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a.5.5 0 0 1-1 0V2a1 1 0 0 0-1-1m1 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a.5.5 0 0 0-1 0v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-2a.5.5 0 0 0-1 0zM1.713 7.954a.5.5 0 1 0-.419-.908c-.347.16-.654.348-.882.57C.184 7.842 0 8.139 0 8.5c0 .546.408.94.823 1.201.44.278 1.043.51 1.745.696C3.978 10.773 5.898 11 8 11q.148 0 .294-.002l-1.148 1.148a.5.5 0 0 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708l1.145 1.144L8 10c-2.04 0-3.87-.221-5.174-.569-.656-.175-1.151-.374-1.47-.575C1.012 8.639 1 8.506 1 8.5c0-.003 0-.059.112-.17.115-.112.31-.242.6-.376Zm12.993-.908a.5.5 0 0 0-.419.908c.292.134.486.264.6.377.113.11.113.166.113.169s0 .065-.13.187c-.132.122-.352.26-.677.4-.645.28-1.596.523-2.763.687a.5.5 0 0 0 .14.99c1.212-.17 2.26-.43 3.02-.758.38-.164.713-.357.96-.587.246-.229.45-.537.45-.919 0-.362-.184-.66-.412-.883s-.535-.411-.882-.571M7.5 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
                    />
                  </svg>
                </div>
                <ReactCardFlip
                  isFlipped={flipped}
                  flipDirection="vertical"
                >
                  <div className="row m-0 mt-2">
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <div>
                        <button
                          onClick={() => setlead_type('customer')}
                          className={`btn btn-sm fw-medium rounded-0 ${
                            lead_type === 'customer'
                              ? 'text-white'
                              : 'text-color'
                          }`}
                          style={{
                            fontSize: '12px',
                            width: '80px',
                            border: '1px solid #002147',
                            backgroundColor:
                              lead_type === 'customer' ? '#002147' : '',
                          }}
                        >
                          Customer
                        </button>
                        <button
                          onClick={() => setlead_type('client')}
                          className={`btn btn-sm fw-medium rounded-0 ${
                            lead_type === 'client' ? 'text-white' : 'text-color'
                          }`}
                          style={{
                            fontSize: '12px',
                            width: '80px',
                            border: '1px solid #002147',
                            backgroundColor:
                              lead_type === 'client' ? '#002147' : '',
                          }}
                        >
                          Client
                        </button>
                      </div>
                      <div className="d-flex">
                        <select
                          name=""
                          value={filter_type}
                          onChange={e => {
                            setfilter_type(e.target.value);
                            if (e.target.value === 'current') {
                              fetch_lead_count();
                            }
                            setstart_date('');
                            setend_date('');
                          }}
                          className="d-block ms-auto py-1"
                          id=""
                        >
                          <option value="current">Current Month</option>
                          <option value="single_date">Date Filter</option>
                          <option value="custom">Custom Filter</option>
                        </select>

                        <div>
                          <input
                            type="date"
                            value={start_date}
                            onChange={e => {
                              setstart_date(e.target.value);
                              filter_lead_count(e.target.value, end_date);
                              fetch_month_prediction();
                            }}
                            className={`rounded border py-1 px-2 ms-3 ${
                              filter_type === 'single_date' ||
                              filter_type === 'custom'
                                ? ''
                                : 'd-none'
                            }`}
                            name=""
                            id=""
                          />
                          <input
                            type="date"
                            value={end_date}
                            onChange={e => {
                              setend_date(e.target.value);
                              filter_lead_count(start_date, e.target.value);
                            }}
                            className={`rounded border py-1 px-2 ms-3 ${
                              filter_type === 'custom' ? '' : 'd-none'
                            }`}
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* ///Total Leads */}

                    {/* //? TO WORK ON */}

                    {/* <div className="col-md-4 col-lg-2 mb-4 ps-md-0 ">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3 "
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            navigate(`/all_leads`);
                            // if(filter_type==='today'){
                            //   navigate(`/all_leads/?month=current_month`)
                            // }
                            // else{
                            //   navigate(`/all_leads/?start_date=${start_date}&end_date=${end_date}`)
                            // }
                          } else if (lead_type === 'client') {
                            navigate(`/all_client_leads`);

                            // if(filter_type==='today'){
                            // navigate(`/all_client_leads/?month=current_month`)
                            // }
                            // else{
                            //   navigate(`/all_client_leads/?start_date=${start_date}&end_date=${end_date}`)
                            // }
                          }
                        }}
                      >
                        <img
                          src={require('../images/admin_icon.png')}
                          width={50}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Total Leads</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {console.log('ALL1', allLeads)}
                            {allLeads && allLeads}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_lead_count &&
                              leads_count.client_lead_count}
                          </p>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-4 col-lg-2 mb-4 ps-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3 "
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            navigate(`/all_monthly_leads`);
                            // if(filter_type==='today'){
                            //   navigate(`/all_leads/?month=current_month`)
                            // }
                            // else{
                            //   navigate(`/all_leads/?start_date=${start_date}&end_date=${end_date}`)
                            // }
                          } else if (lead_type === 'client') {
                            navigate(`/all_client_leads`);

                            // if(filter_type==='today'){
                            // navigate(`/all_client_leads/?month=current_month`)
                            // }
                            // else{
                            //   navigate(`/all_client_leads/?start_date=${start_date}&end_date=${end_date}`)
                            // }
                          }
                        }}
                      >
                        <img
                          src={require('../images/admin_icon.png')}
                          width={50}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Total Leads(Monthly)</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_lead_count &&
                              leads_count.customer_lead_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_lead_count &&
                              leads_count.client_lead_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* ///WALKIN */}
                    <div className="col-md-4 col-lg-2 mb-4">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/walkin/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/walkin/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/walkin/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/walkin/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/Icon.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Walk-Ins</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_walkin_leads_count &&
                              leads_count.customer_walkin_leads_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_walkin_leads_count &&
                              leads_count.client_walkin_leads_count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/followup/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/followup/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/followup/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/followup/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Follow-ups</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_followup_count &&
                              leads_count.customer_followup_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_followup_count &&
                              leads_count.client_followup_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/prospect/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/prospect/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/prospect/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/prospect/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/admin_icon.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Prospects</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_prospect_count &&
                              leads_count.customer_prospect_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_prospect_count &&
                              leads_count.client_prospect_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/registered/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/registered/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/registered/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/registered/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/Icon.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">
                            Newly Registered Leads
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_registration_count &&
                              leads_count.customer_registration_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_registration_count &&
                              leads_count.client_registration_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0 pe-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/closed/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/closed/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/closed/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/closed/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Closed Deals</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.customer_closed_count &&
                              leads_count.customer_closed_count}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_closed_count &&
                              leads_count.client_closed_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* //CourseWiseProspectCount */}

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0 pe-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (lead_type === 'customer') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_leads/prospect/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_leads/prospect/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          } else if (lead_type === 'client') {
                            if (filter_type === 'today') {
                              navigate(
                                `/all_client_leads/prospect/?month=current_month`,
                              );
                            } else {
                              navigate(
                                `/all_client_leads/prospect/?start_date=${start_date}&end_date=${end_date}`,
                              );
                            }
                          }
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Course Wise Prospect</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {coursePrCount?.course_wise_count?.length}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_closed_count &&
                              leads_count.client_closed_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* /TODAYWALKINS */}
                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0 pe-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/all_leads/today-walkins`);
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Today Walk-Ins</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {todayWalkins?.customer_leads?.length}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_closed_count &&
                              leads_count.client_closed_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0 pe-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/all_leads/achieved-walkins`);
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Achieved Walk-Ins</p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {achivedWalkins}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_closed_count &&
                              leads_count.client_closed_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-2 mb-4 mb-md-0 pe-md-0">
                      <div
                        className="d-flex align-items-center bg-white p-2 dash py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(`/all_leads/registration-summary`);
                        }}
                      >
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">
                            Registrations Summary
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'customer' ? '' : 'd-none'
                            }`}
                          >
                            {regisSum?.length}
                          </p>
                          <p
                            className={`m-0 dash_data ${
                              lead_type === 'client' ? '' : 'd-none'
                            }`}
                          >
                            {leads_count.client_closed_count &&
                              leads_count.client_closed_count}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-5">
                    <div className="col-md-4 col-lg-3 mb-4 ps-md-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/admin_icon.png')}
                          width={50}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Today's Billing</p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {today_billing.billing_amount &&
                              today_billing.billing_amount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mb-4">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/Icon.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Today's Collection</p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {today_billing.amount_acheived &&
                              today_billing.amount_acheived}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Monthly Billing</p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {monthwise_billing.billing_amount &&
                              monthwise_billing.billing_amount}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0 pe-md-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={require('../images/admin_icon.png')}
                            width={50}
                            alt=""
                          />
                          <div className={`ms-2 ${state2 ? '' : 'd-none'}`}>
                            <p className="m-0 dash_data1">Monthly Collection</p>
                            <p
                              className={`m-0 dash_data d-flex align-items-center`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-rupee"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                              </svg>
                              {monthwise_billing.amount_acheived &&
                                monthwise_billing.amount_acheived}
                            </p>
                          </div>

                          {/* Monthly refund */}
                          <div className={`ms-2 ${state2 ? 'd-none' : ''}`}>
                            <p className="m-0 dash_data1">New Collectables</p>
                            <p
                              className={`m-0 dash_data fw-medium d-flex align-items-center`}
                              style={{ fontSize: '15px' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-rupee"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                              </svg>
                              {monthly_collectables.new_collectables_total_collected &&
                                monthly_collectables.new_collectables_total_collected}
                            </p>
                            <p className="m-0 dash_data1 mt-2">
                              Old Collectables
                            </p>
                            <p
                              className={`m-0 dash_data fw-medium d-flex align-items-center`}
                              style={{ fontSize: '15px' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-rupee"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                              </svg>
                              {monthly_collectables.old_collectables_total_collected &&
                                monthly_collectables.old_collectables_total_collected}
                            </p>
                          </div>
                        </div>
                        <div>
                          <svg
                            onClick={() => {
                              setstate2(true);
                            }}
                            style={{ cursor: 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="d-block bi bi-chevron-bar-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708M2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4"
                            />
                          </svg>
                          <svg
                            onClick={() => {
                              setstate2(false);
                            }}
                            style={{ cursor: 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="mt-2 bi bi-chevron-bar-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708M1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0 ps-lg-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">
                            Monthly Fresh Collection
                          </p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {monthwise_billing.fresh_collection &&
                              monthwise_billing.fresh_collection}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/Group 41.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">
                            Monthly Old Collection
                          </p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {monthwise_billing.old_collection &&
                              monthwise_billing.old_collection}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3">
                        <img
                          src={require('../images/Icon.png')}
                          width={44}
                          alt=""
                        />
                        <div className="ms-2">
                          <p className="m-0 dash_data1">Droppedout Amount</p>
                          <p className="m-0 dash_data d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-currency-rupee"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                            </svg>
                            {refunds.overall_droppedout_amount &&
                              refunds.overall_droppedout_amount}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-3 mb-4 mb-md-0 pe-lg-0">
                      <div className="d-flex align-items-center bg-white p-2 dash py-3 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={require('../images/Group 41.png')}
                            width={44}
                            alt=""
                          />
                          <div className={`ms-2 ${state ? '' : 'd-none'}`}>
                            <p className="m-0 dash_data1">Overall Refunds</p>
                            <p
                              className={`m-0 dash_data d-flex align-items-center`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-rupee"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                              </svg>
                              {refunds.overall_refunded &&
                                refunds.overall_refunded}
                            </p>
                          </div>

                          {/* Monthly refund */}
                          <div className={`ms-2 ${state ? 'd-none' : ''}`}>
                            <p className="m-0 dash_data1">Monthly Refunds</p>
                            <p
                              className={`m-0 dash_data d-flex align-items-center`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-rupee"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                              </svg>
                              {refunds.refunded_amount_this_month &&
                                refunds.refunded_amount_this_month}
                            </p>
                          </div>
                        </div>
                        <div>
                          <svg
                            onClick={() => {
                              setstate(true);
                            }}
                            style={{ cursor: 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="d-block bi bi-chevron-bar-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708M2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4"
                            />
                          </svg>
                          <svg
                            onClick={() => {
                              setstate(false);
                            }}
                            style={{ cursor: 'pointer' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="mt-2 bi bi-chevron-bar-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708M1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactCardFlip>
              </div>
            </div>

            {/* Month wise prediction */}
            <div className="row m-0 mt-4">
              <h5 className="mb-3">Month Wise Predictions</h5>
              <div className="col-md-6 col-lg-2 mb-4 ps-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/admin_icon.png')}
                    width={50}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Projected Revenue </p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.projected_amount &&
                        month_wise_prediction.projected_amount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-2 mb-4">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/Icon.png')}
                    width={44}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">
                      Projected Revenue (Excl. GST)
                    </p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.projected_amount_without_gst &&
                        month_wise_prediction.projected_amount_without_gst}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-2 mb-4 mb-md-0 pe-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/admin_icon.png')}
                    width={50}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">GST Amount</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction?.gst_amount}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-2 mb-4 mb-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/Group 41.png')}
                    width={44}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Achieved Revenue</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.acheived_amount &&
                        month_wise_prediction.acheived_amount}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-2 mb-4 mb-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/admin_icon.png')}
                    width={50}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Total Pending Revenue</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.pending_collection &&
                        month_wise_prediction.pending_collection}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-2 mb-4 mb-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/Group 41.png')}
                    width={44}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Pending Collections (Old)</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.pending_old_collection &&
                        month_wise_prediction.pending_old_collection}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-2 mb-4 mb-md-0 pe-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/admin_icon.png')}
                    width={50}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Pending Collections (New)</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction.pending_fresh_collection &&
                        month_wise_prediction.pending_fresh_collection}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-md-0 pe-md-0">
                <div className="d-flex align-items-center bg-white p-2 dash py-3">
                  <img
                    src={require('../images/admin_icon.png')}
                    width={50}
                    alt=""
                  />
                  <div className="ms-2">
                    <p className="m-0 dash_data1">Overdue Collections</p>
                    <p
                      className="m-0 dash_data d-flex align-items-center"
                      style={{ fontSize: '18px' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {month_wise_prediction?.overdue_amount}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Charts and Tables */}

            <div className="row m-0">
              <div className="col-md-8 ps-0">
                <div
                  className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`}
                  style={{ borderRadius: '10px', minHeight: '440px' }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5
                      className="m-0"
                      style={{ color: '#1B2559' }}
                    >
                      Yearly Revenue
                    </h5>
                    <select
                      value={year2}
                      className="rounded-pill px-2 ms-2"
                      onChange={e => {
                        setyear2(e.target.value);
                        fetch_revenue(e.target.value);
                      }}
                      name=""
                      id=""
                    >
                      {/* <option value="">Select Year</option> */}
                      {getYears().map((x, idx) => {
                        return (
                          <>
                            <option
                              value={x}
                              key={idx}
                            >
                              {x}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <Line
                    data={employeecomparision}
                    options={options}
                  />
                </div>
              </div>

              <div className="col-md-4 px-0">
                <div
                  className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`}
                  style={{ borderRadius: '10px', height: '520px' }}
                >
                  <div className="d-flex align-items-center justify-content-between pb-3">
                    <h6
                      className="m-0"
                      style={{ color: '#1B2559' }}
                    >
                      Sales Target
                    </h6>
                    <div className="d-flex">
                      <p
                        onClick={() => {
                          settarget_state(true);
                        }}
                        className={`m-0 px-2 ${
                          target_state ? 'para1' : 'para2'
                        }`}
                        style={{
                          borderRadius: '5px 0px 0px 5px',
                          cursor: 'pointer',
                        }}
                      >
                        Activity
                      </p>
                      <p
                        onClick={() => {
                          settarget_state(false);
                        }}
                        className={`m-0 px-2 ${
                          target_state ? 'para2' : 'para1'
                        }`}
                        style={{
                          borderRadius: '0px 5px 5px 0px',
                          cursor: 'pointer',
                        }}
                      >
                        Billing
                      </p>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table class="table">
                      <thead className="border-bottom">
                        <tr>
                          <th
                            scope="col"
                            style={{ color: '#6B678C' }}
                            className="fw-medium"
                          >
                            Employee Name
                          </th>
                          <th
                            scope="col"
                            style={{ color: '#6B678C' }}
                            className="fw-medium"
                          >
                            Target
                          </th>
                          <th
                            scope="col"
                            style={{ color: '#6B678C' }}
                            className="fw-medium"
                          >
                            Achieved
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {emp_targets.map((x, idx) => {
                          return (
                            <tr
                              className="border-transparent"
                              key={idx}
                            >
                              <td className="py-3">
                                <p
                                  className="m-0 fw-medium"
                                  style={{ fontSize: '14px', color: '#A3AED0' }}
                                >
                                  {x.employee_name}
                                </p>
                              </td>
                              <td className="py-3">
                                <p
                                  className="fw-medium m-0 text-center"
                                  style={{ color: '#A3AED0', fontSize: '14px' }}
                                >
                                  {x.total_target}
                                </p>
                              </td>
                              <td className="py-3">
                                <p
                                  className="fw-medium m-0 text-center"
                                  style={{ color: '#A3AED0', fontSize: '14px' }}
                                >
                                  {x.total_achieved}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 mb-4">
              <div className="col-md-8 ps-0">
                <div
                  className={`d-flex flex-column mb-5 mb-lg-0 bg-white px-2 px-md-4 py-4 shadow-sm mt-5`}
                  style={{ borderRadius: '15px', height: '366px' }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5
                      className="m-0"
                      style={{ color: '#1B2559' }}
                    >
                      Top Counsellor
                    </h5>
                    {/* <img src={require('../images/new/Button.png')} alt="" /> */}
                    <div>
                      <select
                        value={month}
                        className="rounded-pill px-2"
                        onChange={e => {
                          setmonth(e.target.value);
                          filter_employees(e.target.value, year);
                        }}
                        name=""
                        id=""
                      >
                        {/* <option value="">Select Month</option> */}
                        {months &&
                          months.map((x, idx) => {
                            return (
                              <>
                                <option
                                  value={x}
                                  key={idx}
                                >
                                  {x}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <select
                        value={year}
                        className="rounded-pill px-2 ms-2"
                        onChange={e => {
                          setyear(e.target.value);
                          filter_employees(month, e.target.value);
                        }}
                        name=""
                        id=""
                      >
                        {/* <option value="">Select Year</option> */}
                        {getYears().map((x, idx) => {
                          return (
                            <>
                              <option
                                value={x}
                                key={idx}
                              >
                                {x}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {employees &&
                    employees.slice(0, 5).map((x, idx) => {
                      return (
                        <div
                          className="d-flex justify-content-between mt-3 border-bottom pb-3 align-items-center"
                          key={idx}
                        >
                          <div className="d-flex align-items-center">
                            {/* <img src={require('../images/new/Avatar.png')} alt="" /> */}
                            <div className="ms-3">
                              <p
                                className="m-0 fw-medium"
                                style={{ color: '#1B2559' }}
                              >
                                {x.name}
                              </p>
                              <p
                                className="m-0 text-secondary"
                                style={{ fontSize: '11px' }}
                              >
                                counsellor3@gmail.com
                              </p>
                            </div>
                          </div>
                          <p
                            className="rounded px-3 m-0 d-flex align-items-center py-1"
                            style={{
                              color: '#05CD99',
                              backgroundColor: '#E6FAF5',
                            }}
                          >
                            {x.achieved}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-md-4 px-0 ">
                <div
                  className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`}
                  style={{ borderRadius: '10px', height: '366px' }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5
                      className="m-0"
                      style={{ color: '#1B2559' }}
                    >
                      Statistics
                    </h5>
                    <div>
                      <select
                        value={month2}
                        className="rounded-pill px-2"
                        onChange={e => {
                          setmonth2(e.target.value);
                          fetch_statistics(year3, e.target.value);
                        }}
                        name=""
                        id=""
                      >
                        {/* <option value="">Select Month</option> */}
                        {months &&
                          months.map((x, idx) => {
                            return (
                              <>
                                <option
                                  value={x}
                                  key={idx}
                                >
                                  {x}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <select
                        value={year3}
                        className="rounded-pill px-2 ms-2"
                        onChange={e => {
                          setyear3(e.target.value);
                          fetch_statistics(e.target.value, month2);
                        }}
                        name=""
                        id=""
                      >
                        {/* <option value="">Select Year</option> */}
                        {getYears().map((x, idx) => {
                          return (
                            <>
                              <option
                                value={x}
                                key={idx}
                              >
                                {x}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <Pie
                    data={chart2}
                    className="mx-auto"
                    style={{ height: '84%', width: '84%' }}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_dashboard;
