import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import { ipaddress } from '../App';
import Preloader from './Preloader';
import { useParams } from 'react-router-dom';
import BillWiseFiltering from './Activity/BillWiseFiltering';
import ActivityWiseFiltering from './Activity/ActivityWiseFiltering';
import CollectionsWiseFiltering from './Activity/CollectionsWiseFiltering';
import MonthlyRecord from './Activity/MonthlyRecord';

const View_Activities = ({ designation }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [typeWise, setTypeWise] = useState('source'); // Default type is 'source'

  const buttonState = [
    {
      name: 'Filter by Source',
      id: 'source',
    },
    {
      name: 'Filter by Course',
      id: 'course_enquiredd',
    },
    {
      name: 'Course Billing',
      id: 'billing_course',
    },
    {
      name: 'Source Billing  ',
      id: 'billing_source',
    },
    {
      name: 'Course Collections ',
      id: 'collections_course',
    },
    {
      name: 'Source Collections',
      id: 'collections_source',
    },
    {
      name: 'Monthly Employee Records',
      id: 'monthly_records',
    },
  ];

  console.log('MUIZ', typeWise);
  const fetchBillingData = () => {
    setLoading(true);
    let endpoint = '';

    if (typeWise === 'billing_course') {
      endpoint = `${ipaddress}/EmployeeCourseDailyBillingAPIView/${id}/`;
    } else if (typeWise === 'billing_source') {
      endpoint = `${ipaddress}/EmployeeSourceDailyBillingAPIView/${id}/`;
    }

    // Fetch data
    if (endpoint) {
      console.log('MUIZZZZ', endpoint);
      axios
        .get(endpoint)
        .then(response => {
          console.log(`MUIZ ${typeWise} data`, response);
          setData(response.data.billing_data || []);
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => setLoading(false));
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBillingData();
  }, [typeWise]);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <div className="d-flex">
          <Sidebar
            designation={designation}
            activevalue={'employees'}
          />
          <div className="w-100 main-div">
            <Navbar
              designation={designation}
              pagename={'Activities'}
            />
            <div
              className="bg-light pt-3 main-container"
              style={{ minHeight: '100vh' }}
            >
              <div className="tw-flex tw-gap-3 tw-my-4 tw-px-4">
                {buttonState.map((item, idx) => (
                  <button
                    key={idx}
                    className={`tw-p-3 tw-rounded-lg tw-text-white ${
                      typeWise === item.id
                        ? 'tw-bg-purple-600'
                        : 'tw-bg-gray-300 hover:tw-bg-gray-400'
                    }`}
                    onClick={() => setTypeWise(item.id)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>

              {(typeWise === 'course_enquiredd' || typeWise === 'source') && (
                <div className="container">
                  <ActivityWiseFiltering
                    id={id}
                    typeWise={typeWise}
                  />
                </div>
              )}

              {(typeWise === 'billing_course' ||
                typeWise === 'billing_source') && (
                <div className="container tw-mt-10">
                  <BillWiseFiltering apiData={data} />
                </div>
              )}

              {(typeWise === 'collections_source' ||
                typeWise === 'collections_course') && (
                <div className="container tw-mt-10">
                  <CollectionsWiseFiltering
                    id={id}
                    typeWise={typeWise}
                  />
                </div>
              )}

              {typeWise === 'monthly_records' && (
                <div className="container tw-mt-10">
                  <MonthlyRecord id={id} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default View_Activities;
