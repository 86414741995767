import axios from 'axios';
import { useEffect, useState } from 'react';
import { ipaddress } from '../../App';
import { buildQueryString } from '../../utils/QueryBuilder';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

function SourcesSummary({
  fetchData,
  todayDate,
  sourcesData,
  setSourcesData,
  value,
  category_name2,
}) {
  const [months, setMonths] = useState({ startDate: '', endDate: '' });
  const [loading, setLoading] = useState(false); // State for loading
  const location = useLocation();

  const [selectedSource, setSelectedSource] = useState();

  let empID;
  if (location.pathname.startsWith('/view_employee_leads')) {
    empID = location.pathname.slice(21, 31);
  }

  const getSourceWiseData = () => {
    const dynamicName = category_name2;
    console.log(value);

    const queryParams = {
      emp_id: empID,
      start_date: months.startDate,
      end_date: months.endDate,
      date: todayDate,
      [dynamicName]: value,
    };

    const IPADDRESS = `${ipaddress}/CountsForSourceLeads/?${buildQueryString(
      queryParams,
    )}`;

    console.log(IPADDRESS);

    setLoading(true); // Set loading to true
    axios
      .get(IPADDRESS)
      .then(res => {
        console.log('SOURCES', res.data);
        setSourcesData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false)); // Set loading to false
  };

  useEffect(() => {
    getSourceWiseData();
    // fetchSourceDetails();
  }, [months, category_name2, todayDate, value, selectedSource]);

  const handleDateChange = (field, value) => {
    setMonths(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const fetchSourceDetails = selectedValue => {
    setLoading(true); // Set loading to true

    const dynamicName = category_name2;
    console.log(value);

    const queryParams = {
      emp_id: empID,
      start_date: months.startDate,
      end_date: months.endDate,
      date: todayDate,
      [dynamicName]: value,
    };

    const IPADDRESS = `${ipaddress}/SearchStageWiseAllB2Cleads/source/${encodeURIComponent(
      selectedValue || selectedSource,
    )}`;

    // const IPADDRESS = `${ipaddress}/SearchStageWiseAllB2Cleads/source/${encodeURIComponent(
    //   selectedSource,
    // )}/?${buildQueryString(queryParams)}`;

    console.log(IPADDRESS);

    axios
      .get(IPADDRESS)
      .then(res => {
        fetchData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false)); // Set loading to false
  };

  const [active, setActive] = useState();

  return (
    <>
      {loading && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-50 tw-flex tw-justify-center tw-items-center">
          <div className="tw-bg-white tw-p-4 tw-rounded-md tw-shadow-md tw-text-center">
            <p className="tw-text-lg tw-font-semibold">Loading...</p>
          </div>
        </div>
      )}
      {/* <div className="tw-mb-6 tw-gap-x-4 tw-flex"> */}
      <div className="tw-mb-6 tw-gap-x-4 ">
        <div className="tw-w-max">
          <label
            htmlFor="startMonth"
            className="tw-block tw-text-sm tw-font-medium"
          >
            Start Month
          </label>
          <input
            onChange={e => handleDateChange('startDate', e.target.value)}
            className="tw-p-2 tw-border tw-rounded-md"
            type="date"
            name="startMonth"
            id="startMonth"
          />
        </div>
        <div className="tw-w-max">
          <label
            htmlFor="endMonth"
            className="tw-block tw-text-sm tw-font-medium"
          >
            End Month
          </label>
          <input
            onChange={e => handleDateChange('endDate', e.target.value)}
            className="tw-p-2 tw-border tw-rounded-md"
            type="date"
            name="endMonth"
            id="endMonth"
          />
        </div>
      </div>
      <div className="tw-p-4 tw-w-[25%] tw-bg-gray-50 tw-rounded-md tw-shadow-md tw-max-h-[400px] tw-overflow-y-auto">
        <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700 tw-mb-4">
          Source Wise Summary
        </h2>
        {sourcesData?.length > 0 ? (
          <ul className="tw-space-y-2">
            {sourcesData?.map((item, idx) => (
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setActive(idx);
                  fetchSourceDetails(item?.source);
                  setSelectedSource(item?.name);
                }}
                key={idx}
                className={`tw-flex tw-justify-between tw-items-center ${
                  active === idx
                    ? 'tw-bg-purple-500 tw-text-white'
                    : 'tw-bg-white'
                }ttw-border tw-rounded-md tw-shadow-sm tw-p-3 tw-transition-transform tw-duration-300 `}
              >
                <span className="tw-text-lg tw-capitalize ">
                  {item?.source || 'N/A'}
                </span>
                <span className="tw-text-xl tw-font-bold tw-text-black">
                  {item?.count || 0}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="tw-text-gray-500">No closed data available.</p>
        )}
      </div>
    </>
  );
}

export default SourcesSummary;
