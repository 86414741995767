function InputAssign({
  selecetdemp,
  assign_leads,
  permissions,
  user,
  selectedLeads,
  all_employees,
}) {
  return (
    <div className="row m-0">
      <div className="col-lg-4 d-flex col-md-6 mb-3 ms-auto pe-md-0 align-items-center">
        <p className="w-25 text-end pe-3 m-0 fw-medium">Assign To : </p>
        <select
          value={selecetdemp}
          disabled={selectedLeads.length > 0 ? false : true}
          name=""
          id=""
          onChange={e => {
            assign_leads(e.target.value);
          }}
          className={`form-select shadow-none w-75 ${
            permissions.assign_leads || user.designation === 'admin'
              ? ''
              : 'd-none'
          }`}
          style={{ cursor: 'pointer' }}
        >
          <option value="">Select Employee...</option>
          {all_employees.map(x => {
            return (
              <>
                <option value={x.user.emp_id}>{x.user.first_name}</option>
              </>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default InputAssign;
